import React, { useContext } from 'react'
import { IPinInputView } from './interface'
import { ShieldCheckIcon } from '@heroicons/react/20/solid'
import OTPInput from 'react-otp-input'
import { WalletContext } from '../../context/Wallet/wallet.context'
import { PinStage, usePinHandler } from '../../hooks/handlePin'

const PinInputView: IPinInputView = ({ handleAuthenticated }) => {
  const { hasWallet } = useContext(WalletContext)

  const { stage, form, updateForm, setPinStage, verifyPin, createPin } = usePinHandler()

  if (hasWallet === false) {
    return (
      <div className="flex flex-col items-center justify-center rounded-lg bg-slate-50 px-6 py-10 ">
        <ShieldCheckIcon className="h-20 w-20 text-[#3b82f7] transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
        <div className="mb-4 flex flex-col items-center justify-center">
          <p className="text-xl font-bold">
            {stage === PinStage.CREATE_PIN
              ? 'Create your transaction pin'
              : 'Confirm your Transaction pin'}
          </p>
          <p className="text-sm text-gray-500">
            Securely set your transaction PIN in just one step!
          </p>
        </div>
        <OTPInput
          value={stage === PinStage.CREATE_PIN ? form.pin : form.c_pin}
          onChange={(e) =>
            updateForm(stage === PinStage.CREATE_PIN ? 'pin' : 'c_pin', e)
          }
          numInputs={4}
          renderSeparator={<div className="mx-2"></div>}
          renderInput={(props) => (
            <input
              {...props}
              className="form-select rounded-full"
              // type='password'
              style={{ width: '45px', height: '45px', textAlign: 'center' }}
              placeholder="0"
            />
          )}
        />
        <p className="mb-3 mt-5 w-[85%] text-center text-xs font-light text-gray-500">
          Please note that exposing your transaction pin may lead to
          unauthorized access and potential financial losses.
        </p>
        <div className="inline-flex w-full justify-center space-x-5">
          {stage === PinStage.CONFIRM_PIN ? (
            <button
              type="button"
              onClick={() =>
                stage === PinStage.CONFIRM_PIN
                  ? setPinStage(PinStage.CREATE_PIN)
                  : null
              }
              className="group relative flex w-[30%] items-center justify-center rounded-md bg-gray-300 px-7 py-1 text-sm text-gray-700 hover:bg-gray-400  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3b82f7] disabled:opacity-60">
              Previous
            </button>
          ) : null}
          <button
            type="button"
            onClick={() =>
              stage === PinStage.CREATE_PIN
                ? setPinStage(PinStage.CONFIRM_PIN)
                : createPin(handleAuthenticated)
            }
            className="group relative flex w-[30%] items-center justify-center rounded-md bg-[#3b82f7] px-7 py-1 text-sm text-white hover:bg-[#1b6ef8] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3b82f7] disabled:opacity-60">
            {stage === PinStage.CREATE_PIN ? 'Next' : 'Submit'}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center justify-center rounded-lg bg-slate-50 px-6 py-10 ">
      <ShieldCheckIcon className="h-20 w-20 text-[#3b82f7] transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
      <div className="mb-4 flex flex-col items-center justify-center">
        <p className="text-xl font-bold">Enter your Transaction pin</p>
        <p className="text-sm text-gray-500">
          Secure your transaction: Enter PIN to proceed
        </p>
      </div>
      <OTPInput
          value={form.pin}
          onChange={(e) =>
            updateForm('pin', e)
          }
          numInputs={4}
          renderSeparator={<div className="mx-2"></div>}
          renderInput={(props) => (
            <input
              {...props}
              className="form-select rounded-full"
              style={{ width: '45px', height: '45px', textAlign: 'center' }}
              placeholder="0"
            />
          )}
        />
      <p className="mb-3 mt-5 w-[85%] text-center text-xs font-light text-gray-500">
        Please note that exposing your transaction pin may lead to unauthorized
        access and potential financial losses.
      </p>
      <button
        type="button"
        onClick={() => verifyPin(handleAuthenticated)}
        className="group relative flex w-3/6 items-center justify-center rounded-md bg-[#3b82f7] px-3 py-2 text-sm font-semibold text-white hover:bg-[#1b6ef8] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3b82f7] disabled:opacity-60">
        Submit
      </button>
    </div>
  )
}

export default PinInputView
