import React from 'react'
import { useCart } from '../../hooks/handleCart'
import Layout from '../../layout'
import Sidebar from '../../layout/Sidebar/Sidebar'
// import useWalletHandler from '../../hooks/handleWallet'
// import PinInputView from './PinInputView'
import AccountView from './AccountView'
import { HandleWalletProvider } from '../../context/Wallet/handleWallet.context'

const WalletPage: React.FC<{}> = () => {
  const {
    cartItems,
    loading: cartLoading,
    deleteCartItem,
    totalCost,
    updateCartItem,
  } = useCart()

  return (
    <Layout
      totalCost={totalCost}
      data={cartItems}
      loading={cartLoading}
      updateCartItem={updateCartItem as any}
      deleteCartItem={deleteCartItem} cartItems={null}>
      <Sidebar title="Order History" pathname="Order History">
        <div className="bg-gray-50 p-6">
          <h2 className="sm:text-base">Wallet</h2>
        </div>
        <div className="p-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-6">
            <div className="md:col-span-4 md:col-start-2">
              <HandleWalletProvider>
                <AccountView />
              </HandleWalletProvider>
            </div>
          </div>
        </div>
      </Sidebar>
    </Layout>
  )
}

export default WalletPage
