import { updateUserPassword } from './../Api/user.api'
import { useState, useMemo, useCallback, ChangeEvent } from 'react'
import { IPasswordChange } from './../Api/interface'
import { toast } from 'react-hot-toast'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

type IField = 'old_password' | 'new_password' | 'confirm_password'

interface ISettings {
  loading: boolean
  passwordForm: IPasswordChange
  validForm: boolean
  logout: () => void
  updatePasswordForm: (fieldName: IField, event: ChangeEvent) => void
  changePassword: (payload: IPasswordChange) => void
}

export const useSettings = (): ISettings => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [passwordForm, setPasswordForm] = useState<IPasswordChange>({
    new_password: '',
    old_password: '',
    confirm_password: '',
  })

  const handleError = useCallback((data: any): string => {
    const res = Object.values(data)
    const message = res.join(',')
    return message
  }, [])

  const changePassword = useCallback(
    (payload: IPasswordChange) => {
      setLoading(true)
      const data: IPasswordChange = {
        old_password: payload.old_password,
        new_password: payload.new_password,
      }
      const resp = updateUserPassword({ payload: data })
      void toast.promise(resp, {
        loading: 'Please wait....',
        success: (props) => {
          setPasswordForm({
            new_password: '',
            old_password: '',
            confirm_password: '',
          })
          setLoading(false)
          return 'Your Password Reset was Successful'
        },
        error: (err: AxiosError) => {
          const { response } = err
          const message = handleError(response?.data)
          setLoading(false)
          return `${message ?? 'Something went wrong try again'}`
        },
      })
    },
    [loading, passwordForm]
  )

  const updatePasswordForm = useCallback(
    (field: IField, e: ChangeEvent) => {
      setPasswordForm((prev) => ({
        ...prev,
        [field]: (e.target as any).value,
      }))
    },
    [passwordForm]
  )

  const validForm = useCallback((): boolean => {
    if (
      passwordForm.confirm_password !== '' &&
      passwordForm.new_password !== '' &&
      passwordForm.confirm_password !== '' &&
      passwordForm.new_password === passwordForm.confirm_password
    ) {
      return true
    }
    return false
  }, [passwordForm])

  const logout = useCallback(() => {
    localStorage.clear()
    navigate('/')
  }, [])

  return useMemo(
    () => ({
      loading,
      passwordForm,
      validForm: !validForm(),
      logout,
      updatePasswordForm,
      changePassword,
    }),
    [loading, passwordForm]
  )
}
