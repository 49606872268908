/* eslint-disable indent */
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid'
import React, { useState, useEffect } from 'react'
import Loader from '../../assets/loaders/Loader'
import { useRegistration } from '../../hooks/handleRegisteration'
import { useAuthentication } from '../../hooks/useAuth'
import { Discovery, IOptions, Location } from '../../utilities/option'
import { AppStep, IRegistration } from './Interface'
import { validateEmail, validatePhoneNumber } from '../../utilities/validator'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import validateForm from './validator'

const MIN_PASSWORD_LENGTH = 8;
const MAX_PHONE_LENGTH = 11;

const RegisterPage: React.FC<IRegistration> = () => {
  const [step, setStep] = useState<AppStep>(AppStep.PERSONAL_INFO)
  const {
    form,
    updateForm,
    profile,
    category,
    handleCategory,
    handleBusinessCategory,
  } = useRegistration()
  const { signup, error, loading } = useAuthentication()

  const [emailError, setErrorEmail] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>('');
  const [firstNameError, setFirstNameError] = useState<string | null>('');
  const [lastNameError, setLastNameError] = useState<string | null>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>('');
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [showPasswordText, setShowPasswordText] = useState('password')
  const errors = validateForm(form);

  const handlePasswordCheck = (): any => {
    if (!isShowPassword) {
      setIsShowPassword(true)
      setShowPasswordText('text')
    } else {
      setIsShowPassword(false)
      setShowPasswordText('password')
    }
  }
  
  useEffect(() => {
    const isValidFirstName = /^[A-Za-z]+$/.test(form?.first_name);
    const isValidLastName = /^[A-Za-z]+$/.test(form?.last_name);
    const isValidEmail = validateEmail(form?.email);
    const isValidPhone = validatePhoneNumber(form?.phone);

    if (form?.password.length > 0 && form?.password.length < MIN_PASSWORD_LENGTH) {
      setPasswordError(errors.password);
    } else if (!isValidFirstName && form?.first_name.length > 0) {
      setFirstNameError(errors.first_name);
    } else if (!isValidLastName && form?.last_name.length > 0) {
      setLastNameError(errors.last_name);
    } else if (!isValidEmail) {
      setErrorEmail('Invalid Email Address');
    } else if (isValidPhone || (form?.phone.length > 0 && form?.phone.length < MAX_PHONE_LENGTH)) {
      setPhoneNumberError(errors.phone);
    } else {
      setPasswordError(null);
      setFirstNameError(null);
      setLastNameError(null);
      setPhoneNumberError(null);
      setErrorEmail(null);
    }
  }, [form?.password, form?.first_name, form?.last_name, form?.email, form?.phone]);
  
  return (
    <div>
      <nav className="fixed left-0 top-0 z-20 w-full bg-white px-2 py-4">
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <a href="/" className="flex items-center">
            <img
              className="mx-auto h-9 w-auto"
              src="/logo.png"
              alt="Your Company"
            />
          </a>
        </div>
      </nav>
      <div className="flex min-h-screen items-center justify-center bg-gray-100 px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-3xl space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-semibold tracking-tight text-gray-500">
              Welcome to DrugStoc
            </h2>
            <p className="mt-2 text-center text-sm capitalize text-gray-600">
              Complete your information to get started
            </p>
          </div>
          <div className="mt-8 space-y-6">
            <ol className="flex w-full items-center text-center text-sm font-medium text-gray-500 dark:text-gray-400 sm:text-base">
              <li
                className={`after:border-1 flex items-center ${
                  profile.personal || step === AppStep.PERSONAL_INFO
                    ? 'text-[#3b82f7]'
                    : ''
                } after:mx-6 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200 dark:text-[#3b82f7] dark:after:border-gray-700 sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10`}>
                <span className="flex items-center after:mx-2 after:font-light after:text-gray-200 after:content-['/'] dark:after:text-gray-500 sm:after:hidden">
                  {profile.personal ? (
                    <svg
                      aria-hidden="true"
                      className="mr-2 h-4 w-4 sm:h-5 sm:w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"></path>
                    </svg>
                  ) : (
                    <span className="mr-2">1</span>
                  )}
                  Personal{' '}
                  <span className="hidden sm:ml-2 sm:inline-flex">Info</span>
                </span>
              </li>
              <li
                className={`after:border-1 flex items-center ${
                  profile.account || step === AppStep.ACCOUNT_INFO
                    ? 'text-[#3b82f7]'
                    : ''
                } after:mx-6 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200 dark:text-[#3b82f7] dark:after:border-gray-700 sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10`}>
                <span className="flex items-center after:mx-2 after:font-light after:text-gray-200 after:content-['/'] dark:after:text-gray-500 sm:after:hidden">
                  {profile.account ? (
                    <svg
                      aria-hidden="true"
                      className="mr-2 h-4 w-4 sm:h-5 sm:w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"></path>
                    </svg>
                  ) : (
                    <span className="mr-2">2</span>
                  )}
                  Account{' '}
                  <span className="hidden sm:ml-2 sm:inline-flex">Info</span>
                </span>
              </li>
              <li
                className={`flex ${
                  profile.confirmation || step === AppStep.CONFIRMATION
                    ? 'text-[#3b82f7]'
                    : ''
                } items-center`}>
                {profile.confirmation ? (
                  <svg
                    aria-hidden="true"
                    className="mr-2 h-4 w-4 sm:h-5 sm:w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"></path>
                  </svg>
                ) : (
                  <span className="mr-2">3</span>
                )}
                Confirmation
              </li>
            </ol>
            <div className="min-h-auto mx-auto rounded-lg border border-gray-200 bg-white p-6 shadow-xl dark:border-gray-700 dark:bg-gray-800 md:w-3/4">
              {step === AppStep.PERSONAL_INFO ? (
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label className="form-label">
                      What Best Describes you?
                    </label>
                    <select
                      onChange={(e) => handleCategory(e)}
                      value={category}
                      disabled={loading}
                      className="form-select">
                      <option disabled defaultValue="">
                        Select an option
                      </option>
                      <option value="individual">
                        Licensed health Worker i.e Pharmacist, Doctor, Nurse
                      </option>
                      <option value="facility">
                        Licensed health Facility i.e Pharmacy, Hospital, Clinics
                      </option>
                    </select>
                  </div>
                  <div className="sm:col-span-3">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      value={form?.first_name}
                      disabled={loading}
                      onChange={(e) => updateForm('first_name', e)}
                      className="form-select"
                      placeholder="Your First Name (e.g John)"
                    />
                    { <p className="text-red-500 text-sm">{firstNameError}</p>}
                  </div>
                  <div className="sm:col-span-3">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      value={form?.last_name}
                      disabled={loading}
                      onChange={(e) => updateForm('last_name', e)}
                      className="form-select"
                      placeholder="Your Last Name (e.g Adewale)"
                    />
                    {<p className="text-red-500 text-sm">{lastNameError}</p>}
                  </div>
                  <div className="sm:col-span-6">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      value={form?.email.toLocaleLowerCase()}
                      disabled={loading}
                      onChange={(e) => updateForm('email', e)}
                      className="form-select"
                      placeholder="Your Email Address (e.g john.adewale@email.com)"
                      required
                    />
                    {form?.email.length > 0 && <p className="text-red-500 text-sm">{emailError}</p>}
                  </div>
                  <div className="sm:col-span-6">
                    <label className="form-label">Phone Number</label>
                    <input
                      type="text"
                      value={form?.phone ?? ''}
                      disabled={loading}
                      onChange={(e) => updateForm('phone', e)}
                      className="form-select"
                      placeholder="Your Phone Number (e.g 08033619901)"
                    />
                    {form?.phone !== '' && <p className="text-red-500 text-sm">{phoneNumberError}</p>}
                  </div>
                  <div className="relative sm:col-span-6">
                    <label className="form-label">Password</label>
                    <input
                      type={showPasswordText}
                      value={form?.password}
                      disabled={loading}
                      onChange={(e) => updateForm('password', e)}
                      className="form-select"
                      placeholder="Your Password"
                      required
                    />
                    <div
                  onClick={handlePasswordCheck}
                  className="absolute right-4 top-9 cursor-pointer">
                  {isShowPassword ? (
                    <EyeIcon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <EyeSlashIcon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </div>
                    {form?.password.length > 0 && form?.password.length < 11 && <p className="text-red-500 text-sm">{passwordError}</p>}
                  </div>
                  <div className="sm:col-span-6">
                    <button
                      type="button"
                      disabled={!profile.personal}
                      onClick={() => setStep(AppStep.ACCOUNT_INFO)}
                      className="base-button bg-blue-500">
                      Continue
                      <ArrowRightIcon className="-mr-2 ml-1 h-5 w-7" />
                    </button>
                  </div>
                  <div className="text-sm sm:col-span-6">
                    <a
                      href="/login"
                      className="text-center font-medium text-[#3b82f7] hover:text-[#1b6ef8]">
                      Already Have an Account? Log In
                    </a>
                  </div>
                </div>
              ) : step === AppStep.ACCOUNT_INFO ? (
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {category === 'facility' ? (
                    <div className="sm:col-span-6">
                      <label className="form-label">Business Name</label>
                      <input
                        type="text"
                        value={form?.business_name}
                        onChange={(e) => updateForm('business_name', e)}
                        className="form-select"
                        placeholder="Your Business Name (e.g XYC Facility LTD)"
                      />
                    </div>
                  ) : null}
                  <div className="sm:col-span-3">
                    <label className="form-label">Category</label>
                    <select
                      value={form?.category}
                      disabled={loading}
                      onChange={(e: any) => updateForm('category', e)}
                      className="form-select">
                      <option selected disabled value="">
                        Select a category
                      </option>
                      {handleBusinessCategory().map((el: IOptions) => (
                        <option key={el.id} value={el.value}>
                          {el.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="sm:col-span-3">
                    <label className="form-label">Location</label>
                    <select
                      value={form?.location}
                      disabled={loading}
                      onChange={(e: any) => updateForm('location', e)}
                      className="form-select">
                      <option selected disabled value="">
                        Select a location
                      </option>
                      {Location.map((el: IOptions) => (
                        <option key={el.id} value={el.value}>
                          {el.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="sm:col-span-3">
                    <label className="form-label">
                      How Did you hear about Us?
                    </label>
                    <select
                      value={form?.discovery}
                      disabled={loading}
                      onChange={(e: any) => updateForm('discovery', e)}
                      className="form-select">
                      <option selected disabled value="">
                        Select an option
                      </option>
                      {Discovery.map((el: IOptions) => (
                        <option key={el.id} value={el.value}>
                          {el.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="sm:col-span-3">
                    <label className="form-label">Referral Code</label>
                    <input
                      type="text"
                      value={form?.ref_code}
                      disabled={loading}
                      onChange={(e) => updateForm('ref_code', e)}
                      className="form-select"
                      placeholder="Referral Code (e.g ADE0234)"
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <div className="flex flex-row justify-between">
                      <button
                        type="button"
                        onClick={() => setStep(AppStep.PERSONAL_INFO)}
                        className="base-button-outline">
                        <ArrowLeftIcon className="-ml-2 mr-1 h-5 w-7" />
                        Back
                      </button>
                      <button
                        type="button"
                        disabled={!profile.account}
                        onClick={() => setStep(AppStep.CONFIRMATION)}
                        className="base-button bg-blue-500">
                        Preview Information
                        <ArrowRightIcon className="-mr-2 ml-1 h-5 w-7" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : step === AppStep.CONFIRMATION ? (
                <div className="overflow-hidden bg-white sm:rounded-lg">
                  {error.hasError ? (
                    <div className="sm:col-span-6">
                      <p className="form-label text-center text-red-600">
                        {error.message}
                      </p>
                    </div>
                  ) : null}
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Registration Summary
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Personal details and application.
                    </p>
                  </div>
                  <div className="border-t border-gray-200">
                    <dl>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Full name
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {form?.first_name} {form.last_name}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Phone Number
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {form?.phone}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Email address
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {form.email}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Location
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {
                            Location.filter(
                              (el) => el.value === form.location
                            )[0].name
                          }
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Category
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {
                            handleBusinessCategory().filter(
                              (el) => el.value === form?.category
                            )[0].name
                          }
                        </dd>
                      </div>
                      {category === 'facility' ? (
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Business Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {form?.business_name}
                          </dd>
                        </div>
                      ) : null}
                    </dl>
                    <div className="flex flex-row justify-between py-4 pl-1">
                      <button
                        type="button"
                        disabled={loading}
                        onClick={() => setStep(AppStep.ACCOUNT_INFO)}
                        className="base-button-outline">
                        <ArrowLeftIcon className="-ml-2 mr-1 h-5 w-7" />
                        Back
                      </button>
                      <button
                        type="button"
                        disabled={loading}
                        onClick={() => signup(form)}
                        className="base-button bg-blue-500">
                        {loading ? <Loader /> : null}
                        {loading ? 'Please wait' : 'Sign Up To DrugStoc'}
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterPage
