/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState } from 'react'
import moment from 'moment'
import { IOrderItem } from './interface'
import { Formatter } from '../../utilities/utils'
import Modal from '../../pages/Home/Modal/Modal'

const OrderItem: React.FC<IOrderItem> = ({ data/* , orders */}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)

  const handleViewOrderClick = (order: any): any => {
    setSelectedOrder(order)
    setIsModalOpen(true)
  }

  const closeModal = (e: any): any => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(false)
    }
  }
  // console.log(orders)
  // console.log([...orders?.results])

  return (
    <div className="overflow-hidden rounded-lg border">
      <div className="items-center justify-between p-6 md:flex">
        <div className="items-center gap-x-8 md:flex">
          <div className="flex items-center justify-between md:block">
            <p className="text-center text-base font-medium text-gray-800">
              Order number
            </p>
            <p className="text-center text-sm uppercase text-gray-600">
              {data.order_no}
            </p>
          </div>
          <div className="flex items-center justify-between md:block">
            <p className="text-center text-base font-medium text-gray-800">
              Date Placed
            </p>
            <p className="text-center text-sm text-gray-600">
              {moment(data.created_at).format('ll')}
            </p>
          </div>
          <div className="flex items-center justify-between md:block">
            <p className="text-center text-base font-medium text-gray-800">
              Total amount
            </p>
            <p className="text-center text-sm font-medium text-gray-800">
              &#8358;{Formatter(parseInt(data.total_amount))}
            </p>
          </div>
        </div>
        <div className="mt-5 flex md:mt-0">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-[#3b82f7] px-5 py-1.5 text-center text-sm font-medium text-white hover:bg-[#3b82f7] disabled:bg-white"
            onClick={() => handleViewOrderClick(data)}>
            View Order
          </button>
          {isModalOpen && selectedOrder && (
            <Modal
              data={selectedOrder}
              closeModal={closeModal}
              showDeleteModal={isModalOpen}
            />
          )}
        </div>
      </div>
      {data.items.map((el: any, index: any) => (
        <div key={index} className="flex border-b p-6" style={{display: 'none'}}>
          <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 md:h-36 md:w-36">
            <img
              src={el.product.image}
              alt={el.product.name}
              className="h-full w-full object-cover object-center"
            />
          </div>

          <div className="ml-4 flex w-[50%] flex-1 flex-col">
            <div>
              <div className="justify-between text-base font-medium text-gray-900 md:flex">
                <h3 className="truncate">
                  <a href={`/product/${el.product.slug}`}>{el.product.name}</a>
                </h3>
                <p className="md:ml-4">
                  &#8358;{' '}
                  {Formatter(
                    parseInt(el.unit_price.toString()) *
                      parseInt(el.quantity.toString())
                  )}
                </p>
              </div>
              <p className="mt-1 text-sm text-gray-500">{el.product.SKU}</p>
            </div>
            <div className="items-end justify-between text-sm md:flex md:flex-1">
              <div>
                <div className="flex space-x-2"></div>
              </div>

              <div className="flex">
                <a
                  href={`/product/${el.product.slug}`}
                  className="font-medium text-[#3b82f7] hover:text-[#3b82f7]">
                  View Details
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default OrderItem
