export const Formatter = (amount: number): string => {
  return amount?.toFixed().replace(/./g, function (c, i, a) {
    return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
  })
}

export const FormatterAccount = (amount: number): string => {
  return amount?.toFixed(2).replace(/./g, function (c, i, a) {
    return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
  })
}

export const PriceFormatter = (amount: any): any => {
  const formattedAmount = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  }).format(amount)

  return formattedAmount
}