import React, { ChangeEvent } from 'react'
import { useHandleBusiness } from '../../hooks/handleBusiness'
import { useCart } from '../../hooks/handleCart'
import Layout from '../../layout'
import Sidebar from '../../layout/Sidebar/Sidebar'
import { IOptions, Location } from '../../utilities/option'

const BusinessPage: React.FC<{}> = () => {
  const { form, loading, updateForm, isBusiness, updateBusiness } =
    useHandleBusiness()

  const {
    cartItems,
    loading: cartLoading,
    deleteCartItem,
    updateCartItem,
    totalCost,
  } = useCart()

  return (
    <Layout
      data={cartItems}
      totalCost={totalCost}
      loading={cartLoading}
      updateCartItem={updateCartItem as any}
      deleteCartItem={deleteCartItem}
      cartItems={cartItems}>
      <Sidebar title="Business Information" pathname="Business Information">
        <div className="bg-gray-50 p-6">
          <h2 className="sm:text-base">Business Information</h2>
        </div>
        <div className="p-6">
          <div className="grid gap-x-6 gap-y-6 sm:grid-cols-4 md:grid-cols-6">
            <div className="grid gap-x-6 gap-y-6 rounded-lg bg-slate-50 px-6 py-10 sm:col-span-6 sm:col-start-2 md:col-start-2 md:grid-cols-4">
              {isBusiness ? (
                <div className="sm:col-span-4 ">
                  <label className="form-label">Business Name</label>
                  <input
                    type="text"
                    value={form?.name ?? ''}
                    disabled={loading}
                    onChange={(e) => updateForm('name', e)}
                    className="form-select"
                    placeholder="Your Business Name"
                  />
                </div>
              ) : null}
              <div className="sm:col-span-4 ">
                <label className="form-label">Contact Email Address</label>
                <input
                  type="text"
                  value={form?.email ?? ''}
                  disabled
                  onChange={(e) => updateForm('email', e)}
                  className="form-select"
                  placeholder="Your Contact email"
                />
              </div>
              <div className="sm:col-span-4 ">
                <label className="form-label">Phone Number</label>
                <input
                  type="text"
                  value={form?.phone ?? ''}
                  disabled={loading}
                  onChange={(e) => updateForm('phone', e)}
                  className="form-select"
                  placeholder="Your Phone Number"
                />
              </div>
              <div className="sm:col-span-4 ">
                <label className="form-label">About</label>
                <textarea
                  rows={6}
                  className="form-select"
                  value={form?.about ?? ''}
                  disabled={loading}
                  onChange={(
                    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any
                  ) => updateForm('about', e)}
                  placeholder="Write about your business here..."></textarea>
              </div>
              <div className="sm:col-span-4 ">
                <label className="form-label">Address</label>
                <input
                  type="text"
                  value={form?.address ?? ''}
                  disabled={loading}
                  onChange={(e) => updateForm('address', e)}
                  className="form-select"
                  placeholder="Your Address"
                />
              </div>
              <div className="sm:col-span-4 ">
                <label className="form-label">Date Establish</label>
                <input
                  type="date"
                  value={form?.year_established ?? ''}
                  disabled={loading}
                  onChange={(e) => updateForm('year_established', e)}
                  className="form-select"
                  placeholder="01/01/1900"
                  max={new Date().toISOString().split('T')[0]}
                />
              </div>
              <div className="sm:col-span-4 ">
                <label className="form-label">Location</label>
                <select
                  value={form?.location ?? ''}
                  disabled
                  className="form-select">
                  <option disabled value="">
                    Select a location
                  </option>
                  {Location.map((el: IOptions) => (
                    <option key={el.id} value={el.value}>
                      {el.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="sm:col-span-4 ">
                <button
                  type="button"
                  disabled={loading}
                  onClick={() => updateBusiness(form)}
                  className="base-button">
                  Save Information
                </button>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </Layout>
  )
}

export default BusinessPage
