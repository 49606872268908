export interface IOptions {
  id: number
  name: string
  value: string
}

export const Location: IOptions[] = [
  {
    id: 1,
    name: 'Lagos State',
    value: 'lagos',
  },
  {
    id: 2,
    name: 'Ogun State',
    value: 'ogun',
  },
  {
    id: 3,
    name: 'Oyo State',
    value: 'oyo',
  },
  {
    id: 4,
    name: 'Rivers State',
    value: 'cross-rivers',
  },
  {
    id: 5,
    name: 'Others',
    value: 'others',
  },
]

export const Discovery: IOptions[] = [
  {
    id: 1,
    name: 'LinkedIn',
    value: 'linkedin',
  },
  {
    id: 2,
    name: 'Instagram',
    value: 'instagram',
  },
  {
    id: 3,
    name: 'Acquisition Team',
    value: 'acquisition-team',
  },
  {
    id: 4,
    name: 'Facebook',
    value: 'facebook',
  },
  {
    id: 5,
    name: 'Google Search',
    value: 'google-search',
  },
  {
    id: 6,
    name: 'Sales Rep',
    value: 'sales-rep',
  },
  {
    id: 7,
    name: 'Referral',
    value: 'referral',
  },
  {
    id: 8,
    name: 'Others',
    value: 'others',
  },
]

export const FacilityCategories: IOptions[] = [
  {
    id: 1,
    name: 'Pharmacy',
    value: 'pharmacy',
  },
  {
    id: 2,
    name: 'Doctors Office',
    value: 'doctors-office',
  },
  {
    id: 3,
    name: 'Hospital',
    value: 'hospital',
  },
  {
    id: 4,
    name: 'Clinic',
    value: 'clinic',
  },
  {
    id: 5,
    name: 'Nursing Home',
    value: 'nursing-home',
  },
]

export const IndividualCategories: IOptions[] = [
  {
    id: 1,
    name: 'Doctor',
    value: 'doctor',
  },
  {
    id: 2,
    name: 'Nurse',
    value: 'nurse',
  },
  {
    id: 3,
    name: 'Pharmacist',
    value: 'pharmacist',
  },
]
