import { AxiosResponse } from 'axios'
import AxiosConfig from '../config/axiosConfig'

export const transactionPinUrl = '/drugstocpay/transaction/pin/verify'
export const createTransactionPinUrl = '/drugstocpay/wallet/create'

export const confirmTransactionPin = async (
  pin: string
): Promise<AxiosResponse> =>
  await AxiosConfig.post(`${transactionPinUrl}`, { transaction_pin: pin })

export const createWallet = async (pin: string): Promise<AxiosResponse> =>
  await AxiosConfig.post(createTransactionPinUrl, {
    transaction_pin: pin,
    currency: 'NGN',
  })
