/* eslint-disable linebreak-style */
import React from 'react'

const Spinner = (): any => {
  return (
    <div className="flex items-center justify-center">
      <div className="h-12 w-12 animate-spin rounded-full border-t-4 border-blue-500 border-t-blue-500"></div>
    </div>
  )
}

export default Spinner
