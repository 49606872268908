/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useRef, useState } from 'react'
import CartItem from './CartItem'
import { ICartListComponent } from './interface'
import Spinner from '../../layout/Spinner/Spinner'
import { cartList, cartUrl } from '../../Api/cart.api'
import useSWR from 'swr'

const BASE_URL = process.env.REACT_APP_BASE_URL

const CartList: React.FC<ICartListComponent> = ({
  data,
  deleteCartItem,
  updateCartItem,
  cartItems,
}) => {
  const [cartData, setCartData] = useState<any>(data ?? [])
  const [currentPage, setCurrentPage] = useState(1)
  const [isFetching, setIsFetching] = useState(false)
  const loaderRef = useRef(null)
  const [errorDetail, setErrorDetail] = useState<any>()
  const [loading, setLoading] = useState<boolean | null>(false)

  const { data: cartObj } = useSWR(cartUrl, cartList)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchData = async () => {
      if (isFetching || !currentPage) return

      setIsFetching(true)
      try {
        if (cartObj && cartObj.count > 0 && cartObj.next !== null) {
          const headers: HeadersInit = localStorage.getItem('token') === null ? {
            'Content-Type': 'application/json',
          }: {
            Authorization: `token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
          const response = await fetch(
            `${BASE_URL}/shopping/cart/cart-item?page=${currentPage || 1}`,
             
            {
              headers,
            },
 
          )

          const newData = await response.json()

          if (
            cartObj &&
            cartObj.count > 0 &&
            newData.detail &&
            newData.detail === 'Invalid page.'
          ) {
            setErrorDetail(`That's all in cart: No more page`)
            return
          }

          setCartData((prevData: any) => [
            ...prevData,
            ...(newData.results || []).filter(
              (newItem: any) =>
                !prevData.some((prevItem: any) => prevItem.id === newItem.id)
            ),
          ])
          setCurrentPage((prevPage: any) => +prevPage + 1)
          setLoading(true)
        }
      } catch (error: any) {
        setErrorDetail(`Error fetching data: ${error}`)
      } finally {
        setIsFetching(false)
      }
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          void fetchData()
        }
      },
      { threshold: 1 }
    )

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
      }
    }
  }, [currentPage /* , isFetching */])

  useEffect(() => {
    setCartData(data ?? [])
  }, [data])

  return (
    <div>
      {cartData.map((el: any, index: any) => (
        <CartItem
          key={`${el.id as string}-${index as string}`}
          id={el.id}
          product={el.product}
          quantity={el.quantity}
          unit_price={el.unit_price}
          updateCartItem={updateCartItem}
          deleteCartItem={deleteCartItem}
          cartItems={cartItems}
        />
      ))}

      {loading && !errorDetail && (
        <div className="my-1">
          <Spinner />
        </div>
      )}

      {errorDetail && (
        <>
          <p className="flex items-center justify-center">{errorDetail}</p>
          {cartObj && cartObj.count === 0 ? (
            <small className="flex justify-center">
              Cart is empty. Add to cart to see items
            </small>
          ) : (
            <small className="flex justify-center">
              Checkout to place your order
            </small>
          )}
        </>
      )}

      <div ref={loaderRef} className="h-[10px]"></div>
    </div>
  )
}

export default CartList
