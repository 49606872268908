import React, { useState } from 'react';
import { useCart } from '../../hooks/handleCart';
import { useSettings } from '../../hooks/useSettings';
import Layout from '../../layout';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/outline';

const PasswordInputWithToggle: React.FC<{
  label: string;
  value: string | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ label, value, onChange }) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handlePasswordToggle = (): void => {
    setIsShowPassword(!isShowPassword);
  };

  return (
    <div className="relative sm:col-span-4">
      <label className="form-label">{label}</label>
      <input
        type={isShowPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        className="form-select"
        placeholder={label}
      />
      <div onClick={handlePasswordToggle} className="absolute right-4 top-9 cursor-pointer">
        {isShowPassword ? (
          <EyeIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
        ) : (
          <EyeSlashIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
        )}
      </div>
    </div>
  );
};

const ChangePasswordPage: React.FC<{}> = () => {
  const { passwordForm, loading, validForm, updatePasswordForm, changePassword } = useSettings();
  const { cartItems, loading: cartLoading, deleteCartItem, totalCost, updateCartItem } = useCart();

  return (
    <Layout cartItems={cartItems} data={cartItems} totalCost={totalCost} loading={cartLoading} updateCartItem={updateCartItem as any} deleteCartItem={deleteCartItem}>
      <Sidebar title="Change Password" pathname="Change Password">
        <div className="bg-gray-50 p-6">
          <h2 className="sm:text-base">Change Password</h2>
        </div>
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-6">
            <div className="grid gap-x-6 gap-y-6 rounded-lg bg-slate-50 px-6 py-10 sm:col-span-4 sm:col-start-2 md:grid-cols-4">
              <PasswordInputWithToggle label="Current Password" value={passwordForm.old_password} onChange={(e) => updatePasswordForm('old_password', e)} />
              <PasswordInputWithToggle label="New Password" value={passwordForm.new_password} onChange={(e) => updatePasswordForm('new_password', e)} />
              <PasswordInputWithToggle label="Confirm Password" value={passwordForm.confirm_password} onChange={(e) => updatePasswordForm('confirm_password', e)} />
              <div className="sm:col-span-4">
                <button type="button" disabled={loading || validForm} onClick={() => changePassword(passwordForm)} className="base-button">
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </Layout>
  );
};

export default ChangePasswordPage;
