/* eslint-disable linebreak-style */
import React from 'react'

const Modal: any = ({
  showDeleteModal,
  closeModal,
  data /* , handlClick  */,
}: any) => {
  const formatDate = (inputDate: any): any => {
    const options: any = { year: 'numeric', month: 'short', day: 'numeric' }
    const formattedDate = new Date(inputDate).toLocaleDateString(
      undefined,
      options
    )
    return formattedDate
  }

  const originalDate = data.created_at
  const formattedDate = formatDate(originalDate)

  // const status = ['pending', 'confirmed', 'packing', 'dispatched', 'delivered']

  const formatter = (amount: any): any => {
    const formattedAmount = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
    }).format(amount)

    return formattedAmount
  }

  return (
    <div>
      {Boolean(showDeleteModal) && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={closeModal}>
          <div
            className="animate-fadeIn h-[500px] w-[80%] rounded-sm bg-white shadow-md"
            style={{ overflowY: 'auto' }}>
            {/* <div className="relative">
              <button
                className="absolute right-2 top-2 cursor-pointer text-gray-600 hover:text-gray-800"
                onClick={handlClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div> */}
            <div>
              <>
                <div
                  key={data.id}
                  className="p-6"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #C9C5CA',
                    paddingBottom: '23px',
                    flexWrap: 'wrap',
                  }}>
                  <div>
                    <div>
                      <span style={{ fontSize: '14px' }}>Order number</span>
                    </div>
                    <div>
                      <b style={{ fontSize: '15px' }}>{data.order_no}</b>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span style={{ fontSize: '14px' }}>Date Placed</span>
                    </div>
                    <div>
                      <b style={{ fontSize: '15px' }}>{formattedDate}</b>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span style={{ fontSize: '14px' }}>Total amount</span>
                    </div>
                    <div>
                      <b style={{ fontSize: '15px' }}>
                        {formatter(data.total_amount)}
                      </b>
                    </div>
                  </div>
                </div>
                <div style={{ borderBottom: '1px solid #C9C5CA' }}>
                  <div className="p-6">
                    <div style={{ fontSize: 15 }}>
                      <b>Delivery Address:</b>{' '}
                      <span>{data?.shipping_address.address_line1}</span>
                    </div>
                    {/* <div style={{ fontSize: 15, marginTop: 5 }}>
                            <b>Payment Method:</b> <span>DrugStoc Pay</span>
                          </div> */}

                    <div
                      className="overflowStatus"
                      style={{ overflowX: 'auto' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: 15,
                          minWidth: 780,
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            height: 24,
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 31 31"
                            fill="none">
                            <g clipPath="url(#clip0_62228_56913)">
                              <path
                                d="M25.7072 1.25228C25.2505 1.04187 24.7124 1.12375 24.3389 1.46034L22.368 3.20541C22.3144 3.25313 22.2335 3.25285 22.1802 3.20484L19.4985 0.806405C19.0135 0.371823 18.2793 0.371823 17.7943 0.806405L15.1151 3.20264C15.0612 3.25079 14.9797 3.25079 14.9258 3.20264L12.2463 0.806405C11.7613 0.371894 11.0271 0.371894 10.5421 0.806405L7.86011 3.20484C7.80643 3.25285 7.72534 3.25306 7.67137 3.20534L5.69871 1.46034C5.17536 0.994937 4.3738 1.04187 3.9084 1.56522C3.6997 1.79991 3.58551 2.10369 3.58793 2.4177V28.5845C3.58324 29.2859 4.14806 29.8584 4.84949 29.863C5.16371 29.8652 5.4675 29.7507 5.70226 29.5419L7.67314 27.7968C7.72676 27.7491 7.80764 27.7494 7.86097 27.7974L10.5426 30.1958C11.0276 30.6304 11.7618 30.6304 12.2468 30.1958L14.9263 27.7996C14.9802 27.7514 15.0617 27.7514 15.1156 27.7996L17.7951 30.1957C18.2801 30.6301 19.0143 30.6301 19.4993 30.1957L22.1814 27.7973C22.2351 27.7493 22.3162 27.7491 22.3702 27.7968L24.3429 29.5418C24.8664 30.007 25.6679 29.9597 26.1331 29.4361C26.3414 29.2017 26.4555 28.8982 26.4532 28.5845V2.4177C26.4599 1.91502 26.1665 1.45665 25.7072 1.25228ZM8.2893 10.6724H15.3609C15.6746 10.6724 15.929 10.9268 15.929 11.2405C15.929 11.5542 15.6746 11.8086 15.3609 11.8086H8.2893C7.97558 11.8086 7.72122 11.5542 7.72122 11.2405C7.72122 10.9268 7.97558 10.6724 8.2893 10.6724ZM21.7518 20.3298H8.2893C7.97558 20.3298 7.72122 20.0754 7.72122 19.7617C7.72122 19.448 7.97558 19.1936 8.2893 19.1936H21.7518C22.0656 19.1936 22.3199 19.448 22.3199 19.7617C22.3199 20.0754 22.0656 20.3298 21.7518 20.3298ZM21.7518 16.0692H8.2893C7.97558 16.0692 7.72122 15.8148 7.72122 15.5011C7.72122 15.1874 7.97558 14.933 8.2893 14.933H21.7518C22.0656 14.933 22.3199 15.1874 22.3199 15.5011C22.3199 15.8148 22.0656 16.0692 21.7518 16.0692Z"
                                fill={`${
                                  data.status === 'pending' ? '#0A8200' : '#ccc'
                                }`}
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_62228_56913">
                                <rect
                                  width="30.0412"
                                  height="30.0412"
                                  fill="white"
                                  transform="translate(0 0.480469)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <span
                            style={{
                              color:
                                data.status === 'pending' ? '#0A8200' : '#ccc',
                              cursor: 'pointer',
                            }}>
                            Order Recieved
                          </span>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            height: 24,
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 31 31"
                            fill="none">
                            <g clipPath="url(#clip0_62228_56919)">
                              <path
                                d="M25.7119 20.9582L24.6565 22.3659L26.0644 23.4223H22.888C22.4945 21.908 21.1285 20.7819 19.4924 20.7819H10.6913C9.05532 20.7819 7.68931 21.908 7.29578 23.4223H5.35197C3.41045 23.4223 1.83151 21.8434 1.83151 19.9018C1.83151 17.9603 3.41045 16.3814 5.35197 16.3814H7.29578C7.68931 17.8957 9.05532 19.0217 10.6913 19.0217H19.4924C21.1285 19.0217 22.4945 17.8957 22.888 16.3814H24.8318C27.7437 16.3814 30.1125 14.0126 30.1125 11.1007C30.1125 8.18877 27.7437 5.82003 24.8318 5.82003H22.888C22.4945 4.30573 21.1285 3.17969 19.4924 3.17969H10.6913C9.05532 3.17969 7.68931 4.30573 7.29578 5.82003H6.52545L3.23924 3.35594L2.18402 4.76366L3.59197 5.82003H0.0712891V7.58025H3.59197L2.18402 8.63662L3.23924 10.0443L6.52545 7.58025H7.29578C7.68931 9.09455 9.05532 10.2206 10.6913 10.2206H19.4924C21.1285 10.2206 22.4945 9.09455 22.888 7.58025H24.8318C26.7733 7.58025 28.3523 9.15918 28.3523 11.1007C28.3523 13.0422 26.7733 14.6212 24.8318 14.6212H22.888C22.4945 13.1069 21.1285 11.9808 19.4924 11.9808H10.6913C9.05532 11.9808 7.68931 13.1069 7.29578 14.6212H5.35197C2.44003 14.6212 0.0712891 16.9899 0.0712891 19.9018C0.0712891 22.8138 2.44003 25.1825 5.35197 25.1825H7.29578C7.68931 26.6968 9.05532 27.8229 10.6913 27.8229H19.4924C21.1285 27.8229 22.4945 26.6968 22.888 25.1825H26.0644L24.6565 26.2389L25.7119 27.6466L30.1125 24.3024L25.7119 20.9582Z"
                                fill={`${
                                  data.status === 'confirmed' ||
                                  data.status === 'packing'
                                    ? '#0A8200'
                                    : '#ccc'
                                }`}
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_62228_56919">
                                <rect
                                  width="30.0412"
                                  height="30.0412"
                                  fill="white"
                                  transform="translate(0.0712891 0.480469)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <span
                            style={{
                              color:
                                data.status === 'confirmed' ||
                                data.status === 'packing'
                                  ? '#0A8200'
                                  : '#ccc',
                              cursor: 'pointer',
                            }}>
                            Order Processed
                          </span>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            height: 24,
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 31 31"
                            fill="none">
                            <g clipPath="url(#clip0_62228_56923)">
                              <path
                                d="M0.141602 21.1481C0.141602 21.4561 0.357429 21.6978 0.66542 21.6978H1.50175C2.0637 20.4582 3.34314 19.5203 4.8287 19.5203C6.31433 19.5203 7.59348 20.4582 8.15572 21.6978H19.0265V17.25H0.141602V21.1481Z"
                                fill={`${
                                  data.status === 'confirmed' ||
                                  data.status === 'packing'
                                    ? '#0A8200'
                                    : '#ccc'
                                }`}
                              />
                              <path
                                d="M4.8295 20.6992C3.46797 20.6992 2.36426 21.8029 2.36426 23.1645C2.36426 24.526 3.46797 25.6297 4.8295 25.6297C6.19038 25.6282 7.29321 24.5254 7.29474 23.1645C7.29474 21.803 6.19104 20.6992 4.8295 20.6992Z"
                                fill={`${
                                  data.status === 'dispatched'
                                    ? '#0A8200'
                                    : '#ccc'
                                }`}
                              />
                              <path
                                d="M29.8619 15.4382L27.514 14.4062H20.1934V21.6977H21.6143C22.1766 20.4582 23.4557 19.5202 24.9413 19.5202C26.4268 19.5202 27.7063 20.4582 28.2682 21.6977H29.6589C29.9669 21.6977 30.1827 21.456 30.1827 21.148V15.9686C30.1904 15.7438 30.0646 15.5357 29.8619 15.4382Z"
                                fill={`${
                                  data.status === 'dispatched'
                                    ? '#0A8200'
                                    : '#ccc'
                                }`}
                              />
                              <path
                                d="M25.1002 10.205C24.8072 9.64315 24.2222 9.29484 23.5886 9.3049H20.1934V13.2423H26.6766L25.1002 10.205Z"
                                fill={`${
                                  data.status === 'dispatched'
                                    ? '#0A8200'
                                    : '#ccc'
                                }`}
                              />
                              <path
                                d="M17.3521 5.36719H1.80726C0.869654 5.36719 0.141602 6.13906 0.141602 7.07667V16.0856H19.0265V7.07667C19.0265 7.07404 19.0266 7.07142 19.0266 7.06879C19.0341 6.13658 18.2844 5.3747 17.3521 5.36719Z"
                                fill={`${
                                  data.status === 'dispatched'
                                    ? '#0A8200'
                                    : '#ccc'
                                }`}
                              />
                              <path
                                d="M24.9408 20.6992C23.5792 20.6993 22.4755 21.803 22.4756 23.1646C22.4757 24.5261 23.5794 25.6298 24.9409 25.6297C26.3018 25.6282 27.4045 24.5254 27.4061 23.1645V23.1644C27.406 21.8029 26.3023 20.6992 24.9408 20.6992Z"
                                fill={`${
                                  data.status === 'dispatched'
                                    ? '#0A8200'
                                    : '#ccc'
                                }`}
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_62228_56923">
                                <rect
                                  width="30.0412"
                                  height="30.0412"
                                  fill="white"
                                  transform="translate(0.142578 0.480469)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <span
                            style={{
                              color:
                                data.status === 'dispatched'
                                  ? '#0A8200'
                                  : '#ccc',
                              cursor: 'pointer',
                            }}>
                            Order Shipped
                          </span>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            height: 24,
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 31 31"
                            fill="none">
                            <g clipPath="url(#clip0_62228_56935)">
                              <path
                                d="M22.4938 17.0039C18.7683 17.0039 15.7373 20.0349 15.7373 23.7604C15.7373 27.4859 18.7683 30.5169 22.4938 30.5169C26.2193 30.5169 29.2503 27.4859 29.2503 23.7604C29.2503 20.0349 26.2194 17.0039 22.4938 17.0039ZM21.7046 26.5916L19.1622 24.0749L20.4021 22.8223L21.6947 24.1019L24.5805 21.2002L25.8302 22.443L21.7046 26.5916Z"
                                fill={`${
                                  data.status === 'delivered'
                                    ? '#0A8200'
                                    : '#ccc'
                                }`}
                              />
                              <path
                                d="M14.2107 0.480469L1.21973 7.98079V22.9814L14.2107 30.4818L16.1022 29.3897C15.7123 28.9472 15.3676 28.464 15.0753 27.9474L14.2107 28.4465L13.3294 27.9377V15.9899L2.9823 10.016V8.99838L3.86358 8.48956L14.2107 14.4635L18.5029 11.9853L8.15584 6.01145L9.91841 4.99386L20.2655 10.9677L24.5577 8.48956L25.439 8.99838V10.016L15.0919 15.9899V19.5531C16.5603 16.9826 19.3284 15.2461 22.4947 15.2461C24.2336 15.2461 25.8522 15.7702 27.2016 16.6681V7.98079L14.2107 0.480469Z"
                                fill={`${
                                  data.status === 'delivered'
                                    ? '#0A8200'
                                    : '#ccc'
                                }`}
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_62228_56935">
                                <rect
                                  width="30.0412"
                                  height="30.0412"
                                  fill="white"
                                  transform="translate(0.213867 0.480469)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <span
                            style={{
                              color:
                                data.status === 'delivered'
                                  ? '#0A8200'
                                  : '#ccc',
                              cursor: 'pointer',
                            }}>
                            Order Delivered
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {data.items?.map((item: any, index: any) => {
                  const truncatedDesc =
                    item.product.desc.length > 70
                      ? // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                      item.product.desc.slice(0, 70) + '...'
                      : item.product.desc
                  return (
                    <div key={index}>
                      <div
                        style={{
                          backgroundColor: 'rgba(227, 226, 228, 0.12)',
                        }}>
                        <div
                          className="p-6"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: 200,
                            marginBlock: 20,
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 15,
                              alignItems: 'center',
                            }}>
                            {/* <Link to={`/product/${item.product.slug}`} style={{display: 'contents'}}> */}
                            <img
                              width="15%"
                              height="200px"
                              style={{ border: '1px solid #C9C5CA' }}
                              src={item.product.image}
                            />
                            {/* </Link> */}
                            <div>
                              <p>
                                <b title={item.product.desc}>{truncatedDesc}</b>
                              </p>
                              <p style={{ color: '#AEAAAE' }}>
                                {item.product.SKU}
                              </p>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  gap: 10,
                                  width: 'auto',
                                }}>
                                <span
                                  style={{
                                    color: '#484649',
                                    backgroundColor: '#F4EFF4',
                                    padding: '0.15638rem 1.56363rem',
                                    border: '1px solid #F4EFF4',
                                  }}>
                                  {formatter(item.unit_price)}
                                </span>
                                <span
                                  style={{
                                    color: '#484649',
                                    backgroundColor: '#F4EFF4',
                                    padding: '0.15638rem 1.56363rem',
                                    border: '1px solid #F4EFF4',
                                  }}>
                                  {item.quantity} pieces
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <span
                              style={{
                                fontWeight: 700,
                                fontSize: '1.12rem',
                              }}>
                              {formatter(item.unit_price * item.quantity)}
                              {/* ₦{item.unit_price * item.quantity} */}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Modal
