/* eslint-disable linebreak-style */
import React from 'react'
import { useShipping } from '../../hooks/handleShipping'
import { useParams } from 'react-router-dom'
import { IOptions, Location } from '../../utilities/option'

interface IParams {
  id?: number
}

const ShippingForm = ({shouldModal}: any): any => {
  const params: IParams = useParams()
  const {
    loading,
    shippingForm,
    addShippingAddress,
    updateShippingForm,
    editShippingAddress,
  } = useShipping(params.id)
  return (
    <div className={`p-6 ${shouldModal === true ? 'flex justify-center align-middle w-full h-full' : ''}`}>
      <div className={`grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-6 ${shouldModal === true ? 'w-full' : ''}`}>
        <div className="grid gap-x-6 gap-y-6 rounded-lg bg-slate-50 px-6 py-10 sm:col-span-4 sm:col-start-2 md:grid-cols-6">
          <div className="sm:col-span-3">
            <label className="form-label">First Name</label>
            <input
              type="text"
              value={shippingForm?.first_name}
              disabled={loading}
              onChange={(e) => updateShippingForm('first_name', e)}
              className="form-select"
              placeholder="Your First Name (e.g John)"
            />
          </div>
          <div className="sm:col-span-3">
            <label className="form-label">Last Name</label>
            <input
              type="text"
              value={shippingForm?.last_name}
              disabled={loading}
              onChange={(e) => updateShippingForm('last_name', e)}
              className="form-select"
              placeholder="Your Last Name (e.g Adewale)"
            />
          </div>
          <div className="sm:col-span-6">
            <label className="form-label">Address</label>
            <input
              type="text"
              value={shippingForm?.address_line1}
              disabled={loading}
              onChange={(e) => updateShippingForm('address_line1', e)}
              className="form-select"
              placeholder="Your Address (e.g 29 Taiwo Street)"
            />
          </div>
          <div className="sm:col-span-6">
            <label className="form-label">Address</label>
            <input
              type="text"
              value={shippingForm?.address_line2}
              disabled={loading}
              onChange={(e) => updateShippingForm('address_line2', e)}
              className="form-select"
              placeholder="Alternative Address (e.g 1b Taiwo Street)"
            />
          </div>
          <div className="sm:col-span-3">
            <label className="form-label">Mobile Phone Number</label>
            <input
              value={shippingForm?.phone_number1}
              disabled={loading}
              onChange={(e) => updateShippingForm('phone_number1', e)}
              className="form-select"
              placeholder="Your Phone Number"
            />
          </div>
          <div className="sm:col-span-3">
            <label className="form-label">Home Phone Number</label>
            <input
              type="text"
              value={shippingForm?.phone_number2}
              disabled={loading}
              onChange={(e) => updateShippingForm('phone_number2', e)}
              className="form-select"
              placeholder="Your Alternative Number"
            />
          </div>
          <div className="sm:col-span-6">
            <label className="form-label">Additional Information</label>
            <textarea
              rows={6}
              className="form-select"
              value={shippingForm?.additional_information}
              disabled={loading}
              onChange={(e) => updateShippingForm('additional_information', e)}
              placeholder="Write about your business here..."></textarea>
          </div>
          <div className="sm:col-span-6">
            <label className="form-label">Region</label>
            <select
              value={shippingForm?.region}
              disabled={loading}
              onChange={(e: any) => updateShippingForm('region', e)}
              className="form-select">
              <option selected disabled value="">
                Select a Region
              </option>
              {Location.map((el: IOptions) => (
                <option key={el.id} value={el.value}>
                  {el.name}
                </option>
              ))}
            </select>
          </div>
          <div className="sm:col-span-6">
            <input
              id="default-checkbox"
              checked={shouldModal === true ? true : shippingForm?.is_default_address}
              onChange={(e: any) => updateShippingForm('is_default_address', e)}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
            />
            <label
              htmlFor="default-checkbox"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Make this your default address
            </label>
          </div>
          <div className="sm:col-span-4 ">
            {params.id === undefined ? (
              <button
                type="button"
                disabled={loading}
                onClick={() => addShippingAddress(shippingForm)}
                className="base-button">
                Add Address
              </button>
            ) : (
              <button
                type="button"
                disabled={loading}
                onClick={() => editShippingAddress(shippingForm)}
                className="base-button">
                Update Address
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShippingForm
