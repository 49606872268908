import { validateEmail } from './../utilities/validator'
import { ChangeEvent, useCallback, useMemo, useState, useEffect } from 'react'

interface IUserProfile {
  email: string
  password: string
}

type IField = 'email' | 'password'

export interface IHandleLogin {
  updateForm: (fieldName: IField, event: ChangeEvent) => void
  form: IUserProfile
  formValid: boolean
}

export const useLogin = (): IHandleLogin => {
  const [formValid, setValidate] = useState<boolean>(false)
  const [form, setForm] = useState<IUserProfile>({
    email: '',
    password: '',
  })

  const handleFormValid = useCallback(() => {
    if (validateEmail(form.email) && form.password !== '') {
      setValidate(true)
    } else {
      setValidate(false)
    }
  }, [form, formValid])

  const updateForm = useCallback(
    (field: IField, e: ChangeEvent) => {
      setForm((prev) => ({
        ...prev,
        [field]: (e.target as any).value,
      }))
    },
    [form]
  )

  useEffect(() => {
    handleFormValid()
  }, [form])

  return useMemo(
    () => ({
      form,
      updateForm,
      formValid,
    }),
    [form, formValid]
  )
}
