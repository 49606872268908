import React from 'react'
import { IFilterData } from './interface'

const FilterOptions: React.FC<IFilterData> = ({
  loading,
  handleFilter,
  isChecked,
  uniqueFilteredManufacturerData,
  uniqueFilteredCompositionsData,
  uniqueFilteredCategoryData,
  uniqueSearchedManufacturerData,
  uniqueSearchedCompositionData,
  uniqueSearchedCategoryData,
}) => {
  const loadingWidth = (): string => {
    const list: string[] = ['w-36', 'w-48', 'w-44', 'w-52']
    const random = Math.floor(Math.random() * list.length)
    return list[random]
  }

  if (loading) {
    return (
      <ul className="no-scrollbar max-h-64 space-y-4 overflow-auto border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
        {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]?.map(
          (option, index: number) => (
            <li key={index}>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  disabled
                  className="h-4 w-4 rounded-xl border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label className="ml-3 text-sm text-gray-600">
                  <div
                    className={`h-2 ${loadingWidth()} rounded-full bg-gray-200 dark:bg-gray-700`}></div>
                </label>
              </div>
            </li>
          )
        )}
      </ul>
    )
  }
  
  let data = uniqueFilteredManufacturerData ?? uniqueFilteredCompositionsData ?? uniqueFilteredCategoryData ?? uniqueSearchedCategoryData ?? uniqueSearchedManufacturerData ?? uniqueSearchedCompositionData

  if (uniqueSearchedCategoryData?.length > 0 && (uniqueSearchedManufacturerData === undefined || uniqueSearchedCompositionData === undefined)) {
    data = uniqueSearchedCategoryData
  } else if (uniqueSearchedManufacturerData?.length > 0 && (uniqueSearchedCategoryData === undefined || uniqueSearchedCompositionData === undefined)) {
    data = uniqueSearchedManufacturerData
  } else if (uniqueSearchedCompositionData?.length > 0 && (uniqueSearchedCategoryData === undefined || uniqueSearchedManufacturerData === undefined)) {
    data = uniqueSearchedCompositionData
  }
  
  return (
    <ul className="no-scrollbar max-h-64 min-h-[16rem] space-y-4 overflow-auto border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
      {
        data?.map((option: any, index: number) => (
        <li key={index}>
          <div className="flex items-center">
            <input
              id={`filter-${index}`}
              defaultValue={option.value}
              type="checkbox"
              onChange={() => handleFilter(option)}
              checked={isChecked(option)}
              className="h-4 w-4 rounded-xl border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label
              htmlFor={`filter-${index}`}
              className="ml-3 truncate text-sm text-gray-600">
              {option.label}
            </label>
          </div>
        </li>
        ))}
    </ul>
  )
}

export default FilterOptions
