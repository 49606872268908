import React from 'react'
import { IDropDownLink } from './interface'

const loadingWidth = (): string => {
  const list: string[] = ['w-[75%]', 'w-[80%]', 'w-[65%]', 'w-[50%]']
  const random = Math.floor(Math.random() * list.length)
  return list[random]
}

const CategoryDropDownLink: React.FC<IDropDownLink> = ({ data, loading }) => {
  if (loading) {
    return (
      <div className="row-start-1 grid grid-cols-3 gap-x-2 gap-y-4 text-sm">
        {Array(50)
          .fill(null)
          .map((e, i) => (
            <div
              key={i}
              className={`h-2 ${loadingWidth()} w rounded-full bg-gray-200 dark:bg-gray-700`}></div>
          ))}
      </div>
    )
  }

  return (
    <div className="row-start-1 grid grid-cols-3 gap-x-2 gap-y-4 text-sm">
      {data?.map((e, i) => (
        <a href={`/category/${e.slug as string}`} key={i} className="truncate hover:text-[#3b82f7]">
          {e.name}
        </a>
      ))}
    </div>
  )
}

export default CategoryDropDownLink
