/* eslint-disable no-useless-escape */

export const validateEmail = (email: string): boolean => {
  const isValidEmail =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (email !== '' && email.match(isValidEmail) != null) {
    return true
  } else {
    return false
  }
}

export const validatePhoneNumber = (phone: string): boolean => {
  const isValidPhone =
    /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}|\d{11}$/
  if (phone !== '' && phone.match(isValidPhone) != null) {
    return true
  } else {
    return false
  }
}
