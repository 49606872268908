import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ISidebarMenu } from './Interface'
import CartList from '../Cart/CartList'
import { Formatter } from '../../utilities/utils'
import { useNavigate } from 'react-router-dom'
import { useHandleBusiness } from '../../hooks/handleBusiness'

const CartSideMenus: React.FC<ISidebarMenu> = ({
  open,
  setOpen,
  data,
  totalCost,
  deleteCartItem,
  updateCartItem,
  loading,
  cartItems,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const { data: isVerified } = useHandleBusiness()

  const handleMouseEnter = (): any => {
    if (isVerified?.is_verified === false) {
      setShowTooltip((prev) => {
        return !prev
      })
    }
  }
 
  const navigate = useNavigate()

  const onClick = (): void => {
    navigate('/document')
  }

  const cartTotal = localStorage.getItem('cart_total')
  const token = localStorage.getItem('token')
  const handleCheckoutClick = (): void => {
    navigate('/checkout')
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="CartSideMenu fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-screen flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="cartSideMenu flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Shopping cart
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}>
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8 flex flex-col items-center justify-center text-center">
                        <div className="flow-root">
                          <ul
                            role="list"
                            className="-my-6 divide-y divide-gray-200">
                            {cartTotal === '0' || token === null ? (
                              <div className="flex h-[60vh] flex-col items-center justify-center">
                                <img
                                  src="https://res.cloudinary.com/bizstak/image/upload/v1702060777/emptyCart_bza8k6.jpg"
                                  width="100%"
                                  height="100%"
                                  alt="empty cart"
                                />
                                <p className="text-center">
                                  Your Cart is Empty
                                </p>
                                {localStorage.getItem('token') !== null ? (
                                  <b className="text-sm">
                                    Add items to your cart to checkout
                                  </b>
                                ) : (
                                  <b className="text-sm">
                                    <a href='/login' className='text-blue-600'>Log in</a> to add items to your cart and proceed
                                    to checkout
                                  </b>
                                )}
                              </div>
                            ) : (
                              <CartList
                                loading={loading}
                                cartItems={cartItems}
                                data={data}
                                updateCartItem={updateCartItem}
                                deleteCartItem={deleteCartItem}
                              />
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <p>Subtotal</p>
                        {isVerified?.is_verified === true ? (
                          <p>&#8358; {Formatter(totalCost)}</p>
                        ) : (
                          <p>###</p>
                        )}
                      </div>
                      <p className="mt-0.5 text-sm text-gray-500">
                        Shipping and taxes calculated at checkout.
                      </p>
                      <div
                        className={`relative mt-6`}
                        onClick={handleMouseEnter}
                        // onMouseLeave={handleMouseLeave}
                      >
                        {/* cartTotal */}
                        <button
                          onClick={handleCheckoutClick}
                          disabled={cartTotal === '0' || cartTotal === null}
                          title={
                            cartTotal === '0'
                              ? 'Add item to your cart to checkout'
                              : cartTotal === null
                                ? 'Log in to add items to your cart and proceed to checkout'
                                : 'Checkout to place your order'
                          }
                          className="flex w-full items-center justify-center rounded-md border border-transparent bg-[#3b82f7] px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700">
                          Checkout
                        </button>
                        {showTooltip && isVerified?.is_verified === false && (
                          <div
                            className="tooltip cursor-pointer underline"
                            onClick={onClick}>
                            <p className="tooltip-text">
                              {/* Call customer care or sales rep to assist with the
                              order of this product. */}
                              Upload your license to easily checkout
                            </p>
                          </div>
                        )}
                      </div>

                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                        <p>
                          or{' '}
                          <button
                            type="button"
                            className="font-medium text-[#3b82f7] hover:text-[#3b82f7]"
                            onClick={() => setOpen(false)}>
                            Continue Shopping
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CartSideMenus
