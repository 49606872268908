import React from 'react'
import { useAccountSummary } from '../../hooks/useAccountSummary'
import {
  firstOrderContent,
  verificationContent,
  walletContent,
} from '../../utilities/content'
import ProgressCard from './ProgressCard.component'

const ProfileProgress: React.FC<{}> = () => {
  const { loading, hasMadeOrder, isVerified, hasWallet } = useAccountSummary()

  // console.log(hasMadeOrder)

  return (
    <div className="p-6">
      <div className="max-h- border-grey-background bg-grey-header relative overflow-hidden rounded-lg border opacity-100 transition-opacity duration-1000 ease-in">
        <div className="md:pt-6.25 md:pb-3.75 md:px-7.5 border-grey-card-outline flex items-center justify-between border-b bg-white px-6 pb-2.5 pt-5 sm:border-b-0">
          {!loading ? (
            <h4 className="text-md font-bold text-gray-600 md:text-base">
              Setup Progress
              <span className="ml-2.5 inline-block text-sm font-normal text-gray-400">
                1 / 3
              </span>
            </h4>
          ) : (
            <div role="status" className="max-w-sm animate-pulse">
              <div className="mb-4 h-2.5 w-44 rounded-full bg-gray-200 dark:bg-gray-700"></div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 text-left sm:grid-cols-3">
          <ProgressCard
            loading={loading}
            title={verificationContent.title}
            actionName={verificationContent.action}
            subtitle={verificationContent.subtitle}
            actionUrl="/document"
            isCompleted={isVerified}
            urgent={true}
          />
          <ProgressCard
            loading={loading}
            title={firstOrderContent.title}
            subtitle={firstOrderContent.subtitle}
            isCompleted={hasMadeOrder}
            actionUrl="/"
            actionName={firstOrderContent.action}
            urgent={false}
          />
          <ProgressCard
            loading={loading}
            title={walletContent.title}
            subtitle={walletContent.subtitle}
            isCompleted={hasWallet}
            actionUrl="/wallet"
            actionName={walletContent.action}
            urgent={false}
          />
        </div>
      </div>
    </div>
  )
}

export default ProfileProgress
