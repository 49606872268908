import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { IDropDownLinkData } from './../component/Dropdowns/interface'
import useSWR from 'swr'
import { manufacturerList } from '../Api/manufacturer.api'
import { IFilterOptions } from '../layout/Filters/interface'

interface IManufacturers {
  data: IDropDownLinkData[]
  filter: IFilterOptions[]
  loading: boolean
  filterManufacturer: (query: ChangeEvent<HTMLInputElement>) => void
  error: any
}

interface IResult {
  id: number
  name: string
  priority: number
  logo: string
  desc: string
  slug: string
  is_published: boolean
  created_at: Date
  updated_at: Date
}

// Find E.g Vaccines (url) - https://staging.drugstoc.com/api/v2/inventory/category/list
// https://staging.drugstoc.com/api/v2/inventory/category/list/vaccines - slug = vaccines (to get id)
// https://staging.drugstoc.com/api/v2/inventory/manufacturer/all?category=23

export const useManufacturer = (): IManufacturers => {
  const { data, error, isLoading } = useSWR(
    '/inventory/manufacturer/all',
    manufacturerList
  )

  const [dropDown, setData] = useState<IDropDownLinkData[]>([])
  const [filter, setFilter] = useState<IFilterOptions[]>([])

  const dropdownList = useCallback((): IDropDownLinkData[] => {
    if (!isLoading) {
      const result: IDropDownLinkData[] = data?.results.map((e: IResult) => ({
        name: e.name,
        link: `/manufacturers/${e.id}/${e.slug}`,
        image: e.logo,
      }))
      setData(result)
      const filterResult: IFilterOptions[] = data?.results.map((e: IResult) => ({
        value: e.id,
        label: e.name,
        checked: false,
        params: 'manufacturer',
      }))
      setFilter(filterResult)
    }
    return []
  }, [isLoading])

  const handleFilterQuery = async (query: string): Promise<void> => {
    const res = await manufacturerList(
      `/inventory/manufacturer/all?name__icontains=${query}`
    )
    const filterResult: IFilterOptions[] = res.results.map((e: IResult) => ({
      value: e.id,
      label: e.name,
      checked: false,
      params: 'manufacturer',
    }))
    setFilter(filterResult)
  }

  const filterManufacturer = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      void handleFilterQuery(event.target.value)
    },
    [filter]
  )

  useEffect(() => {
    dropdownList()
  }, [isLoading])

  return useMemo(
    () => ({
      data: dropDown,
      loading: isLoading,
      filter,
      filterManufacturer,
      error,
    }),
    [isLoading, dropDown, filter]
  )
}
