import useSWR from 'swr'
import { userBusiness } from '../Api/business.api'
import { userWallet } from '../Api/drugstocPay.api'
import { userOrders } from '../Api/orders.api'
import { useContext, useEffect } from 'react'
import { WalletContext } from '../context/Wallet/wallet.context'

interface IAccountSummary {
  loading: boolean
  isVerified: boolean
  hasWallet: boolean
  hasMadeOrder: boolean
}

export const useAccountSummary = (): IAccountSummary => {
  const { setHasWallet } = useContext(WalletContext)
  const { data, isLoading } = useSWR('/business/profile', userBusiness)
  const { data: orderData, isLoading: orderLoading } = useSWR(
    '/shopping/order/sales-order',
    userOrders
  )
  const { data: payData, isLoading: payLoading } = useSWR(
    '/drugstocpay/wallet/account',
    userWallet
  )

  useEffect(() => {
    if (payData !== undefined && 'balance' in payData) {
      setHasWallet?.(true)
    } else {
      setHasWallet?.(false);
    }
  }, [payData])

  return {
    loading: isLoading || orderLoading || payLoading,
    hasMadeOrder: (orderData?.count as number) > 0,
    hasWallet: payData?.balance !== undefined,
    isVerified: data?.is_verified,
  }
}
