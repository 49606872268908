import React from 'react'
import { ITransactionsView } from './interface'
import useWalletHandler, { ITransactions } from '../../hooks/handleWallet'
import { FormatterAccount } from '../../utilities/utils'
import Moment from 'react-moment'
import {
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/20/solid'

const TransactionView: ITransactionsView = () => {
  const { transactions } = useWalletHandler()

  const getStatus = (status: string): { bg: string; color: string } => {
    switch (status) {
      case 'pending':
        return {
          bg: 'bg-yellow-100',
          color: 'text-yellow-800',
        }
      case 'success':
        return {
          bg: 'bg-green-100',
          color: 'text-green-800',
        }
      case 'failed':
        return {
          bg: 'bg-red-100',
          color: 'text-red-800',
        }
      default:
        return {
          bg: 'bg-yellow-100',
          color: 'text-yellow-800',
        }
    }
  }

  return (
    <div className="mt-5">
      <p className="text-lg font-bold">All Transaction Detail</p>

      <div className="relative mt-5 h-[40rem] overflow-x-auto">
        <table className="w-full md:table-fixed table-auto  text-left text-sm text-gray-500 dark:text-gray-400">
          <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="w-[60%] px-6 py-3">
                Details
              </th>
              <th scope="col" className="px-6 py-3">
                Amount
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((data: ITransactions) => (
              <tr key={data.id} className="border-b bg-white hover:bg-gray-50">
                <th
                  scope="row"
                  className="inline-flex space-x-2 whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white">
                  <div className="inline-flex items-center">
                    <div className="relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-gray-100 dark:bg-gray-600">
                      {data.transaction_type === 'debit' ? (
                        <ArrowRightOnRectangleIcon className="h-6 w-6 text-red-500" />
                      ) : (
                        <ArrowLeftOnRectangleIcon className="h-6 w-6 text-green-500" />
                      )}
                    </div>
                  </div>
                  <div className="inline-flex items-center">
                    <div>
                      <p className="w-[250px] truncate">{data.remark}</p>
                      <p className="text-xs text-gray-400">
                        Transaction id: {data.transaction_type}
                      </p>
                      <Moment fromNow className='text-xs text-gray-400'>{data.created_at}</Moment>
                    </div>
                  </div>
                </th>
                <td className="px-6 py-4">₦{FormatterAccount(data.amount)}</td>
                <td className="px-6 py-4">
                  <span
                    className={`mr-2 rounded ${
                      getStatus(data.status).bg
                    } px-2.5 py-0.5 text-xs font-medium ${
                      getStatus(data.status).color
                    } capitalize`}>
                    {data.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TransactionView
