/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react'
import { useProductHandler } from '../../hooks/handleProducts'
import Layout from '../index'
import { useLocation, useNavigate } from 'react-router-dom'
import Spinner from '../Spinner/Spinner'
import Pagination from '../Pagination'
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/solid'

const ITEMS_PER_PAGE = 20
const BASE_URL = process.env.REACT_APP_BASE_URL

const CategoryList = (): any => {
  const [categoryList, setCategoryList] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<any>()
  const productHandler = useProductHandler()
  const { cartItems, updateCartItem, deleteCartItem, cartLoading, totalCost } =
    productHandler
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const currentPage = queryParams.get('page') ?? 1
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const handlePageClick = (page: number): void => {
    navigate(`/categories?page=${page}`)
  }

  const getAllCategories = async (): Promise<void> => {
    try {
      const response = await fetch(
        `${BASE_URL as string}/inventory/category/list?page=${currentPage}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      const json = await response.json()
      setCategoryList(json)
      setIsLoading(true)
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void getAllCategories()
  }, [currentPage])

  return (
    <>
      <Layout
        cartItems={cartItems}
        totalCost={totalCost}
        data={cartItems}
        loading={cartLoading}
        updateCartItem={updateCartItem as any}
        deleteCartItem={deleteCartItem}>
        {!isLoading ? (
          <div className="flex h-[400px] items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <h1 className="ml-[10%] mt-5 text-[1.5rem]">All Categories</h1>
            <div className="mx-auto my-5 flex flex-wrap justify-center gap-4 sm:w-[80%] sm:justify-between">
              {categoryList?.results?.map((item: any) => (
                <a
                  title={item.name}
                  key={item.id}
                  href={`/category/${item.slug as string}`}
                  className="flex h-20 w-96 items-center truncate rounded-xl border border-solid border-gray-200 text-[#6b7280] hover:text-[#3b82f7] sm:w-64">
                  <span className="rounded-4 p-2 text-center text-[0.8rem]">
                    {item.name}
                  </span>
                </a>
              ))}
            </div>
            <Pagination
              ITEMS_PER_PAGE={ITEMS_PER_PAGE}
              handlePageClick={handlePageClick}
              previousArrow={ArrowLeftCircleIcon}
              nextArrow={ArrowRightCircleIcon}
              TOTAL_ITEMS={categoryList?.count}
            />
          </>
        )}
      </Layout>
    </>
  )
}

export default CategoryList
