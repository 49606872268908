import { AxiosResponse } from 'axios'
import AxiosConfig from '../config/axiosConfig'
import { APIResponse, ICreateOrder } from './interface'

export const orderUrl = '/shopping/order/sales-order'

export const userOrders = async (url: string): Promise<APIResponse> =>
  await AxiosConfig.get(url).then((resp: AxiosResponse) => resp.data)

export const submitOrder = async (
  payload: ICreateOrder
): Promise<AxiosResponse> => await AxiosConfig.post(orderUrl, payload)
