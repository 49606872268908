import React from 'react'
import { IOrderList } from './interface'
import OrderItem from './OrderItem'
import Spinner from '../../layout/Spinner/Spinner'

const OrderList: React.FC<IOrderList> = ({ loading, data, orders, goToNextPage, goToPrevPage}) => {
  if (loading) {
    return <Spinner />
  }

  if (data?.length === 0) {
    return <p className='flex justify-center'>Your Order History is Empty</p>
  }

  const activeButton = 'bg-blue-600 py-2 px-4 rounded text-white'

  return (
    <div className="space-y-8">
      {data.map((el, index) => (
        <OrderItem key={index} data={el} orders={orders} />
      ))}
      <div className='flex justify-center gap-4'>
      <button disabled={orders.previous === null} className={activeButton} onClick={goToPrevPage}>&lt; Previous</button>
      <button disabled={orders.next === null} className={activeButton} onClick={goToNextPage}>Next &gt;</button>
      </div>
    </div>
  )
}

export default OrderList
