import axios, { AxiosError } from 'axios'

const AxiosConfig = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

AxiosConfig.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem('token')
    if (token !== null) {
      request.headers.Authorization = `token ${token}`
    }
    return request
  },
  async (error) => {
    return await Promise.reject(error)
  }
)

AxiosConfig.interceptors.response.use(
  (response) => {
    return response
  },
  async (error: AxiosError) => {
    const token = localStorage.getItem('token')
    if (
      token !== null &&
      error.response?.status !== 200 &&
      error.response?.config.method === 'get'
    ) {
      // localStorage.removeItem('token')
      // window.location.replace('/login')
    }
    return await Promise.reject(error)
  }
)

export default AxiosConfig
