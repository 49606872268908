/* eslint-disable @typescript-eslint/naming-convention */
import { useNavigate } from 'react-router-dom'
import {
  shippingUrl,
  shippingList,
  createShippingAddress,
  updateShippingAddress,
  deleteShippingAddress,
  detailShippingAddress,
} from './../Api/shipping.api'
import useSWR from 'swr'
import { IShippingAddress } from './../Api/interface'
import { useState, useCallback, useEffect, useMemo, ChangeEvent } from 'react'
import { toast } from 'react-hot-toast'
import { AxiosError } from 'axios'
import { handleError } from '../utilities/errorMessageHandler'

type IField =
  | 'first_name'
  | 'last_name'
  | 'address_line1'
  | 'address_line2'
  | 'phone_number1'
  | 'phone_number2'
  | 'additional_information'
  | 'region'
  | 'is_default_address'

interface IHandleShipping {
  data: IShippingAddress[]
  addShippingAddress: (form: IShippingAddress) => void
  editShippingAddress: (form: IShippingAddress) => void
  makeDefaultShippingAddress: (form: IShippingAddress) => void
  removeShippingAddress: (id: number) => void
  shippingForm: IShippingAddress
  updateShippingForm: (
    fieldName: IField,
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => void
  loading: boolean
  buttonLoading: boolean
}

export const useShipping = (param: number | undefined): IHandleShipping => {
  const navigate = useNavigate()
  const { data, isLoading } = useSWR(shippingUrl, shippingList)
  const [address, setAddress] = useState<IShippingAddress[]>([])
  const [uiLoading, setUiLoading] = useState<boolean>(false)
  const [itemLoading, setItemLoading] = useState<boolean>(false)

  const [shippingForm, setShippingForm] = useState<IShippingAddress>({
    additional_information: '',
    address_line1: '',
    address_line2: '',
    first_name: '',
    last_name: '',
    phone_number1: '',
    phone_number2: '',
    region: '',
    is_default_address: false,
  })

  //   initializing shipping address from default state
  const initShippingData = useCallback(() => {
    if (!isLoading) {
      const shippingData = data?.data.results as IShippingAddress[]
      setAddress(shippingData)
    }
  }, [isLoading])

  //   handle shipping form update
  const updateShippingForm = useCallback(
    (
      field: IField,
      e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
      setShippingForm((prev) => ({
        ...prev,
        [field]: e.target.value,
      }))
    },
    [shippingForm]
  )

  const updateEditForm = useCallback(async () => {
    if (param !== undefined) {
      const response = await detailShippingAddress(param)
      const {
        is_default,
        first_name,
        last_name,
        additional_information,
        address_line1,
        address_line2,
        phone_number1,
        phone_number2,
        region,
      } = response.data
      setShippingForm({
        region,
        additional_information,
        first_name,
        last_name,
        address_line1,
        address_line2,
        phone_number1,
        phone_number2,
        is_default_address: is_default as boolean,
      })
    }
  }, [shippingForm])

  //   refreshing shipping address if there is an update
  const refreshShipping = useCallback(async () => {
    setItemLoading(true)
    const shippingData = await shippingList(shippingUrl)
    const { results } = shippingData.data
    setAddress(results)
    setItemLoading(false)
  }, [address])

  //   handle adding shipping address
  const addShippingAddress = useCallback((form: IShippingAddress) => {
    setUiLoading(true)
    const shippingItem = createShippingAddress(form)
    void toast.promise(shippingItem, {
      loading: 'Please wait ....',
      success: () => {
        void refreshShipping()
        setUiLoading(false)
        navigate(-1)
        return 'Shipping Address Added Successfully'
      },
      error: (err: AxiosError) => {
        const { response } = err
        const message = handleError(response?.data)
        setUiLoading(false)
        return `${message ?? 'Something went wrong try again'}`
      },
    })
  }, [])

  //   handle editing shipping address
  const editShippingAddress = useCallback((form: IShippingAddress) => {
    setUiLoading(true)
    const shippingItem = updateShippingAddress(form, param as number)
    void toast.promise(shippingItem, {
      loading: 'Please wait ....',
      success: () => {
        void refreshShipping()
        setUiLoading(false)
        navigate(-1)
        return 'Shipping Address Updated Successfully'
      },
      error: (err: AxiosError) => {
        const { response } = err
        console.log(response)
        const message = handleError(response?.data)
        setUiLoading(false)
        return `${message ?? 'Something went wrong try again'}`
      },
    })
  }, [])

  //   handle deleting shipping address
  const removeShippingAddress = useCallback((id: number) => {
    setUiLoading(true)
    const shippingItem = deleteShippingAddress(id)
    void toast.promise(shippingItem, {
      loading: 'Please wait ....',
      success: () => {
        void refreshShipping()
        setUiLoading(false)
        return 'Shipping Address Updated Successfully'
      },
      error: (err: AxiosError) => {
        const { response } = err
        const message = handleError(response?.data)
        setUiLoading(false)
        return `${message ?? 'Something went wrong try again'}`
      },
    })
  }, [])

  const makeDefaultShippingAddress = useCallback(
    (payload: IShippingAddress) => {
      setUiLoading(true)
      const shippingItem = updateShippingAddress(
        { ...payload, is_default_address: true },
        payload.id as number
      )
      void toast.promise(shippingItem, {
        loading: 'Please wait ....',
        success: () => {
          void refreshShipping()
          setUiLoading(false)
          return 'Shipping Address Default he been set Successfully'
        },
        error: (err: AxiosError) => {
          const { response } = err
          console.log(response)
          const message = handleError(response?.data)
          setUiLoading(false)
          return `${message ?? 'Something went wrong try again'}`
        },
      })
    },
    [itemLoading]
  )

  useEffect(() => {
    initShippingData()
    void updateEditForm()
  }, [isLoading])

  return useMemo(
    () => ({
      data: address,
      addShippingAddress,
      editShippingAddress,
      loading: isLoading || itemLoading,
      removeShippingAddress,
      buttonLoading: uiLoading,
      updateShippingForm,
      makeDefaultShippingAddress,
      shippingForm,
    }),
    [isLoading, address, shippingForm]
  )
}
