import React, { useState } from 'react'
import { useManufacturer } from '../../hooks/handleManufacturers'
import './FeatureManufacturers.Layout.css'
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import Pagination from '../../component/Pagination'

const FeaturedManufacturerLayout: React.FC<{}> = () => {
  const { data } = useManufacturer()
  const [currentPage, setCurrentPage] = useState(1)

  const goToNextPage = (): void => {
    setCurrentPage((prev: number) => prev + 1)
  }

  const goToPreviousPage = (): void => {
    setCurrentPage((prev: number) => prev - 1)
  }

  const userAgent = window.navigator.userAgent

  const isAndroid = /Android/i.test(userAgent)
  const isIOS = /iPhone|iPad|iPod/i.test(userAgent)

  const ITEMS_PER_PAGE = isAndroid || isIOS ? 3 : 4
  const indexOfLastCard = currentPage * ITEMS_PER_PAGE
  const indexOfFirstCard = indexOfLastCard - ITEMS_PER_PAGE
  const currentCard = data?.slice(indexOfFirstCard, indexOfLastCard)

  const totalPages = Math.ceil(data?.length / ITEMS_PER_PAGE)


  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <p className="mb-0 text-xl font-semibold text-gray-700">
            Manufacturers Stories
          </p>
          <span
            style={{ color: '#aaa', fontSize: '14px', display: 'block' }}
            className="mb-7">
            See the latest from top manufacturers
          </span>
        </div>
        <div>
          <a
            href="/manufacturers"
            className="flex w-full min-w-[82px] items-center justify-center gap-1 text-center text-blue-600 hover:text-blue-800">
            <span className="text-sm">See More</span>{' '}
            <ArrowRightIcon className="h-[16px] w-[16px]" />
          </a>
        </div>
      </div>
      <div className='stories-brand'>
      <div className={`${isAndroid || isIOS ? 'visible' : 'invisible'} relative top-11 sm:top-16 pagination-container home-pagination`}>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
        />
      </div>
      <div className="scrollbar-hidden overflow-x-auto stories-brand scroll-smooth">
        <div className="flex justify-between gap-x-4 text-sm">
          {currentCard?.map((e, i) => (
            <a
              href={e.link}
              key={i}
              className="flex w-[100%] flex-col aspect-auto items-center rounded-md border px-4 py-2 font-semibold hover:text-[#3b82f7]">
              <img
                className="h-10 sm:h-20 object-contain aspect-w-3 aspect-h-1"
                src={e.image}
                alt="Brand Logo"
              />
            </a>
          ))}
        </div>
      </div>
      </div>
    </div>
  )
}

export default FeaturedManufacturerLayout
