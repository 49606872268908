import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { useComposition } from '../../hooks/handleComporition'
import FilterOptions from './FilterOptions'
import { IQuery } from './interface'

const CompositionFilter: React.FC<IQuery> = ({
  params,
  type,
  handleFilter,
  isChecked,
  uniqueFilteredCompositionsData,
  uniqueSearchedCompositionData,
  handleSubmit,
  handleTextChange,
  text,
}) => {
  const { filter, loading } = useComposition()

  return (
    <div>
      <h3 className="flow-root font-medium">Composition</h3>
      <form className="my-5"  onSubmit={handleSubmit}>
        <div className="relative w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
          </div>
          <input
            type="text"
            onChange={handleTextChange}
            value={text ?? ''}
            id="voice-search"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            placeholder="Search Composition"
            required
          />
        </div>
      </form>
      <FilterOptions
        data={filter}
        loading={loading}
        params={params}
        isChecked={isChecked}
        type={type}
        handleFilter={handleFilter}
        uniqueFilteredCompositionsData={uniqueFilteredCompositionsData}
        uniqueSearchedCompositionData={uniqueSearchedCompositionData}
      />
    </div>
  )
}

export default CompositionFilter
