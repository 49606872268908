import React, { useState } from 'react'
import Loader from '../../assets/loaders/Loader'
import { usePasswordSettings } from '../../hooks/handlePasswordReset'

const ConfirmPasswordPage: React.FC<{}> = () => {
  const { loading, passwordForm, updatePasswordForm, confirmPassword } =
    usePasswordSettings()

  const [isShowPassword, setIsShowPassword] = useState(false)
  const [showPasswordText, setShowPasswordText] = useState('password')

  const [isShowPassword1, setIsShowPassword1] = useState(false)
  const [showPasswordText1, setShowPasswordText1] = useState('password')
  
  const handlePasswordCheck = (): any => {
    if (!isShowPassword) {
      setIsShowPassword(true)
      setShowPasswordText('text')
    } else {
      setIsShowPassword(false)
      setShowPasswordText('password')
    }
  }

  const handlePasswordCheck1 = (): any => {
    if (!isShowPassword1) {
      setIsShowPassword1(true)
      setShowPasswordText1('text')
    } else {
      setIsShowPassword1(false)
      setShowPasswordText1('password')
    }
  }

  return (
    <div>
      <nav className="fixed left-0 top-0 z-20 w-full bg-white px-2 py-4">
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <a href="/" className="flex items-center">
            <img
              className="mx-auto h-9 w-auto"
              src="/logo.png"
              alt="Your Company"
            />
          </a>
        </div>
      </nav>
      <div className="flex min-h-screen items-center justify-center bg-gray-100 px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-lg space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-semibold tracking-tight text-gray-600">
              Reset Your Password
            </h2>
            <p className="mt-2 text-center text-sm capitalize text-gray-600">
              We can help you get your account back
            </p>
          </div>
          <div className="min-h-auto mx-auto rounded-lg border border-gray-200 bg-white p-6 shadow-xl dark:border-gray-700 dark:bg-gray-800 md:w-4/5">
            <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-6 relative">
                <label className="form-label">New Password</label>
                <input
                  type={showPasswordText}
                  value={passwordForm.password}
                  disabled={loading}
                  onChange={(e) => updatePasswordForm('password', e)}
                  className="form-select"
                  placeholder="Your New Password"
                />
                <div onClick={handlePasswordCheck} className='absolute right-4 top-9'>
                {isShowPassword ? (
                  <img
                    src="https://res.cloudinary.com/bizstak/image/upload/v1678128269/opened-eye_cfytw0.svg"
                    width={24}
                    height={24}
                    alt="eye opened"
                  />
                ) : (
                  <img
                    src="https://res.cloudinary.com/bizstak/image/upload/v1678128224/closed-eye_giryen.svg"
                    width={24}
                    height={24}
                    alt="eye closed"
                  />
                )}
                </div>
              </div>
              <div className="sm:col-span-6 relative">
                <label className="form-label">Confirm Password</label>
                <input
                  type={showPasswordText1}
                  value={passwordForm.c_password}
                  disabled={loading}
                  onChange={(e) => updatePasswordForm('c_password', e)}
                  className="form-select"
                  placeholder="Confirm Password"
                />
                <div onClick={handlePasswordCheck1} className='absolute right-4 top-9'>
                {isShowPassword1 ? (
                  <img
                    src="https://res.cloudinary.com/bizstak/image/upload/v1678128269/opened-eye_cfytw0.svg"
                    width={24}
                    height={24}
                    alt="eye opened"
                  />
                ) : (
                  <img
                    src="https://res.cloudinary.com/bizstak/image/upload/v1678128224/closed-eye_giryen.svg"
                    width={24}
                    height={24}
                    alt="eye closed"
                  />
                )}
                </div>
              </div>
              <div className="sm:col-span-6">
                <button
                  type="submit"
                  disabled={
                    passwordForm.password === '' ||
                    loading ||
                    passwordForm.c_password === '' ||
                    passwordForm.password !== passwordForm.c_password
                  }
                  onClick={() => confirmPassword(passwordForm)}
                  className="group relative flex w-full items-center justify-center rounded-md bg-[#3b82f7] px-3 py-2 text-sm font-semibold text-white hover:bg-[#1b6ef8] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3b82f7] disabled:opacity-60">
                  {loading ? <Loader /> : null}
                  {loading ? 'Please wait...' : 'Reset Password'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmPasswordPage
