import { userProfile } from './../Api/user.api'
import useSWR from 'swr'
import { IUser } from '../Api/interface'

interface IProfile {
  data: IUser | undefined
  loading: boolean
  isAuthenticated: boolean
  error: any
}

export const useUser = (): IProfile => {
  const {
    data: userData,
    error,
    isLoading,
  } = useSWR('/account/profile', userProfile)

  const hasError = (): boolean => {
    const token = localStorage.getItem('token')
    if (token !== null) {
      return true
    }

    return false
  }

  return {
    data: userData,
    loading: isLoading,
    isAuthenticated: hasError(),
    error,
  }
}
