import React, { ChangeEvent } from 'react'
import { IQuery } from './interface'

const PriceFilter: React.FC<IQuery> = ({
  handleFilter,
  params,
  isChecked,
  type,
}) => {
  const priceFilter = (
    e: ChangeEvent<HTMLInputElement>,
    priceType: 'min' | 'max'
  ): void => {
    // const params : IFilterOptions = {
    //         checked: false,
    //         label: '',
    //         params: '',
    //         value: 0
    // }
    if (priceType === 'min') console.log(e.target.value, priceType)
  }

  return (
    <div>
      <h3 className="flow-root font-medium">Price</h3>
      <form className="my-5 flex items-center space-x-3">
        <div className="relative w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            &#8358;
          </div>
          <input
            type="text"
            id="voice-search"
            onChange={(e) => priceFilter(e, 'min')}
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            placeholder="Min"
            required
          />
        </div>
        <p>To</p>
        <div className="relative w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            &#8358;
          </div>
          <input
            type="text"
            id="voice-search"
            onChange={(e) => priceFilter(e, 'max')}
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            placeholder="Max"
            required
          />
        </div>
      </form>
    </div>
  )
}

export default PriceFilter
