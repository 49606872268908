/* eslint-disable @typescript-eslint/naming-convention */
import React, { ChangeEvent, useCallback, useState } from 'react'
import { Formatter } from '../../utilities/utils'
import { ICartComponent } from './interface'
import { userBusiness } from '../../Api/business.api'
import useSWR from 'swr'
import { useLocation } from 'react-router-dom'

const CartItem: React.FC<ICartComponent> = ({
  deleteCartItem,
  product,
  quantity,
  unit_price,
  id,
  updateCartItem,
  cartItems,
}) => {
  const [values, setValue] = useState<string>(
    quantity !== 0 ? quantity.toString() : '1'
  )
  const { pathname } = useLocation()
  const getAmount = useCallback(() => {
    if (values === '' || parseInt(values) > product.quantity) {
      return Formatter(0)
    } else {
      return Formatter((product.price as number) * parseInt(values))
    }
  }, [values])

  const { data: businessDetail } = useSWR('/business/profile', userBusiness)

  const isVerified = businessDetail?.is_verified
  const [isDeleted, setIsDeleted] = useState(false)

  const handleDelete = (): void => {
    deleteCartItem(product.id)
    setIsDeleted(true)
  }

  return (
    <li key={product.id} className="flex border-b-2 py-6">
      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
        <a href={`/product/${product.slug}`}>
          <img
            src={product.image}
            alt={product.name}
            className="h-full w-full object-cover object-center"
          />
        </a>
      </div>

      <div className="ml-4 flex flex-1 flex-col">
        <div>
          <div className="flex justify-between gap-3 text-base font-medium text-gray-900">
            <h3>
              <a href={`/product/${product.slug}`}>{product.name}</a>
            </h3>
            {isVerified === true ? (
              <p className="ml-4">&#8358;{getAmount()}</p>
            ) : (
              '###'
            )}
          </div>
          <p className="mt-1 text-sm text-gray-500">{product.SKU}</p>
        </div>
        <div className="flex flex-1 items-end justify-between text-sm">
          <div>
            <div className="flex space-x-2">
              <input
                type="number"
                value={values}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setValue(e.target.value)
                }
                className="sm:text-md block w-16 rounded-lg border border-gray-300 bg-gray-50 p-1 text-center text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              />
              {parseInt(values) > product.quantity ? (
                <p className="mt-1 text-sm text-[#3b82f7] hover:text-[#3b82f7]">
                  {pathname === '/checkout'
                    ? `Only ${quantity} is available for express shipping`
                    : 'Available on Request'}
                </p>
              ) : // quantity
                values !== quantity.toString() ? (
                <button
                  type="button"
                  disabled={values === ''}
                  onClick={() =>
                    updateCartItem(
                      {
                        product_id: product.id,
                        quantity: parseInt(values),
                      },
                      product.id
                    )
                  }
                  className="font-medium text-[#3b82f7] hover:text-[#3b82f7]">
                  Update
                </button>
                ) : null}
            </div>
          </div>

          <div className="flex">
            <button
              type="button"
              onClick={handleDelete}
              className={`${isDeleted ? 'pointer-events-none opacity-50' : ''} font-medium text-[#3b82f7] hover:text-[#3b82f7]`}>
              Remove
            </button>
          </div>
        </div>
      </div>
    </li>
  )
}

export default CartItem
