import React from 'react'
import ProductList from '../../component/Products/ProductList'
import { IHandleProduct } from '../../hooks/handleProducts';

const PopularProducts: React.FC<{productHandler: IHandleProduct}> = ({productHandler}) => {
  const {
    data,
    loading,
    addToCart,
    updateCartItem,
    deleteCartItem,
    cartLoading,
  } = productHandler;

  return (
    <div>
      <p className="mb-0 text-xl font-semibold text-gray-700">
        See Popular Products
      </p>
      <span style={{ color: '#aaa', fontSize: '14px', display: 'block' }} className='mb-7'>Scroll up to see more products</span>
      <div className="mx-auto max-w-2xl lg:max-w-7xl">
        <ProductList
          loading={loading}
          data={data}
          grid={4}
          addToCart={addToCart}
          updateCartItem={updateCartItem}
          deleteCartItem={deleteCartItem}
          cartLoading={cartLoading}
        />
      </div>
    </div>
  )
}

export default PopularProducts
