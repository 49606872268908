/* eslint-disable linebreak-style */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import { useLocation } from 'react-router-dom'

const Pagination = ({
  ITEMS_PER_PAGE,
  handlePageClick,
  previousArrow: PreviousArrow,
  nextArrow: NextArrow,
  TOTAL_ITEMS,
}: any): any => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const currentPage = queryParams.get('page') ?? 1

  const pageCount = Math.ceil(TOTAL_ITEMS / ITEMS_PER_PAGE)

  const calculatePageRange = (): any => {
    const maxButtons = 5

    let rangeStart = Math.max(1, +currentPage - Math.floor(maxButtons / 2))
    let rangeEnd = Math.min(rangeStart + maxButtons - 1, pageCount)

    if (rangeEnd - rangeStart + 1 < maxButtons) {
      rangeStart = Math.max(1, pageCount - maxButtons + 1)
      rangeEnd = pageCount
    }

    return { rangeStart, rangeEnd }
  }

  const { rangeStart, rangeEnd } = calculatePageRange()
  const buttonClasses =
    'flex h-[35px] w-[35px] items-center justify-center rounded bg-blue-500 font-bold text-white hover:bg-blue-700'

  return (
    <>
      {TOTAL_ITEMS !== undefined && (
        <div>
          <div className="my-4 flex items-center justify-center gap-6 sm:hidden">
            <button
              type="button"
              disabled={+currentPage === 1}
              key="prev"
              onClick={() => handlePageClick(+currentPage - 1)}
              className={`${buttonClasses}`}>
              {(PreviousArrow && <PreviousArrow className="h-5 w-5" />) || (
                <span>&lt;</span>
              )}
            </button>
            <span className="text-[#6b7280]">{`${currentPage} / ${pageCount}`}</span>
            <button
              type="button"
              disabled={+currentPage === pageCount}
              key="next"
              onClick={() => handlePageClick(+currentPage + 1)}
              className={`${buttonClasses}`}>
              {(NextArrow && <NextArrow className="h-5 w-5" />) || (
                <span>&gt;</span>
              )}
            </button>
          </div>
          <div className="my-4 hidden flex-wrap justify-center gap-3 space-x-1 sm:flex sm:gap-1">
            {rangeStart > 1 && (
              <>
                {+currentPage > 1 && (
                  <button
                    type="button"
                    key="prev"
                    onClick={() => handlePageClick(+currentPage - 1)}
                    className={`${buttonClasses}`}>
                    {(PreviousArrow && (
                      <PreviousArrow className="h-5 w-5" />
                    )) || <span>&lt;</span>}
                  </button>
                )}
                <button
                  type="button"
                  key={1}
                  onClick={() => handlePageClick(1)}
                  className={`${buttonClasses} ${
                    +currentPage === 1 ? 'bg-blue-700' : ''
                  }`}>
                  01
                </button>
                {+currentPage >= 5 && (
                  <span className={buttonClasses}>...</span>
                )}
              </>
            )}

            {Array.from(
              { length: rangeEnd - rangeStart + 1 },
              (_, index) => +rangeStart + index
            ).map((page) => (
              <button
                type="button"
                key={page}
                onClick={() => handlePageClick(page)}
                className={`${buttonClasses} ${
                  page === +currentPage ? 'bg-blue-700' : ''
                }`}>
                {page < 10 ? `0${page}` : page}
              </button>
            ))}
            {rangeEnd < pageCount && (
              <>
                {
                  +currentPage <= pageCount - 4 && <span className={buttonClasses}>...</span>
                }
                <button
                  type="button"
                  key={pageCount}
                  onClick={() => handlePageClick(pageCount)}
                  className={`${buttonClasses} ${
                    pageCount === +currentPage ? 'bg-blue-700' : ''
                  }`}>
                  {pageCount < 10 ? `0${pageCount}` : pageCount}
                </button>
                <button
                  type="button"
                  key="next"
                  onClick={() => handlePageClick(+currentPage + 1)}
                  className={`${buttonClasses}`}>
                  {(NextArrow && <NextArrow className="h-5 w-5" />) || (
                    <span>&gt;</span>
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Pagination
