import { InformationCircleIcon, /* ArrowRightIcon */ } from '@heroicons/react/20/solid'
import React from 'react'
import { IDropDownLink } from './interface'

const ManufacturerDropdownLink: React.FC<IDropDownLink> = ({
  loading,
  data,
}) => {
  if (loading) {
    return (
      <div className="row-start-1 grid grid-cols-5 gap-x-2 gap-y-4 text-sm">
        {Array(20)
          .fill(null)
          .map((e, i) => (
            <div
              key={i}
              className="max-w-sm animate-pulse rounded-xl border border-gray-200 p-1 md:p-1">
              <div className="flex items-center space-x-3">
                <InformationCircleIcon className="h-14 w-14 text-gray-200" />
                <div>
                  <div className="mb-2 h-2.5 w-40 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                  <div className="h-1.5 w-32 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                </div>
              </div>
            </div>
          ))}
      </div>
    )
  }

  return (
    <>
      <div className="row-start-1 mx-auto grid grid-cols-5 gap-x-2 gap-y-4 text-[0.8rem]">
        {data?.map((e: any, i) => (
          <a
            href={`/manufacturers/${e.id as string}/${e.slug as string}`}
            key={i}
            className="flex flex-col items-center pt-2 truncate rounded-xl border border-gray-200 font-semibold manufacturer-card">
            <img
              className="h-14 rounded object-contain aspect-w-3 aspect-h-2"
              src={e.logo ?? `https://placehold.co/192/f0f0f0/png?text=${(
                e.name as string
              )?.replaceAll(' ', '+')}`}
              alt="Brand Logo"
            />
            <span className={`manufacturer-name-${i} w-full flex justify-center py-1.5`}>{e.name}</span>
          </a>
        ))}
      </div>
    </>
  )
}

export default ManufacturerDropdownLink
