export enum AppStep {
  PERSONAL_INFO = 0,
  ACCOUNT_INFO = 1,
  CONFIRMATION = 2,
}

export interface IRegistration {
  step?: AppStep
}

interface RegistrationValidator {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  phone: string;
  isValid: boolean;
}

export type { RegistrationValidator }