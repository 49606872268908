import { AxiosError, AxiosResponse } from 'axios'
import useSWR from 'swr'
import { useState, useCallback, useMemo, useEffect, ChangeEvent } from 'react'
import { ICartBody, ICartRequest } from './../Api/interface'
import {
  cartUrl,
  cartList,
  addToCartList,
  DeleteCartItem,
  updateCart,
} from '../Api/cart.api'
import { toast } from 'react-hot-toast'

const handleError = (data: any): string => {
  const res = Object.values(data)
  const message = res.join(',')
  return message
}

export type NextFunction = (...arg: any | null) => void

export interface ICartHandler {
  cartItems: ICartBody[]
  loading: boolean
  totalCost: number
  addToCart: (data: ICartRequest, next?: NextFunction) => void
  searchCart: (
    query: ChangeEvent<HTMLInputElement>,
    next?: NextFunction
  ) => void
  updateCartItem: (data: ICartRequest, id: number, next?: NextFunction) => void
  deleteCartItem: (id: number, next?: NextFunction) => void
}

export const useCart = (): ICartHandler => {
  const { data, isLoading, mutate } = useSWR(cartUrl, cartList)
  const [cartItems, setItems] = useState<ICartBody[] | any>([])
  const [uiLoading, setUiLoading] = useState<boolean>(true)
  const [totalCost, setTotalCost] = useState<number>(0)

  const token = localStorage.getItem('token')
 
  const refreshCart = useCallback(async () => {
    void mutate()
    const response = await cartList(cartUrl)
    const cartData = response?.results as ICartBody[]
    setTotalCost(response?.total_amount as number)
    localStorage.setItem('cart_total', response?.count.toString())
    localStorage.setItem('cart_data', JSON.stringify(cartData as any))
    setItems(cartData)
    setUiLoading(false)
  }, [])

  const handleAddToCart = (item: ICartRequest, next?: NextFunction): void => {
    setUiLoading(true);
    const addResp = addToCartList(item);
    
    // Add the item to cartItems state immediately
    // setItems((prevItems: any) => [...prevItems, item]);
  
    void toast.promise(addResp, {
      loading: 'Please wait ....',
      success: (resp: AxiosResponse) => {
        if (next !== undefined) {
          next(item, { in_cart: true });
        }
        localStorage.setItem('cart_data', JSON.stringify([...cartItems, item]));
      
        void refreshCart();
        return 'Item Added to Cart Successfully';
      },
      error: (err: AxiosError) => {
        const { response } = err;
        const message = handleError(response?.data);
        return `${message ?? 'Something went wrong try again'}`;
      },
    });
  };

  const addToCart = useCallback(
    (item: ICartRequest, next?: NextFunction) => {
      try {
        if (token === null) {
          toast.error(
            'Please Login or Sign up before you can perform this action'
          )
        } else {
          handleAddToCart(item, next)
          // useEffect(() => {
          //   setItems((prevItems: any) => [...prevItems, item]);
          // }, [item]);
        }
      } catch (error) {}
    },
    // isLoading, uiLoading, cartItems
    [token]
  )

  const searchCart = useCallback(
    async (query: ChangeEvent<HTMLInputElement>) => {
      const response = await cartList(
        `${cartUrl}?product__name__icontains=${query.target.value}`
      )
      const cartData = response?.results as ICartBody[]
      setItems(cartData)
    },
    []
  )

  const updateCartItem = useCallback(
    (item: ICartRequest, id: number, next?: NextFunction) => {
      const addResp = updateCart(item, id)
      void toast.promise(addResp, {
        loading: 'Please wait ....',
        success: (resp) => {
          void refreshCart()
          if (next !== undefined) {
            next(item, { in_cart: true })
          }
          console.log(item)
          return 'Item Updated Successfully'
        },
        error: (err: AxiosError) => {
          const { response } = err
          const message = handleError(response?.data)
          return `${message ?? 'Something went wrong try again'}`
        },
      })
    },
    [cartItems]
  )

  const deleteCartItem = useCallback(
    (id: number, next?: NextFunction) => {
      const addResp = DeleteCartItem(id)
      void toast.promise(addResp, {
        loading: 'Please wait ....',
        success: (resp) => {
          if (next !== undefined) {
            next({ product_id: id, quantity: 0 }, { in_cart: false })
          }
          void refreshCart()
          return 'Item Deleted Successfully'
        },
        error: (err: AxiosError) => {
          const { response } = err
          const message = handleError(response?.data)
          return `${message ?? 'Something went wrong try again'}`
        },
      })
    },
    [cartItems]
  )

  useEffect(() => {
    if (!isLoading && token !== null) {
      const cartData = data?.results as ICartBody[]
      localStorage.setItem('cart_total', (data?.count as number)?.toString())
      localStorage.setItem('cart_data', JSON.stringify(data as any))
      setTotalCost(data?.total_amount as number)
      setItems(cartData)
      setUiLoading(false)
    } else {
      localStorage.removeItem('cart_total')
      localStorage.removeItem('cart_data')
      setItems([])
    }
  }, [isLoading, token, data])

  return useMemo(
    () => ({
      cartItems,
      loading: uiLoading,
      totalCost,
      addToCart,
      searchCart,
      updateCartItem,
      deleteCartItem,
    }),
    [isLoading, uiLoading, cartItems, addToCart, searchCart, refreshCart]
  )
}
