import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { useManufacturer } from '../../hooks/handleManufacturers'
import FilterOptions from './FilterOptions'
import { IQuery } from './interface'

const ManufacturerFilter: React.FC<IQuery> = ({
  params,
  type,
  handleFilter,
  isChecked,
  filteredData,
  uniqueFilteredManufacturerData,
  uniqueSearchedManufacturerData,
  handleSubmit,
  handleTextChange,
  text,
}) => {
  const { loading, filter } = useManufacturer()

  return (
    <div>
      <h3 className="flow-root font-medium">Manufacturer</h3>
      <form className="my-5"  onSubmit={handleSubmit}>
        <div className="relative w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
          </div>
            <input
              type="text"
              id="voice-search"
              value={text ?? ''}
              onChange={handleTextChange}
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              placeholder="Search Manufacturer"
              required
            />
        </div>
      </form>
      <FilterOptions
        data={filter}
        loading={loading}
        params={params}
        type={type}
        isChecked={isChecked}
        handleFilter={handleFilter}
        filteredData={filteredData}
        uniqueFilteredManufacturerData={uniqueFilteredManufacturerData}
        uniqueSearchedManufacturerData={uniqueSearchedManufacturerData}
      />
    </div>
  )
}

export default ManufacturerFilter
