import {
  ArrowDownTrayIcon,
  PaperClipIcon,
  PencilSquareIcon,
} from '@heroicons/react/20/solid'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'
import React, { useCallback, useState } from 'react'
import Layout from '../../layout'
import Sidebar from '../../layout/Sidebar/Sidebar'
import LicenseUploadModal from '../../component/Modals/LicenseUpload.modal'
import { ILicenseUpload } from '../../component/Modals/interface'
import { useHandleBusiness } from '../../hooks/handleBusiness'
import { useCart } from '../../hooks/handleCart'

type IModal = 'premise' | 'practice'

const DocumentPage: React.FC<{}> = () => {
  const [modalInfo, setModalTitle] = useState<ILicenseUpload>({
    field: '',
    title: '',
    open: false,
    setOpen: () => null,
  })

  const {
    cartItems,
    loading: cartLoading,
    deleteCartItem,
    totalCost,
    updateCartItem,
  } = useCart()

  const { data, loading, isBusiness } = useHandleBusiness()

  const handleModal = useCallback(
    (modal: IModal) => {
      if (modal === 'practice') {
        setModalTitle({
          ...modalInfo,
          field: 'practice_license',
          title: 'Upload Your Practice License',
          open: true,
        })
      }

      if (modal === 'premise') {
        setModalTitle({
          ...modalInfo,
          field: 'premise_license',
          title: 'Upload Your Premise License',
          open: true,
        })
      }
    },
    [modalInfo]
  )

  const setOpen = useCallback(
    (option: boolean) => {
      setModalTitle({ ...modalInfo, open: option })
    },
    [modalInfo, data]
  )

  return (
    <Layout
      cartItems={cartItems}
      data={cartItems}
      totalCost={totalCost}
      loading={cartLoading}
      updateCartItem={updateCartItem as any}
      deleteCartItem={deleteCartItem}>
      <Sidebar title="Compliance & Documents" pathname="Compliance & Documents">
        <LicenseUploadModal {...modalInfo} setOpen={setOpen} />

        <div className="bg-gray-50 p-6">
          <h2 className="sm:text-base">Compliance & Documents</h2>
        </div>
        {!loading ? (
          <div>
            {data?.is_verified ?? false ? (
              <div className="relative isolate flex items-center gap-x-6 bg-green-100 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                <div className="flex w-full justify-center gap-x-4 gap-y-2">
                  <CheckCircleIcon className="h-6 text-green-800" />
                  <p className="items-center text-sm leading-6 text-green-800">
                    Your Documents Has been approved
                  </p>
                </div>
              </div>
            ) : (
              <div className="relative isolate flex items-center gap-x-6 bg-red-100 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                <div className="flex w-full justify-center gap-x-4 gap-y-2">
                  <ExclamationTriangleIcon className="h-6 text-red-800" />
                  <p className="items-center text-sm leading-6 text-red-800">
                    Documents requires approval from our verification team
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : null}
        <div className="p-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-6">
            <div className="md:col-span-4 md:col-start-2">
              <div>
                <h2 id="accordion-collapse-heading-1">
                  <button
                    type="button"
                    className="flex w-full items-center space-x-3 rounded-t-xl bg-gray-100 p-5 text-left font-medium text-gray-800">
                    <div className="relative inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-gray-300">
                      <PaperClipIcon className="h-6 w-6" />
                    </div>
                    <div>
                      <p>Compliant Required Documents</p>
                      <p className="mt-1 text-xs font-light">
                        Upload required licenses
                      </p>
                    </div>
                  </button>
                </h2>
                <div className="block">
                  <div className="rounded-b-lg bg-slate-50 p-5 dark:border-gray-700">
                    {isBusiness && !loading ? (
                      <div className="flex w-[100%] flex-row items-center justify-between  overflow-hidden">
                        <div>
                          <p className="mb-1 font-medium text-gray-800 dark:text-gray-400">
                            Premise License
                          </p>
                          {data?.premise_license === null ? (
                            <p className="text-xs text-gray-500">
                              Not Submitted
                            </p>
                          ) : (
                            <div className="w-[60%] overflow-hidden">
                              <a
                                href={data?.premise_license}
                                target="_blank"
                                className="mb-2 mr-2 flex items-center truncate rounded-lg border border-[#3b82f7] bg-white px-3 py-2 text-xs font-medium text-[#3b82f7] hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none "
                                rel="noreferrer">
                                <ArrowDownTrayIcon className="mr-2 h-3" />
                                Your Uploaded Premise License
                              </a>
                              <p className="text-xs text-green-500">
                                Premise License Submitted
                              </p>
                            </div>
                          )}
                        </div>
                        {data?.premise_license === null ? (
                          <button
                            type="button"
                            onClick={() => handleModal('premise')}
                            className="mb-2 mr-2 rounded-lg border border-gray-200 bg-white px-3 py-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none ">
                            Start
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => handleModal('premise')}
                            disabled={data?.is_verified}
                            className="inline-flex items-center rounded-full p-2 text-center text-sm font-medium text-[#3b82f7] hover:bg-[#70a4f837] disabled:text-gray-300 disabled:hover:bg-transparent">
                            <PencilSquareIcon className="h-6 w-6" />
                            <span className="sr-only">Icon description</span>
                          </button>
                        )}
                      </div>
                    ) : null}
                    {!loading ? (
                      <div
                        className={`flex w-[100%] flex-row items-center justify-between overflow-hidden ${
                          isBusiness && !loading ? 'pt-8' : ''
                        }`}>
                        <div>
                          <p className="mb-1 font-medium text-gray-800 dark:text-gray-400">
                            Practice License
                          </p>
                          {data?.practice_license === null ? (
                            <p className="text-xs text-gray-500">
                              Not Submitted
                            </p>
                          ) : (
                            <div className="w-full">
                              <a
                                href={data?.practice_license}
                                target="_blank"
                                className="my-2 mb-2 flex w-[100%] items-center truncate rounded-lg border border-[#3b82f7] bg-white px-3 py-2 text-xs font-medium text-[#3b82f7] hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none md:w-full "
                                rel="noreferrer">
                                <ArrowDownTrayIcon className="mr-2 h-3" />
                                Your Uploaded Practice License
                              </a>
                              <p className="text-xs text-green-500">
                                Practice License Submitted
                              </p>
                            </div>
                          )}
                        </div>
                        {data?.practice_license === null ? (
                          <button
                            type="button"
                            onClick={() => handleModal('practice')}
                            className="mb-2 mr-2 rounded-lg border border-gray-200 bg-white px-3 py-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none ">
                            Start
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => handleModal('practice')}
                            disabled={data?.is_verified}
                            className="inline-flex items-center rounded-full p-2 text-center text-sm font-medium text-[#3b82f7] hover:bg-[#70a4f837] disabled:text-gray-300 disabled:hover:bg-transparent">
                            <PencilSquareIcon className="h-6 w-6" />
                            <span className="sr-only">Icon description</span>
                          </button>
                        )}
                      </div>
                    ) : (
                      <p>please wait ....</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </Layout>
  )
}

export default DocumentPage
