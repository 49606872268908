import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import {
  LoginUser,
  IAuthenticate,
  RegisterUser,
} from './../Api/authenticate.api'
import { useMemo, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { IUser } from '../Api/interface'
import { userProfile } from '../Api/user.api'

interface IHandleError {
  hasError: boolean
  message: string
}

interface IAuthentication {
  signin: (payload: IAuthenticate) => void
  signup: (payload: IAuthenticate) => void
  loading: boolean
  error: IHandleError
}

interface IAPIResponse {
  status: string
  token: string
  id: number
}

export const useAuthentication = (): IAuthentication => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<IHandleError>({
    hasError: false,
    message: '',
  })

  const navigate = useNavigate()

  const handleAuthParams = async (): Promise<void> => {
    const user: IUser = await userProfile('/account/profile')
    const payload = { ...user, isAuthenticated: true }
    localStorage.setItem('user', payload.first_name)
    // data = payload
  }

  const handleAuthentication = useCallback((token: string) => {
    if (token === '') {
      return
    }
    localStorage.setItem('token', token)
    void handleAuthParams()
    navigate('/dashboard')
  }, [])

  const handleError = useCallback((data: any): string => {
    const res = Object.values(data)
    const message = res.join(',')
    return message
  }, [])

  const signin = useCallback(
    (payload: IAuthenticate) => {
      setLoading(true)
      const resp = LoginUser({ payload })
      void toast.promise(resp, {
        loading: 'Authenticating Please wait...',
        success: (props) => {
          const { data } = props
          setError({ hasError: false, message: '' })
          handleAuthentication((data as IAPIResponse).token)
          setLoading(false)
          return 'Authentication Successful'
        },
        error: (err: AxiosError) => {
          const { response } = err
          const messages: string | undefined = (response?.data as any)
            .non_field_errors
          setError({
            hasError: true,
            message: `Couldn’t find a DrugStoc account associated with this credentials. Try resetting your password or create an account`,
          })
          setLoading(false)
          return `${messages ?? 'Something went wrong try again'}`
        },
      })
    },
    [loading, error]
  )

  const signup = useCallback(
    (payload: IAuthenticate) => {
      setLoading(true)
      const resp = RegisterUser({ payload })
      void toast.promise(resp, {
        loading: 'Please wait....',
        success: (props) => {
          const { data } = props
          setError({ hasError: false, message: '' })
          handleAuthentication((data as IAPIResponse).token)
          setLoading(false)
          return 'successful'
        },
        error: (err: AxiosError) => {
          const { response } = err
          const message = handleError(response?.data)
          setError({
            hasError: true,
            message: `Ops!! ${message}`,
          })
          setLoading(false)
          return `${message ?? 'Something went wrong try again'}`
        },
      })
    },
    [loading, error]
  )

  return useMemo(
    () => ({
      signin,
      signup,
      loading,
      error,
    }),
    [loading, error]
  )
}
