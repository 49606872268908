import React, { useState, useEffect } from 'react'
import { XMarkIcon } from '@heroicons/react/20/solid'

const Banner: React.FC<{}> = () => {
  const [isBannerVisible, setBannerVisible] = useState(
    localStorage.getItem('isBannerVisible') !== 'true'
  )

  const handleCloseBanner = (): void => {
    localStorage.setItem('bannerClosedTimestamp', String(Date.now()))
    setBannerVisible(false)
  }

  useEffect(() => {
    const bannerClosedTimestamp = localStorage.getItem('bannerClosedTimestamp')
    if (bannerClosedTimestamp != null) {
      const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000
      const closedTime = parseInt(bannerClosedTimestamp, 10)
      const currentTime = Date.now()
      const shouldShowBanner =
        currentTime - closedTime > thirtyDaysInMilliseconds

      setBannerVisible(shouldShowBanner)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('isBannerVisible', String(isBannerVisible))
  }, [isBannerVisible])

  // const userAgent = window.navigator.userAgent

  // const isWindows = navigator.platform.includes('Win') || /Win/i.test(userAgent)
  // const isMacOS = navigator.platform.includes('Mac') || /Mac/i.test(userAgent);
  // const isAndroid = /Android/i.test(userAgent)
  // const isIOS = /iPhone|iPad|iPod/i.test(userAgent)

  return (
    <div>
      {isBannerVisible && (
        <div className="relative isolate mt-1 flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
            <p className="text-sm leading-6 text-gray-900">
              The mobile app allows you to reach more customers
            </p>
            <span
              className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
              <a
                href={
                  'https://linktr.ee/newdrugstocapp/'
                  // isAndroid
                  //   ? 'https://play.google.com/store/apps/details?id=com.drugstoc.app'
                  //   : isIOS
                  //     ? 'https://apps.apple.com/ng/app/drugstoc-pharmacy-partner/id1467205425'
                  //     : isWindows
                  //       ? 'https://play.google.com/store/apps/details?id=com.drugstoc.app'
                  //       : 'https://apps.apple.com/ng/app/drugstoc-pharmacy-partner/id1467205425'
                }
                target="_blank"
                rel="noreferrer">
                Download now
              </a>{' '}
              <span aria-hidden="true"></span>
            </span>
          </div>
          <div className="flex flex-1 justify-end">
            <button
              type="button"
              className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
              onClick={handleCloseBanner}>
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Banner
