/* eslint-disable linebreak-style */
import { validateEmail } from "../../utilities/validator";
import { RegistrationValidator } from "./Interface";

export const validateForm = (form: any): RegistrationValidator => {
  const errors: any = {};

  const MIN_PASSWORD_LENGTH = 8;
  const MAX_PHONE_LENGTH = 11;

  const isValidEmail = validateEmail(form?.email);
  const isValidFirstName = /^[A-Za-z]+$/.test(form?.first_name);
  const isValidLastName = /^[A-Za-z]+$/.test(form?.last_name);
  const isValidPhone = /^\d+$/.test(form?.phone) && form?.phone.length <= MAX_PHONE_LENGTH;
  const isValidPassword = form?.password.length >= MIN_PASSWORD_LENGTH;

  if (!isValidEmail) {
    errors.email = 'Invalid Email Address';
  }

  if (!isValidFirstName) {
    errors.first_name = 'First name is required and should contain only letters';
  }

  if (!isValidLastName) {
    errors.last_name = 'Last name is required and should contain only letters';
  }

  if (!isValidPhone) {
    errors.phone = 'Phone number must be numeric and not exceed 11 digits';
  }

  if (!isValidPassword) {
    errors.password = 'Invalid Password - minimum of 8 characters';
  }

  return errors;
};

export default validateForm;
