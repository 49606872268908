/* eslint-disable @typescript-eslint/no-shadow */
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon, UserCircleIcon } from "@heroicons/react/20/solid";
import Logo from "../../assets/img/logo.png";
import SearchModal from "../../component/Modals/Search.modal";
import CartSideMenus from "../../component/SidebarMenus/CartSideMenus";
import CategoryDropdown from "../../component/Dropdowns/Categories.dropdown";
import ManufacturerDropDown from "../../component/Dropdowns/Manufacturers.dropdown";
import { INavbar } from "./interface";
import { useCategory } from "../../hooks/handleCategoeries";
import { useManufacturer } from "../../hooks/handleManufacturers";
import dropdownIcon from "../../assets/img/dropdown.png";
import { useSettings } from "../../hooks/useSettings";
import { useNavigate } from "react-router-dom";
// https://img.icons8.com/emoji/48/shopping-cart-emoji.png

const Navbar: FC<INavbar> = ({
  data,
  deleteCartItem,
  loading,
  totalCost,
  updateCartItem,
  cartItems,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [cart, setCart] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/restrict-plus-operands
  const { logout } = useSettings();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownDivRef = useRef<HTMLDivElement | null>(null);
  const dropdownImgRef = useRef<HTMLImageElement | null>(null);

  const handleClickOutside = (event: any): any => {
    if (
      dropdownDivRef.current != null &&
      !dropdownDivRef.current.contains(event.target) &&
      dropdownImgRef.current != null &&
      !dropdownImgRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { data: categoryData, loading: categoryLoading } = useCategory();
  const { data: manufacturerData, loading: manufacturerLoading } =
    useManufacturer();

  const [cartTotal, setCartTotal] = useState(
    localStorage.getItem("cart_total"),
  );

  useEffect(() => {
    setCartTotal(localStorage.getItem("cart_total"));
  }, [data]);

  const navigate = useNavigate();

  const handleClick = (): void => {
    navigate("/dashboard");
  };

  const dropdownModal = (
    <>
      <div className="absolute left-[46px] top-[85px] z-10 h-[80px] w-36 overflow-hidden rounded-md border border-gray-300 bg-white shadow-lg">
        <ul className="flex h-[68px] flex-col justify-between">
          <li
            className="cursor-pointer py-2 hover:bg-gray-300"
            onClick={handleClick}
          >
            <span className="px-2 py-1">Dashboard</span>
          </li>
          <li className="cursor-pointer py-2 hover:bg-gray-300">
            <span className="px-2 py-1" onClick={logout}>
              Log out
            </span>
          </li>
        </ul>
      </div>
    </>
  );

  const token = localStorage.getItem("token") as string;

  return (
    <div className="bg-white">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  {localStorage.getItem("token") === null
                    ? (
                      <div className="mt-4 flex flex-col space-y-4">
                        <a
                          href="/login"
                          className="text-base font-medium text-gray-700 hover:text-gray-800"
                        >
                          Log in
                        </a>
                        <a
                          href="/register"
                          className="text-base font-medium text-gray-700 hover:text-gray-800"
                        >
                          Create account
                        </a>
                      </div>
                      )
                    : (
                      <div
                        ref={dropdownDivRef}
                        className="mt-4 flex cursor-pointer flex-col space-y-4"
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      >
                        <span className="flex items-center text-base font-medium text-gray-700 hover:text-gray-800">
                          <UserCircleIcon className="mr-2 h-10 w-10" />
                          {token === null
                            ? null
                            : `Hi, ${localStorage.getItem("user") as string}`}
                          <img
                            ref={dropdownImgRef}
                            src={dropdownIcon}
                            alt="dropdown icon"
                            width={16}
                            height={16}
                            className={`cursor-pointer ${
                              isDropdownOpen ? "bg-gray-200" : ""
                            }`}
                          />
                        </span>
                        {isDropdownOpen && dropdownModal}
                        <hr />
                      </div>
                      )}
                </div>

                {/* Links */}
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <SearchModal open={modal} setOpen={setModal} />
      <CartSideMenus
        open={cart}
        setOpen={setCart}
        data={data}
        cartItems={cartItems}
        totalCost={totalCost}
        loading={loading}
        updateCartItem={updateCartItem}
        deleteCartItem={deleteCartItem}
      />

      <header className="relative bg-white">
        <p className="flex overflow-hidden whitespace-nowrap h-10 items-center justify-center bg-[#3b82f7] px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
          <span className='marquee'>Get free delivery on orders over ₦150,000 Call&nbsp;
          <a href="tel:017005571" className="underline">
            01-7005571
          </a>
          &nbsp;to order now</span>
        </p>

        <nav
          aria-label="Top"
          className="mx-auto max-w-7xl  px-4 sm:px-4 lg:px-4"
        >
          <div className="border-b border-gray-200">
            <div className="flex h-16 items-center">
              <button
                type="button"
                className="rounded-md bg-white p-2 text-gray-400 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="ml-4 flex lg:ml-0">
                <a href="/">
                  <span className="sr-only">DrugStoc</span>
                  <img className="h-[2rem] w-auto" src={Logo} alt="" />
                </a>
              </div>

              {/* Flyout menus */}
              <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
                <div className="flex h-full space-x-8">
                  <CategoryDropdown
                    data={categoryData}
                    loading={categoryLoading}
                  />
                  <ManufacturerDropDown
                    data={manufacturerData}
                    loading={manufacturerLoading}
                  />
                  <a
                    href="https://drugstoc.com/about"
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                  >
                    About Us
                  </a>
                </div>
              </Popover.Group>

              <div className="ml-auto flex items-center">
                {localStorage.getItem("token") === null
                  ? (
                    <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                      <a
                        href="/login"
                        className="text-sm font-medium text-gray-700 hover:text-gray-800"
                      >
                        Log in
                      </a>
                      <span
                        className="h-6 w-px bg-gray-200"
                        aria-hidden="true"
                      />
                      <a
                        href="/register"
                        className="text-sm font-medium text-gray-700 hover:text-gray-800"
                      >
                        Create account
                      </a>
                    </div>
                    )
                  : (
                    <div
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      ref={dropdownDivRef}
                      className="hidden cursor-pointer lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6"
                      style={{ position: "relative" }}
                    >
                      <div className="flex justify-between gap-2">
                        <span className="text-sm font-medium text-gray-700 hover:text-gray-800">
                          Hi, {localStorage.getItem("user")}
                        </span>
                        <img
                          ref={dropdownImgRef}
                          src={dropdownIcon}
                          alt="dropdown icon"
                          width={16}
                          height={16}
                          className={`cursor-pointer ${
                            isDropdownOpen ? "bg-gray-200" : ""
                          }`}
                        />
                      </div>
                      {isDropdownOpen && (
                        <div className="absolute right-0 top-[25px] z-10 h-[80px] w-36 overflow-hidden rounded-md border border-gray-300 bg-white shadow-lg">
                          <ul className="flex h-[68px] flex-col justify-between">
                            <li
                              onClick={handleClick}
                              className="cursor-pointer py-2 hover:bg-gray-300"
                            >
                              <span className="px-2 py-1">Dashboard</span>
                            </li>
                            <li className="cursor-pointer py-2 hover:bg-gray-300">
                              <span className="px-2 py-1" onClick={logout}>
                                Log out
                              </span>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    )}

                {/* Search */}
                <div className="flex lg:ml-6">
                  <button
                    onClick={() => setModal(true)}
                    className="p-2 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Search</span>
                    <MagnifyingGlassIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </button>
                </div>

                {/* Cart */}
                <div className="ml-4 flow-root lg:ml-6">
                  <button
                    onClick={() => setCart(true)}
                    className="group -m-2 flex items-center p-2"
                  >
                    {
                      /* <ShoppingBagIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    /> */
                    }
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                      />
                    </svg>

                    <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                      {cartTotal !== null ? cartTotal : null}
                    </span>
                    <span className="sr-only">items in cart, view bag</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
