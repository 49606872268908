import {
  CalculatorIcon,
  ChatBubbleLeftIcon,
  GlobeAltIcon,
  GlobeEuropeAfricaIcon,
  SpeakerWaveIcon,
} from '@heroicons/react/20/solid'
import React, { useCallback, useState } from 'react'
import ProfileProgress from '../../component/ProfieProgress/ProfileProgress.component'
import { useCart } from '../../hooks/handleCart'
import { useUser } from '../../hooks/useUser'
import Layout from '../../layout'
import Sidebar from '../../layout/Sidebar/Sidebar'

const DashboardPage: React.FC<{}> = () => {
  const [copy, setCopy] = useState<boolean>(false)
  const { data, loading } = useUser()
  const {
    cartItems,
    loading: cartLoading,
    deleteCartItem,
    updateCartItem,
    totalCost,
  } = useCart()

  const handleCopyReferralLink = useCallback(
    async (text: string | undefined): Promise<boolean> => {
      if (text === undefined) {
        return false
      }
      try {
        await navigator.clipboard.writeText(text)
        setCopy(true)
        return true
      } catch (error) {
        console.warn('Copy failed', error)
        setCopy(false)
        return false
      }
    },
    [copy]
  )

  return (
    <Layout
      cartItems={cartItems}
      data={cartItems}
      totalCost={totalCost}
      loading={cartLoading}
      updateCartItem={updateCartItem as any}
      deleteCartItem={deleteCartItem}>
      <Sidebar title="My Account" pathname="My Account">
        <div className="bg-gray-50 p-6">
          <h2 className="sm:text-base">Home</h2>
        </div>
        <div className="w-full py-8">
          {!loading ? (
            <h2 className="text-center text-sm font-medium leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
              Welcome, {data?.first_name}!
            </h2>
          ) : (
            <div className="flex flex-row justify-center">
              <div role="status" className="max-w-sm animate-pulse">
                <div className="mb-2 h-3.5 w-60 rounded-full bg-gray-200 dark:bg-gray-700"></div>
              </div>
            </div>
          )}
          <p className="text-center text-gray-500">
            {"Let's get you started with DrugStoc"}
          </p>
        </div>
        <ProfileProgress />
        <div className="mb-4 grid grid-cols-7 items-center gap-4 px-6">
          <div className="relative col-span-7 flex h-48 items-center justify-center overflow-hidden rounded-xl bg-green-50 shadow-xl dark:bg-gray-800 md:col-span-2">
            <CalculatorIcon className="absolute -right-5 -top-5 h-20 rotate-45 text-green-500 opacity-30" />
            <CalculatorIcon className="absolute -right-0 top-8 h-10 rotate-45 text-green-500 opacity-70" />
            <CalculatorIcon className="absolute -bottom-8 -left-5 h-16 -rotate-45 text-green-500 opacity-20" />
            <CalculatorIcon className="absolute bottom-1 left-3 h-8 -rotate-45 text-green-500 opacity-70" />
            <div>
              <p className="text-center text-base font-semibold text-gray-600 dark:text-gray-500">
                Get Pilometer
              </p>
              <p className="mx-auto w-5/6 text-center text-xs font-normal text-gray-400 dark:text-gray-500">
                Powerful and easy-to-use inventory management system for new and
                existing pharmacies.
              </p>
              <div className="mt-2 flex flex-row justify-center">
                <button
                  type="button"
                  className="rounded bg-green-500 px-3 py-2 text-center text-xs font-medium text-slate-50 hover:bg-green-600">
                  Request A device
                </button>
              </div>
            </div>
          </div>
          <div className="relative col-span-7 flex h-72 items-center justify-center overflow-hidden rounded-xl bg-[#3b82f7] p-6 shadow-2xl dark:bg-gray-800 md:col-span-3">
            <ChatBubbleLeftIcon className="absolute -bottom-3 -left-0 -z-0 h-14 text-white opacity-25" />
            <ChatBubbleLeftIcon className="absolute bottom-3 left-5 -z-0 h-10 text-white opacity-70" />
            <ChatBubbleLeftIcon className="absolute -right-8 -top-10 -z-0 h-28 text-white opacity-70" />
            <ChatBubbleLeftIcon className="absolute right-5 top-1 -z-0 h-24 text-white opacity-20" />
            <div>
              <div className="mb-4 flex w-full justify-center">
                <div className="relative inline-flex h-14 w-14 items-center justify-center overflow-hidden rounded-full bg-orange-400">
                  <SpeakerWaveIcon className="h-10 w-10 items-center text-gray-50" />
                </div>
              </div>
              <p className="text-center text-base font-semibold text-white dark:text-gray-500">
                Refer and win promo points
              </p>
              <p className="text-center text-xs font-normal text-white dark:text-gray-500">
                Share your referral code with colleagues and win promo points
              </p>
              <div className="mt-4 flex w-full flex-row items-center justify-center space-x-3">
                <input
                  type="text"
                  id="first_name"
                  value={
                    loading
                      ? 'Loading...'
                      : data?.referral_code?.toLocaleUpperCase()
                  }
                  disabled
                  className="block w-fit rounded-lg border border-gray-300 bg-transparent p-1.5 text-center text-sm text-white focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  placeholder="John"
                  required
                />
                <button
                  type="button"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={async () =>
                    await handleCopyReferralLink(data?.referral_code)
                  }
                  className="rounded bg-slate-100 px-3 py-2 text-center text-xs font-medium text-[#3b82f7] hover:bg-slate-200">
                  {!copy ? 'Copy' : 'Copied!'}
                </button>
              </div>
            </div>
          </div>
          <div className="relative col-span-7 flex h-48 items-center justify-center overflow-hidden rounded-xl bg-gray-100 shadow-xl dark:bg-gray-800 md:col-span-2">
            <GlobeAltIcon className="absolute right-20 h-[20rem] text-gray-300 opacity-40" />
            <GlobeEuropeAfricaIcon className="absolute bottom-0 left-[83%] h-10 text-gray-300" />
            <div className="z-20">
              <p className="text-center text-base font-semibold text-gray-600">
                Join TPN
              </p>
              <p className="text-center text-xs font-normal text-gray-500">
                Join THE PHARMACY NETWORK and be part of a large network of
                healthcare providers
              </p>
              <div className="mt-2 flex flex-row justify-center">
                <button
                  type="button"
                  disabled
                  className="rounded bg-gray-300 px-3 py-2 text-center text-xs font-medium text-gray-600 hover:bg-gray-400">
                  Coming soon....
                </button>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </Layout>
  )
}

export default DashboardPage
