import {
  ArrowLeftOnRectangleIcon,
  BuildingOffice2Icon,
  ClipboardDocumentListIcon,
  CogIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  HomeIcon,
  WalletIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import React, { Fragment, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSettings } from '../../hooks/useSettings'
import { ISidebar } from './inteface'
import { Dialog, Transition } from '@headlessui/react'

const Sidebar: React.FC<ISidebar> = ({ children, title, pathname }) => {
  const location = useLocation()
  const { logout } = useSettings()
  const [open, setOpen] = useState<boolean>(false)

  return (
    <div className="bg-gray-100">
      <div className="mb-5 bg-white shadow-sm lg:items-center lg:justify-between">
        <div className="container m-auto px-4 py-6">
          <div className="">
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                  <a
                    href="/"
                    className="inline-flex items-center text-sm font-normal text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                    <svg
                      aria-hidden="true"
                      className="mr-2 h-4 w-4"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg>
                    Home
                  </a>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="h-6 w-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                    <a
                      href="/dashboard"
                      className="ml-1 text-sm font-normal text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white md:ml-2">
                      Account
                    </a>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="h-6 w-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                    <span className="ml-1 text-sm font-normal text-gray-500 dark:text-gray-400 md:ml-2">
                      {pathname}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
          </div>

          <div className="mt-4">
            <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {title}
            </h2>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}>
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <div className="h-full overflow-y-auto bg-white px-3 py-4 dark:bg-gray-800">
                    <ul className="space-y-2 font-medium">
                      <li>
                        <a
                          href="/dashboard"
                          className={`flex items-center rounded-lg ${
                            location.pathname === '/dashboard'
                              ? 'bg-gray-200'
                              : ''
                          } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                          <HomeIcon className="h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                          <span className="ml-3">Dashboard</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="/business"
                          className={`flex items-center rounded-lg ${
                            location.pathname === '/business'
                              ? 'bg-gray-200'
                              : ''
                          } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                          <BuildingOffice2Icon className="h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                          <span className="ml-3 flex-1 whitespace-nowrap">
                            Business Information
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="/document"
                          className={`flex items-center rounded-lg ${
                            location.pathname === '/document'
                              ? 'bg-gray-200'
                              : ''
                          } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                          <DocumentTextIcon className="h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                          <span className="ml-3 flex-1 whitespace-nowrap">
                            Compliance & Documents
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="/wallet"
                          className={`flex items-center rounded-lg ${
                            location.pathname === '/wallet'
                              ? 'bg-gray-200'
                              : ''
                          } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                          <WalletIcon className="h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                          <span className="ml-3 flex-1 whitespace-nowrap">
                            Wallet
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="/orders"
                          className={`flex items-center rounded-lg ${
                            location.pathname.includes('/orders')
                              ? 'bg-gray-200'
                              : ''
                          } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                          <ClipboardDocumentListIcon className="h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                          <span className="ml-3 flex-1 whitespace-nowrap">
                            Orders History
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="/shipping-address"
                          className={`flex items-center rounded-lg ${
                            location.pathname.includes('/shipping-address')
                              ? 'bg-gray-200'
                              : ''
                          } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                          <DocumentDuplicateIcon className="h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                          <span className="ml-3 flex-1 whitespace-nowrap">
                            Shipping Address
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="/change-password"
                          className={`flex items-center rounded-lg ${
                            location.pathname === '/change-password'
                              ? 'bg-gray-200'
                              : ''
                          } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                          <CogIcon className="h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                          <span className="ml-3 flex-1 whitespace-nowrap">
                            Change Password
                          </span>
                        </a>
                      </li>
                      <li>
                        <button
                          onClick={logout}
                          className="flex w-full items-center rounded-lg p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                          <ArrowLeftOnRectangleIcon className="h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                          <span className="ml-3 whitespace-nowrap">
                            Log Out
                          </span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <button
          data-drawer-target="default-sidebar"
          data-drawer-toggle="default-sidebar"
          aria-controls="default-sidebar"
          onClick={() => setOpen(true)}
          type="button"
          className="mb-5 ml-auto mt-2 inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden">
          <span className="sr-only">Open sidebar</span>
          <svg
            className="h-6 w-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
          </svg>
        </button>
        <div className="grid grid-cols-8 gap-x-8 gap-y-10">
          <aside
            id="default-sidebar"
            className="hidden h-fit -translate-x-full shadow-sm transition-transform  sm:translate-x-0 md:col-span-2 md:block"
            aria-label="Sidebar">
            <div className="h-full overflow-y-auto bg-white px-3 py-4 dark:bg-gray-800">
              <ul className="space-y-2 font-medium">
                <li>
                  <a
                    href="/dashboard"
                    className={`flex items-center rounded-lg ${
                      location.pathname === '/dashboard' ? 'bg-gray-200' : ''
                    } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                    <HomeIcon className="h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                    <span className="ml-3">Dashboard</span>
                  </a>
                </li>
                <li>
                  <a
                    href="/business"
                    className={`flex items-center rounded-lg ${
                      location.pathname === '/business' ? 'bg-gray-200' : ''
                    } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                    <BuildingOffice2Icon className="h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                    <span className="ml-3 flex-1 whitespace-nowrap">
                      Business Information
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="/document"
                    className={`flex items-center rounded-lg ${
                      location.pathname === '/document' ? 'bg-gray-200' : ''
                    } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                    <DocumentTextIcon className="h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                    <span className="ml-3 flex-1 whitespace-nowrap">
                      Compliance & Documents
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="/wallet"
                    className={`flex items-center rounded-lg ${
                      location.pathname === '/wallet' ? 'bg-gray-200' : ''
                    } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                    <WalletIcon className="h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                    <span className="ml-3 flex-1 whitespace-nowrap">
                      Wallet
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="/orders"
                    className={`flex items-center rounded-lg ${
                      location.pathname.includes('/orders') ? 'bg-gray-200' : ''
                    } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                    <ClipboardDocumentListIcon className="h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                    <span className="ml-3 flex-1 whitespace-nowrap">
                      Orders History
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="/shipping-address"
                    className={`flex items-center rounded-lg ${
                      location.pathname.includes('/shipping-address')
                        ? 'bg-gray-200'
                        : ''
                    } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                    <DocumentDuplicateIcon className="h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                    <span className="ml-3 flex-1 whitespace-nowrap">
                      Shipping Address
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="/change-password"
                    className={`flex items-center rounded-lg ${
                      location.pathname === '/change-password'
                        ? 'bg-gray-200'
                        : ''
                    } p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}>
                    <CogIcon className="h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                    <span className="ml-3 flex-1 whitespace-nowrap">
                      Change Password
                    </span>
                  </a>
                </li>
                <li>
                  <button
                    onClick={logout}
                    className="flex w-full items-center rounded-lg p-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                    <ArrowLeftOnRectangleIcon className="h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" />
                    <span className="ml-3 whitespace-nowrap">Log Out</span>
                  </button>
                </li>
              </ul>
            </div>
          </aside>

          <div className="col-span-8 mb-8 sm:col-span-6 lg:col-span-6">
            <div className="min-h-[40rem] overflow-hidden rounded-lg bg-white shadow-sm">
              {children}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Sidebar
