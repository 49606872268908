import {
  forgetPassword,
  resetPassword,
  validateForgotPasswordToken,
} from './../Api/user.api'
import { useState, useMemo, useCallback, ChangeEvent, useEffect } from 'react'
import { IPasswordReset } from './../Api/interface'
import { toast } from 'react-hot-toast'
import { AxiosError } from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'

type IField = 'email' | 'password' | 'c_password'

interface IPasswordSettings {
  loading: boolean
  passwordForm: IPasswordReset
  validForm: boolean
  updatePasswordForm: (fieldName: IField, event: ChangeEvent) => void
  requestPasswordChange: (email: string) => void
  confirmPassword: (payload: IPasswordReset) => void
}

export const usePasswordSettings = (): IPasswordSettings => {
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [passwordForm, setPasswordForm] = useState<IPasswordReset>({
    email: '',
    password: '',
    c_password: '',
    token: '',
  })

  const verifyToken = useCallback(() => {
    if (location.pathname.includes('/reset-password/')) {
      const token = location.pathname.replace('/reset-password/', '')
      void validateForgotPasswordToken(token)
        .then((resp) => {
          setPasswordForm({ ...passwordForm, token })
        })
        .catch((_err) => {
          toast.error(
            'Invalid Reset Password Link or Link has expired please again'
          )
          navigate('/forgot-password')
        })
    }
  }, [passwordForm])

  const handleError = useCallback((data: any): string => {
    const res = Object.values(data)
    const message = res.join(',')
    return message
  }, [])

  const requestPasswordChange = useCallback(
    (email: string) => {
      setLoading(true)
      const resp = forgetPassword(email)
      void toast.promise(resp, {
        loading: 'Please wait....',
        success: (_props) => {
          setLoading(false)
          navigate('/login')
          return 'An email has been sent you completed your password reset request'
        },
        error: (err: AxiosError) => {
          const { response } = err
          const message = handleError(response?.data)
          setLoading(false)
          return `${message ?? 'Something went wrong try again'}`
        },
      })
    },
    [loading, passwordForm]
  )

  const confirmPassword = useCallback(
    (payload: IPasswordReset) => {
      setLoading(true)
      const resp = resetPassword(payload.password, payload.token)
      void toast.promise(resp, {
        loading: 'Please wait....',
        success: (_props) => {
          setLoading(false)
          navigate('/login')
          return 'Your Password has been Updated successfully'
        },
        error: (err: AxiosError) => {
          const { response } = err
          console.log(response)
          const message = handleError(response?.data)
          setLoading(false)
          return `${message ?? 'Something went wrong try again'}`
        },
      })
    },
    [loading, passwordForm]
  )

  const updatePasswordForm = useCallback(
    (field: IField, e: ChangeEvent) => {
      setPasswordForm((prev) => ({
        ...prev,
        [field]: (e.target as any).value,
      }))
    },
    [passwordForm]
  )

  const validForm = useCallback((): boolean => {
    if (
      passwordForm.password !== '' ||
      passwordForm.email !== '' ||
      passwordForm.password === passwordForm.c_password
    ) {
      return true
    }
    return false
  }, [passwordForm])

  useEffect(() => {
    verifyToken()
  }, [location])

  return useMemo(
    () => ({
      loading,
      passwordForm,
      validForm: !validForm(),
      updatePasswordForm,
      confirmPassword,
      requestPasswordChange,
    }),
    [loading, verifyToken, passwordForm]
  )
}
