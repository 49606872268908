import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import React from 'react'
import Doctors from '../../assets/img/doctors.png'
import Backdrop from '../../assets/img/backdrop.png'

const CarouselLayout: React.FC<{}> = () => {
  return (
    <div className="relative overflow-hidden rounded-xl  bg-[#2de1c32d] px-3 py-3 md:rounded-3xl md:px-24 md:py-12">
      <p className="text-3xl font-semibold text-[#04497C] md:w-[60%] md:text-4xl md:leading-[4.0rem]" style={{lineHeight: 1.2}}>
        {"Welcome to the safest marketplace to order pharmaceuticals"}
      </p>

      <p className="mb-2 mt-3 text-gray-500 md:mb-8 md:mt-12 invisible">
        Sign up For daily newsletter
      </p>

      <form className="md:w-[40%]">
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <PaperAirplaneIcon className="h-5 w-5 text-gray-500" />
          </div>
          <input
            type="search"
            className="block w-full rounded-lg bg-gray-50 p-2 pl-10 text-sm text-gray-900"
            placeholder="Your email Address"
          />
          <button
            type="submit"
            className="absolute bottom-[0.3rem] right-1.5 rounded-md bg-[#5EA3D6] px-4 py-1 text-sm font-medium text-white">
            Shop now
          </button>
        </div>
      </form>
      <img
        src={Backdrop}
        className="absolute -left-16 top-0 -z-10 opacity-40 md:h-[30rem]"
      />
      <img
        src={Doctors}
        className="absolute -right-10 top-5 hidden h-[52rem] md:flex"
      />
    </div>
  )
}

export default CarouselLayout
