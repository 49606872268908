import React from 'react'
import BusinessPage from '../pages/Business/Busniess.page'
import CategoryPage from '../pages/Category/Category.page'
import ChangePasswordPage from '../pages/ChangePassword/ChangePassword.page'
import CheckoutPage from '../pages/Checkout/Checkout.page'
import DashboardPage from '../pages/Dashboard/Dashbaord.Page'
import DocumentPage from '../pages/Document/Document.page'
import ConfirmPasswordPage from '../pages/ForgetPassword/ConfirmPassword.page'
import ForgotPasswordPage from '../pages/ForgetPassword/ForgotPassword.page'
import HomePage from '../pages/Home/HomePage.page'
import LoginPage from '../pages/Login/Login.Page'
import OrderHistoryPage from '../pages/OrderHistory/OrderHistory.page'
import ProductManufacturerPage from '../pages/ProductManufacturer/ProductsManufacturer.page'
import ProductsDetailPage from '../pages/ProductsDetail/ProductDetail.page'
import RegisterPage from '../pages/Register/Register.Page'
// import ResultPage from '../pages/Result/Result'
import ShippingPage from '../pages/Shipping/Shipping.page'
import ShippingFormPage from '../pages/Shipping/ShippingForm.page'
import {
  AvoidWhenAuthenticated,
  ProtectedRoute,
} from '../utilities/protectedRoute'
import { IRouter } from './interface'
import WalletPage from '../pages/wallet/Wallet.page'
import ManufacturerList from '../layout/ManufacturerList'
import CategoryList from '../layout/CategoryList'

export const Routes: IRouter[] = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/category/:slug',
    element: <CategoryPage />,
  },
  {
    path: '/manufacturers',
    element: <ManufacturerList />
  },
  {
    path: '/categories',
    element: <CategoryList />
  },
  {
    path: '/manufacturers/:id/:slug',
    element: <ProductManufacturerPage />,
  },
  {
    path: '/product/:slug',
    element: <ProductsDetailPage />,
  },
  {
    path: '/register',
    element: <AvoidWhenAuthenticated component={<RegisterPage />} />,
  },
  {
    path: '/login',
    element: <AvoidWhenAuthenticated component={<LoginPage />} />,
  },
  {
    path: '/dashboard',
    element: <ProtectedRoute component={<DashboardPage />} />,
  },
  {
    path: '/business',
    element: <ProtectedRoute component={<BusinessPage />} />,
  },
  {
    path: '/document',
    element: <ProtectedRoute component={<DocumentPage />} />,
  },
  {
    path: '/wallet',
    element: <ProtectedRoute component={<WalletPage />} />,
  },
  {
    path: '/checkout',
    element: <ProtectedRoute component={<CheckoutPage />} />,
  },
  {
    path: '/shipping-address',
    element: <ProtectedRoute component={<ShippingPage />} />,
  },
  {
    path: '/shipping-address/add-shipping-address',
    element: <ProtectedRoute component={<ShippingFormPage />} />,
  },
  {
    path: '/shipping-address/edit-shipping-address/:id',
    element: <ProtectedRoute component={<ShippingFormPage />} />,
  },
  {
    path: '/orders',
    element: <ProtectedRoute component={<OrderHistoryPage />} />,
  },
  {
    path: '/change-password',
    element: <ProtectedRoute component={<ChangePasswordPage />} />,
  },
  {
    path: '/forgot-password',
    element: <AvoidWhenAuthenticated component={<ForgotPasswordPage />} />,
  },
  {
    path: '/reset-password/:token',
    element: <AvoidWhenAuthenticated component={<ConfirmPasswordPage />} />,
  },
]
