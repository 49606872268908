/* eslint-disable linebreak-style */
// import {
//   // ArrowSmallRightIcon,
//   ArrowSmallLeftIcon,
// } from '@heroicons/react/24/solid'
import React from 'react'
import { useLocation } from 'react-router-dom'

const Pagination = ({
  currentPage,
  totalPages,
  goToPreviousPage,
  goToNextPage,
}: any): any => {
  const { pathname } = useLocation()
  const rectangleShape =
    'rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700'
  const circleShape =
    'rounded-full bg-black bg-opacity-50 hover:bg-opacity-75 w-[35px] h-[35px] overflow-hidden'
  return (
    <div
      id={currentPage}
      className={`${
        pathname === '/' ? '-mt-10' : 'mt-16'
      } flex justify-between`}>
      <div className={`${pathname === '/' ? 'relative right-4' : ''} ${pathname === '/' && currentPage === 1 ? 'invisible' : ''}`}>
        <button
          className={`flex cursor-pointer items-center justify-center font-bold text-white ${
            pathname === '/' ? circleShape : rectangleShape
          }`}
          onClick={goToPreviousPage}
          disabled={currentPage === 1}>
          {pathname === '/' ? (
            // <ArrowSmallLeftIcon className="w-10" h-10 />
            <img width="30" height="30" className='p-0.5' src="https://img.icons8.com/ios-glyphs/30/ffffff/back.png" alt="back"/>
          ) : (
            'Previous'
          )}
        </button>
      </div>
      {currentPage !== undefined &&
      totalPages !== undefined &&
      pathname !== '/' ? (
        <span>{`Page ${currentPage as string} of ${
          isNaN(totalPages) ? 1 : (totalPages as string)
        }`}</span>
          ) : null}
      <div className={`${pathname === '/' ? 'relative left-4' : ''} ${pathname === '/' && currentPage === totalPages ? 'invisible' : ''}`}>
        <button
          className={`flex cursor-pointer items-center justify-center font-bold text-white ${
            pathname === '/' ? circleShape : rectangleShape
          }`}
          onClick={goToNextPage}
          disabled={currentPage === totalPages}>
          {pathname === '/' ? (
            // <ArrowSmallRightIcon className="w-10" h-10 />
            <img width="30" height="30" className='p-0.5' src="https://img.icons8.com/ios-glyphs/30/ffffff/forward.png" alt="forward"/>
          ) : (
            'Next'
          )}
        </button>
      </div>
    </div>
  )
}

export default Pagination
