import React, { Fragment, useState } from 'react'
import { Dialog, Menu, /* Menu, */ Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  ChevronDownIcon,
  FunnelIcon,
  Squares2X2Icon,
} from '@heroicons/react/20/solid'
import { IFilter } from './interface'
import CategoriesFilter from './Categories'
import CompositionFilter from './Composition'
import ManufacturerFilter from './Manufacturer'
import PriceFilter from './PriceFilter'
// import BrandDetails from './BrandDetails'
import { useLocation } from 'react-router-dom'

const sortOptions = [
  // { name: 'Relevant', href: '#', current: true },
  { name: 'Newest', href: '#', current: false },
  { name: 'Composition (A - Z)', href: '#', current: false },
  { name: 'Composition (Z - A)', href: '#', current: false },
  { name: 'Name (A - Z)', href: '#', current: false },
  { name: 'Name (Z - A)', href: '#', current: false },
  { name: 'Quantity: Low to High', href: '#', current: false },
  { name: 'Quantity: High to Low', href: '#', current: false },
  { name: 'Price: Low to High', href: '#', current: false },
  { name: 'Price: High to Low', href: '#', current: false },
]

function classNames(...classes: any[]): any {
  return classes.filter(Boolean).join(' ')
}

const Filter: React.FC<IFilter> = ({
  children,
  title,
  subtitle,
  params,
  type,
  brand,
  handleFilter,
  isChecked,
  filterData,
  filteredData,
  setSelectedSortOption,
  handleGridSystem,
  uniqueFilteredManufacturerData,
  uniqueFilteredCompositionsData,
  uniqueFilteredCategoryData,
  textSearchedComposition,
  textSearchedManufacturer,
  textSearchedCategory,
  handleTextCategoryChange,
  handleTextManufacturerChange,
  handleTextCompositionChange,
  handleCategorySubmit,
  handleManufacturerSubmit,
  handleCompositionSubmit,
}) => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

  let uniqueSearchedCategoryData = uniqueFilteredCategoryData?.filter(
    (item: any) => {
      return item.label
        ?.toLowerCase()
        .includes(textSearchedCategory?.toLowerCase())
    }
  )
  const uniqueSearchedCompositionData = uniqueFilteredCompositionsData?.filter(
    (item: any) => {
      return item.label
        ?.toLowerCase()
        .includes(textSearchedComposition?.toLowerCase())
    }
  )
  let uniqueSearchedManufacturerData = uniqueFilteredManufacturerData?.filter(
    (item: any) => {
      return item.label
        ?.toLowerCase()
        .includes(textSearchedManufacturer?.toLowerCase())
    }
  )
  const { pathname } = useLocation()
  const path = pathname.split('/')[1]

  let count: number = filterData?.length
  if (filterData?.length > 0) {
    count = filterData?.length
  } else {
    count = filteredData?.length
  }

  if (uniqueSearchedManufacturerData === undefined) {
    uniqueSearchedManufacturerData = []
  } else if (uniqueSearchedCategoryData === undefined) {
    uniqueSearchedCategoryData = []
  }

  // let newDearchData: any[] = []
  // if (filterData.length === 0 && (uniqueSearchedManufacturerData?.length > 0 || uniqueSearchedCompositionData?.length > 0 || uniqueSearchedCategoryData?.length > 0 )) {
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   newDearchData = [...uniqueSearchedManufacturerData, ...uniqueSearchedCompositionData, ...uniqueSearchedCategoryData]
  // }

  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}>
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <div className="h-fit lg:block">
                    <div className="rounded-md bg-white p-6 md:shadow-md">
                      <div className="hidden">
                        <PriceFilter
                          params={params}
                          type={type}
                          isChecked={isChecked}
                          handleFilter={handleFilter}
                        />
                      </div>
                      {type === 'category' ? null : (
                        <div className="mt-0">
                          <CategoriesFilter
                            params={params}
                            type={type}
                            isChecked={isChecked}
                            handleFilter={handleFilter}
                            uniqueSearchedCategoryData={
                              uniqueSearchedCategoryData
                            }
                            handleSubmit={handleCategorySubmit}
                            handleTextChange={handleTextCategoryChange}
                            text={textSearchedCategory}
                            uniqueFilteredCategoryData={
                              uniqueFilteredCategoryData
                            }
                          />
                        </div>
                      )}
                      <div className="mt-3">
                        <CompositionFilter
                          params={params}
                          isChecked={isChecked}
                          type={type}
                          handleFilter={handleFilter}
                          uniqueSearchedCompositionData={
                            uniqueSearchedCompositionData
                          }
                          handleSubmit={handleCompositionSubmit}
                          handleTextChange={handleTextCompositionChange}
                          text={textSearchedComposition}
                          uniqueFilteredCompositionsData={
                            uniqueFilteredCompositionsData
                          }
                        />
                      </div>
                      {type === 'manufacturer' ? null : (
                        <div className="mt-3">
                          <ManufacturerFilter
                            params={params}
                            isChecked={isChecked}
                            filteredData={filteredData}
                            uniqueSearchedManufacturerData={
                              uniqueSearchedManufacturerData
                            }
                            handleSubmit={handleManufacturerSubmit}
                            handleTextChange={handleTextManufacturerChange}
                            text={textSearchedManufacturer}
                            uniqueFilteredManufacturerData={
                              uniqueFilteredManufacturerData
                            }
                            type={type}
                            handleFilter={handleFilter}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="max-w-full">
          <div>
            <div className="mx-auto flex max-w-7xl items-baseline justify-between border-b border-gray-200 bg-white px-4 pb-6 pt-5 sm:px-6 lg:px-8">
              <div>
                {path === 'manufacturers' ? (
                  <img src={brand?.logo} alt={title} width={100} height={100} />
                ) : null}
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                  {title}{' '}
                </h1>
                <span className="text-base font-normal text-gray-500">
                  ({subtitle} Items)
                </span>
              </div>
                  
              {/* @todo reconsider condition */}
              <div className="flex items-center">
                <Menu as="div" className="relative inline-block text-left">
                  {filterData.length > 0 ? null : (
                    <div>
                      <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        Sort
                        <ChevronDownIcon
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                  )}

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-60 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {sortOptions?.map((option, index) => (
                          <Menu.Item key={option.name}>
                            {({ active }) => (
                              <a
                                href={option.href}
                                key={index}
                                onClick={() => setSelectedSortOption(option)}
                                className={classNames(
                                  option.current
                                    ? 'font-medium text-gray-900'
                                    : 'text-gray-500',
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm'
                                )}>
                                {option.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

                {/* Grid */}
                <button
                  type="button"
                  onClick={handleGridSystem}
                  className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7">
                  <span className="sr-only">View grid</span>
                  <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
                </button>

                <button
                  type="button"
                  className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                  onClick={() => setMobileFiltersOpen(true)}>
                  <span className="sr-only">Filters</span>
                  <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div>
              <div className="mx-auto flex h-[64px] w-[95%] items-center justify-around gap-4">
                <div className="no-scrollbar mx-auto flex h-16 w-full max-w-7xl space-x-3 overflow-x-auto py-3">
                  {filterData?.map((el: any) => (
                    <span
                      key={`${el.value as string}-${el.name as string}`}
                      className="mr-2 flex items-center whitespace-nowrap rounded-full bg-gray-100 px-4 py-2 text-sm font-medium text-gray-800">
                      {el.label}
                      <button
                        type="button"
                        onClick={() => handleFilter(el)}
                        className="ml-2 flex items-center rounded-sm bg-transparent p-0.5 text-sm text-gray-400 hover:bg-gray-200">
                        <XMarkIcon className="h-3.5 w-3.5" />
                      </button>
                    </span>
                  ))}
                </div>
                {textSearchedCategory?.length > 0 ||
                textSearchedComposition?.length > 0 ||
                textSearchedManufacturer?.length > 0 ? (
                  <span className="whitespace-nowrap">Searching...</span>
                    ) : filterData.length > 0 ? (
                  <span className="whitespace-nowrap">Filtering...</span>
                    ) : (
                  <span className="whitespace-nowrap">
                    result: {count} items
                  </span>
                    )}
              </div>
            </div>
          </div>

          <div className="bg-gray-100">
            <section
              aria-labelledby="products-heading"
              className="mx-auto max-w-7xl pb-24 pt-6">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>

              <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                {/* Filters */}
                <div className="hidden h-fit lg:block">
                  {/* {type === 'manufacturer' && brand !== undefined ? (
                    <div className="mb-5 rounded-md bg-white p-6 shadow-md">
                      <BrandDetails
                        data={brand}
                        count={parseInt(subtitle as string)}
                      />
                    </div>
                  ) : null} */}
                  <div className="rounded-md bg-white p-6 shadow-md">
                    <div className="hidden">
                      <PriceFilter
                        params={params}
                        type={type}
                        isChecked={isChecked}
                        handleFilter={handleFilter}
                      />
                    </div>
                    {type === 'category' ? null : (
                      <div className="mt-0">
                        <CategoriesFilter
                          params={params}
                          type={type}
                          isChecked={isChecked}
                          handleFilter={handleFilter}
                          uniqueSearchedCategoryData={
                            uniqueSearchedCategoryData
                          }
                          handleSubmit={handleCategorySubmit}
                          handleTextChange={handleTextCategoryChange}
                          text={textSearchedCategory}
                          uniqueFilteredCategoryData={
                            uniqueFilteredCategoryData
                          }
                        />
                      </div>
                    )}
                    <div className="mt-3">
                      <CompositionFilter
                        params={params}
                        isChecked={isChecked}
                        type={type}
                        uniqueFilteredCompositionsData={
                          uniqueFilteredCompositionsData
                        }
                        uniqueSearchedCompositionData={
                          uniqueSearchedCompositionData
                        }
                        handleSubmit={handleCompositionSubmit}
                        handleTextChange={handleTextCompositionChange}
                        text={textSearchedComposition}
                        handleFilter={handleFilter}
                      />
                    </div>
                    {type === 'manufacturer' ? null : (
                      <div className="mt-3">
                        <ManufacturerFilter
                          params={params}
                          uniqueFilteredManufacturerData={
                            uniqueFilteredManufacturerData
                          }
                          uniqueSearchedManufacturerData={
                            uniqueSearchedManufacturerData
                          }
                          handleSubmit={handleManufacturerSubmit}
                          handleTextChange={handleTextManufacturerChange}
                          text={textSearchedManufacturer}
                          isChecked={isChecked}
                          type={type}
                          handleFilter={handleFilter}
                          filteredData={filteredData}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* Product grid */}
                {/* {type === 'manufacturer' && brand !== undefined ? (
                  <div className="-mb-5 rounded-md bg-white p-6 md:mb-5 md:hidden md:shadow-md">
                    <BrandDetails
                      data={brand}
                      count={parseInt(subtitle as string)}
                    />
                  </div>
                ) : null} */}
                <div className="rounded-md lg:col-span-3">{children}</div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  )
}

export default Filter
