import React, { useEffect, useState } from 'react'
import { useShipping } from '../../hooks/handleShipping'
import { IShippingSelect } from './interface'

type IMode = 'edit' | 'selected'

const ShippingSelect: React.FC<IShippingSelect> = ({
  value,
  onChange,
  setDefaultAddress,
}) => {
  const [mode, setMode] = useState<IMode>('selected')
  const { data } = useShipping(undefined)

  const defaultAddress = data.find((el) =>
    value !== 0 ? el.id === value : el.is_default === true
  )

  useEffect(() => {
    if (data?.length > 0) {
      setDefaultAddress(defaultAddress?.id)
    }
  }, [data])

  return (
    <div>
      {mode === 'selected' ? (
        <div className="flex items-start justify-between">
          {data?.length > 0 ? (
            <div>
              <p className="text-sm font-semibold text-gray-700">
                {defaultAddress?.first_name} {defaultAddress?.last_name}
              </p>
              <p className="text-sm text-gray-700">
                {defaultAddress?.address_line1}
              </p>
              <p className="text-sm text-gray-700">
                {defaultAddress?.address_line2}
              </p>
              <p className="text-sm text-gray-700">
                {defaultAddress?.phone_number1}
              </p>
              <p className="text-sm text-gray-700">
                {defaultAddress?.phone_number2}
              </p>
            </div>
          ) : (
            <a
              href="/shipping-address/add-shipping-address"
              className="font-semibold text-blue-500">
              Add Shipping Address
            </a>
          )}
          <button
            className="font-semibold text-blue-500"
            onClick={() => setMode('edit')}>
            Change Address
          </button>
        </div>
      ) : (
        <div>
          <div className="mb-2 border-b border-gray-200 pb-2">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              My Available Shipping Address
            </h3>
          </div>
          {data.map((el, index) => (
            <div key={index} className="mb-5 flex items-center">
              <div className="flex h-5 items-center">
                <input
                  id={`shipping${el.id as number}`}
                  name="shipping_method"
                  onChange={onChange}
                  aria-describedby={`shipping-text${el.id as number}`}
                  type="radio"
                  checked={value === el.id}
                  defaultValue={el.id}
                  className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                />
              </div>
              <div className="ml-2 w-[100%] overflow-hidden truncate text-sm">
                <label
                  htmlFor={`shipping${el.id as number}`}
                  className="w-[80%] truncate font-medium text-gray-900">
                  {el.first_name} {el.last_name}{' '}
                  <span className='className="text-xs" font-normal text-gray-500'>
                    {el.address_line1}, {el.address_line2}
                  </span>
                </label>
                <p
                  id={`shipping-text${el.id as number}`}
                  className="text-xs font-normal text-gray-500">
                  Phone Number: {el.phone_number1}, {el.phone_number2}{' '}
                </p>
                <a
                  href={`shipping-address/edit-shipping-address/${
                    el.id as number
                  }`}
                  className="text-xs font-medium text-blue-500">
                  Edit Address
                </a>
              </div>
            </div>
          ))}
          <div className="my-3 space-x-6">
            <button
              type="button"
              onClick={() => setMode('selected')}
              className="base-button">
              Use this Address
            </button>
            <a
              href="/shipping-address/add-shipping-address"
              className="font-semibold text-blue-500">
              Add Shipping Address
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default ShippingSelect
