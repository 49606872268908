import { AxiosResponse } from 'axios'
import AxiosConfig from '../config/axiosConfig'

export const productUrl = '/inventory/products'

export const productsDetails = (slug: string): any =>
  AxiosConfig.get(`/inventory/products/${slug}`).then(
    (resp: AxiosResponse) => resp.data
  )
export const productsAlternative = (slug: string): any =>
  AxiosConfig.get(`/inventory/products/${slug}/alternative`).then(
    (resp: AxiosResponse) => resp.data
  )

export const products = (urls: string): any =>
  AxiosConfig.get(urls).then((resp: AxiosResponse) => resp.data)
