import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

interface IProtection {
  component: React.ReactNode
}

export const ProtectedRoute = ({ component }: IProtection): any => {
  const token = localStorage.getItem('token')
  const location = useLocation()

  if (token == null) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return component
}

export const AvoidWhenAuthenticated = ({ component }: IProtection): any => {
  const token = localStorage.getItem('token')
  const location = useLocation()

  if (token !== null) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />
  }

  return component
}
