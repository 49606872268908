import { APIResponse, IShippingAddress } from './interface'
import { AxiosResponse } from 'axios'
import AxiosConfig from '../config/axiosConfig'

export const shippingUrl = '/shipping/shipping-address'

export const shippingList = async (
  url: string
): Promise<AxiosResponse<APIResponse>> => await AxiosConfig.get(url)

export const createShippingAddress = async (
  payload: IShippingAddress
): Promise<AxiosResponse> => await AxiosConfig.post(`${shippingUrl}`, payload)

export const updateShippingAddress = async (
  payload: IShippingAddress,
  id: number
): Promise<AxiosResponse> =>
  await AxiosConfig.patch(`${shippingUrl}/${id}`, payload)

export const detailShippingAddress = async (
  id: number
): Promise<AxiosResponse<IShippingAddress>> =>
  await AxiosConfig.get(`${shippingUrl}/${id}`)

export const deleteShippingAddress = async (
  id: number
): Promise<AxiosResponse> => await AxiosConfig.delete(`${shippingUrl}/${id}`)
