import useSWR from 'swr'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { IBusiness } from '../Api/interface'
import { userBusiness, updatedBusinessProfile } from '../Api/business.api'
import { toast } from 'react-hot-toast'
import { AxiosError } from 'axios'
import { FacilityCategories, IOptions } from '../utilities/option'

type IField =
  | 'name'
  | 'logo'
  | 'email'
  | 'address'
  | 'phone'
  | 'year_established'
  | 'category'
  | 'location'
  | 'about'
  | 'premise_license'
  | 'practice_license'

export interface IHandleBusiness {
  updateForm: (fieldName: IField, event: ChangeEvent<HTMLInputElement>) => void;
  form: IBusiness
  data: IBusiness
  loading: boolean
  isBusiness: boolean
  loadingLicense?: boolean
  loadingProfile?: boolean
  updateBusiness: (payload: IBusiness) => void
  uploadLicense: (payload: FormData, next: (option: boolean) => void) => void
}

export const useHandleBusiness = (): IHandleBusiness => {
  const { data, isLoading } = useSWR('/business/profile', userBusiness, {
    refreshInterval: 500,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [isBusiness, setBusiness] = useState<boolean>(false)

  const [form, setForm] = useState<IBusiness>({
    email: '',
    name: '',
    address: '',
    logo: '',
    about: '',
    category: '',
    location: '',
    phone: '',
    is_verified: false,
    // practice_license: null,
    year_established: '',
    // premise_license: null,
  })

  const initialFormData = useCallback(() => {
    const business: IOptions[] = FacilityCategories
    if (!isLoading) {
      const haveBusiness = business.some((e) => e.value === data?.category)
      if (haveBusiness) {
        setBusiness(true)
      }
      setForm({
        email: data?.email,
        name: data?.name,
        address: data?.address,
        logo: data?.logo,
        about: data?.about,
        category: data?.category,
        location: data?.location,
        phone: data?.phone,
        is_verified: data?.is_verified,
        // practice_license: data?.practice_license,
        year_established: data?.year_established,
        // premise_license: data?.premise_license,
      })
    }
  }, [isLoading, isBusiness])

  const updateForm = useCallback(
    (field: IField, e: ChangeEvent<HTMLInputElement>) => {
      setForm((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
    },
    [form]
  );
  
  const updateBusiness = useCallback(
    (payload: IBusiness) => {
      setLoading(true)
      const resp = updatedBusinessProfile({ payload })
      void toast.promise(resp, {
        loading: 'Please wait...',
        success: (props) => {
          setLoading(false)
          return 'Profile Updated Successfully'
        },
        error: (err: AxiosError) => {
          const { response } = err
          const messages: string | undefined = (response?.data as any)
            .non_field_errors
          setLoading(false)
          return `${messages ?? 'Something went wrong try again'}`
        },
      })
    },
    [loading]
  )

  const uploadLicense = useCallback(
    (payload: FormData, next: (option: boolean) => void) => {
      setLoading(true)
      const resp = updatedBusinessProfile({ payload })
      void toast.promise(resp, {
        loading: 'File Uploading Please wait...',
        success: (props) => {
          setLoading(false)
          next(false)
          return 'File Uploaded Successful'
        },
        error: (err: AxiosError) => {
          const { response } = err
          console.log(response)
          const messages: string | undefined = (response?.data as any)
            .non_field_errors
          setLoading(false)
          return `${messages ?? 'Something went wrong try again'}`
        },
      })
    },
    [loading, form]
  )

  useEffect(() => {
    initialFormData()
  }, [isLoading])

  return useMemo(
    () => ({
      form,
      data,
      updateForm,
      updateBusiness,
      isBusiness,
      uploadLicense,
      loading: isLoading || loading,
    }),
    [form, isLoading, isBusiness, loading, data]
  )
}
