/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react'
import { useProductHandler } from '../../hooks/handleProducts'
import Layout from '../../layout/index'
import { useLocation, useNavigate } from 'react-router-dom'
import Spinner from '../Spinner/Spinner'
import Pagination from '../Pagination'
import { ArrowRightCircleIcon, ArrowLeftCircleIcon } from '@heroicons/react/24/solid'

const ITEMS_PER_PAGE = 20
const BASE_URL = process.env.REACT_APP_BASE_URL

const ManufacturerList = (): any => {
  const [manufacturerList, setManufacturerList] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<any>()
  const productHandler = useProductHandler()
  const { cartItems, updateCartItem, deleteCartItem, cartLoading, totalCost } =
    productHandler
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const currentPage = queryParams.get('page') ?? 1
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const handlePageClick = (page: number): void => {
    navigate(`/manufacturers?page=${page}`)
  }

  const getAllManufacturers = async (): Promise<void> => {
    try {
      const response = await fetch(
        `${BASE_URL as string}/inventory/manufacturer/all?page=${currentPage}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      const json = await response.json()
      setManufacturerList(json)
      setIsLoading(true)
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void getAllManufacturers()
  }, [currentPage])

  return (
    <>
      <Layout
        cartItems={cartItems}
        totalCost={totalCost}
        data={cartItems}
        loading={cartLoading}
        updateCartItem={updateCartItem as any}
        deleteCartItem={deleteCartItem}>
        {!isLoading ? (
          <div className="flex h-[400px] items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <h1 className="ml-[10%] mt-5 text-[1.5rem]">All Manufacturers</h1>
            <div className="mx-auto my-5 flex w-[80%] flex-wrap justify-center gap-2 sm:justify-between">
              {manufacturerList?.results?.map((item: any, index: any) => (
                <div
                  key={item.id}
                  title={item.name}
                  className="manufacturer-card mx-auto my-4 flex w-96 flex-col truncate rounded-xl border border-solid border-gray-200 text-sm sm:w-64">
                  <div className="flex">
                    <a
                      href={`/manufacturers/${item.id as string}/${
                        item.slug as string
                      }`}
                      className="my-4 flex h-20 w-96 items-center justify-center text-center font-semibold hover:text-[#3b82f7] sm:w-64">
                      <img
                        src={
                          item.logo ??
                          `https://placehold.co/192/f0f0f0/png?text=${(
                            item.name as string
                          )?.replaceAll(' ', '+')}`
                        }
                        alt={item.desc}
                        width='100%'
                        height={64}
                        className="rounded-4 mx-auto aspect-[3/2] h-full w-full object-contain p-2"
                      />
                    </a>
                  </div>
                  <div
                    className={`bg-white py-2 text-center text-[0.8rem] font-semibold text-[#6b7280] manufacturer-name-${
                      index as string
                    }`}>
                    <a
                      href={`/manufacturers/${item.id as string}/${
                        item.slug as string
                      }`}>
                      {item.name}
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <Pagination
              ITEMS_PER_PAGE={ITEMS_PER_PAGE}
              handlePageClick={handlePageClick}
              previousArrow={ArrowLeftCircleIcon}
              nextArrow={ArrowRightCircleIcon}
              TOTAL_ITEMS={manufacturerList?.count}
            />
          </>
        )}
      </Layout>
    </>
  )
}

export default ManufacturerList
