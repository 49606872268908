import React, { useEffect, useState } from 'react'
import Loader from '../../assets/loaders/Loader'
import { useLogin } from '../../hooks/handleLogin'
import { useAuthentication } from '../../hooks/useAuth'
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/outline'
import { validateEmail } from '../../utilities/validator'
// import { EyeIcon } from '@heroicons/react/24/outline';

const LoginPage: React.FC<{}> = () => {
  const { form, formValid, updateForm } = useLogin()
  const { signin, error, loading } = useAuthentication()
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [showPasswordText, setShowPasswordText] = useState('password')

  const [emailError, setErrorEmail] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string>('')

  const handlePasswordCheck = (): any => {
    if (!isShowPassword) {
      setIsShowPassword(true)
      setShowPasswordText('text')
    } else {
      setIsShowPassword(false)
      setShowPasswordText('password')
    }
  }

  useEffect(() => {
    const isValidEmail = validateEmail(form?.email)

    if (!isValidEmail) {
      setErrorEmail('Invalid Email Address')
    } else if (form?.password.length > 0 && form.password.length < 8) {
      setPasswordError('Invalid Password - minimum of 8 characters')
    } else {
      setErrorEmail(null)
    }
  }, [form?.email, form?.password])

  return (
    <div>
      <nav className="fixed left-0 top-0 z-20 w-full bg-white px-2 py-4">
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <a href="/" className="flex items-center">
            <img
              className="mx-auto h-9 w-auto"
              src="/logo.png"
              alt="Your Company"
            />
          </a>
        </div>
      </nav>
      <div className="flex min-h-screen items-center justify-center bg-gray-100 px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-lg space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-semibold tracking-tight text-gray-600">
              Welcome Back!
            </h2>
            <p className="mt-2 text-center text-sm capitalize text-gray-600">
              Log in to continue shopping
            </p>
          </div>
          <div className="min-h-auto mx-auto rounded-lg border border-gray-200 bg-white p-6 shadow-xl dark:border-gray-700 dark:bg-gray-800 md:w-4/5">
            <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              {error.hasError ? (
                <div className="sm:col-span-6">
                  <p className="form-label text-red-600">{error.message}</p>
                </div>
              ) : null}
              <div className="sm:col-span-6">
                <label className="form-label">Your Email</label>
                <input
                  type="email"
                  value={form?.email.toLowerCase()}
                  onChange={(e) => updateForm('email', e)}
                  className="form-select"
                  placeholder="Your Email (e.g you@example.com)"
                  required
                />
                {form?.email.length > 0 && (
                  <p className="text-sm text-red-500">{emailError}</p>
                )}
              </div>
              <div className="relative sm:col-span-6">
                <label className="form-label">Your Password</label>
                <input
                  type={showPasswordText}
                  value={form?.password}
                  onChange={(e) => updateForm('password', e)}
                  className="form-select"
                  placeholder="Your Password"
                  required
                />
                {form?.password.length > 0 &&
                  form?.password.length < 8 &&
                  form?.password.length < 11 && (
                    <p className="text-sm text-red-500">{passwordError}</p>
                )}
                <div
                  onClick={handlePasswordCheck}
                  className="absolute right-4 top-9 cursor-pointer">
                  {isShowPassword ? (
                    <EyeIcon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <EyeSlashIcon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </div>

              <div className="text-sm sm:col-span-6">
                <a
                  href="/forgot-password"
                  className="font-medium text-[#3b82f7] hover:text-[#1b6ef8]">
                  Forgot your password?
                </a>
              </div>
              <div className="sm:col-span-6">
                <button
                  type="submit"
                  disabled={!formValid || loading}
                  onClick={() => signin(form)}
                  className="group relative flex w-full items-center justify-center rounded-md bg-[#3b82f7] px-3 py-2 text-sm font-semibold text-white hover:bg-[#1b6ef8] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3b82f7] disabled:opacity-60">
                  {loading ? <Loader /> : null}
                  {loading ? 'Please wait...' : 'Log in'}
                </button>
              </div>
              <div className="text-sm sm:col-span-6">
                <div className="flex w-full flex-row items-center">
                  <a
                    href="/register"
                    className="m-auto font-medium text-[#3b82f7] hover:text-[#1b6ef8]">
                    New to DrugStoc? Create Account
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
