import React, { useEffect, useState } from 'react';
import { useProductHandler } from '../../hooks/handleProducts';
import Layout from '../../layout';
import CarouselLayout from '../../layout/Carousel/Carousel.layout';
import FeaturedManufacturerLayout from '../../layout/FeaturedManufacturer/FeatureManufacturers.Layout';
import PopularProducts from '../../layout/PopuplarProducts/PopularProducts.Layout';

const HomePage: React.FC<{}> = () => {
  const productHandler = useProductHandler();
  const { cartItems, updateCartItem, deleteCartItem, cartLoading, totalCost } =
    productHandler;

  const [showButton, setShowButton] = useState(true);
  const [prevScrollY, setPrevScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = (): void => {
      const threshold = 100;
      const scrollY = window.scrollY;
      const footerHeight = 296;

      setShowButton(
        scrollY < threshold &&
          scrollY + window.innerHeight <
            document.body.scrollHeight - footerHeight
      );

      const scrollDirection = scrollY > prevScrollY ? 'down' : 'up';
      setPrevScrollY(scrollY);

      if (scrollDirection === 'down') {
        setShowButton(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollY, showButton]);

  const handleClick = (): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Layout
      cartItems={cartItems}
      totalCost={totalCost}
      data={cartItems}
      loading={cartLoading}
      updateCartItem={updateCartItem as any}
      deleteCartItem={deleteCartItem}>
      <div className="mx-auto max-w-2xl border-white p-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <CarouselLayout />
        <div className="mt-16">
          <FeaturedManufacturerLayout />
        </div>
        {showButton ? null : (
          <>
            <a
              href="https://wa.me/2348036879999"
              target="_blank"
              rel="noreferrer"
              className="fixed bottom-1 right-1 z-10 -rotate-90 cursor-pointer">
              <img
                width="45"
                height="45"
                src="https://img.icons8.com/3d-fluency/45/whatsapp.png"
                alt="whatsapp"
              />
            </a>
            <div>
              <div
                onClick={handleClick}
                className="fixed bottom-14 right-1 z-10 -rotate-90 cursor-pointer rounded-full bg-black bg-opacity-75 p-1 hover:bg-opacity-50">
                <img
                  width="30"
                  height="30"
                  className="p-0.5"
                  src="https://img.icons8.com/ios-glyphs/30/ffffff/forward.png"
                  alt="upward"
                />
              </div>
            </div>
          </>
        )}
        <div className="mb-20 mt-16">
          <PopularProducts productHandler={productHandler} />
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
