import {
  IOptions,
  FacilityCategories,
  IndividualCategories,
} from '../utilities/option'
import { validateEmail, validatePhoneNumber } from '../utilities/validator'
import { ChangeEvent, useCallback, useMemo, useState, useEffect } from 'react'

interface IUserProfile {
  first_name: string
  last_name: string
  email: string
  password: string
  business_name: string
  phone: string
  category: string
  location: string
  discovery: string
  ref_code: string
}

type IField =
  | 'business_name'
  | 'category'
  | 'discovery'
  | 'email'
  | 'first_name'
  | 'last_name'
  | 'location'
  | 'password'
  | 'phone'
  | 'ref_code'

type ICategory = 'individual' | 'facility' | ''

interface IProfile {
  personal: boolean
  account: boolean
  confirmation: boolean
}

export interface IHandleRegistration {
  profile: IProfile
  updateForm: (fieldName: IField, event: ChangeEvent) => void
  form: IUserProfile
  category: ICategory
  handleCategory: (event: ChangeEvent) => void
  handleBusinessCategory: () => IOptions[]
}

export const useRegistration = (): IHandleRegistration => {
  const [category, setCategory] = useState<ICategory>('')
  const [profile, setProfile] = useState<IProfile>({
    account: false,
    confirmation: false,
    personal: false,
  })

  const [form, setForm] = useState<IUserProfile>({
    business_name: '',
    category: '',
    discovery: '',
    email: '',
    first_name: '',
    last_name: '',
    location: '',
    password: '',
    phone: '',
    ref_code: '',
  })

  const completedPersonalData = useCallback(() => {
    if (
      validateEmail(form.email) &&
      category !== '' &&
      form.first_name !== '' &&
      form.last_name !== '' &&
      validatePhoneNumber(form.phone) &&
      form.password !== ''
    ) {
      setProfile({ ...profile, personal: true })
    } else {
      setProfile({ ...profile, personal: false })
    }
  }, [form])

  const completedAccountData = useCallback(() => {
    if (
      form.business_name !== '' &&
      form.location !== '' &&
      form.category !== '' &&
      form.discovery !== ''
    ) {
      setProfile({ ...profile, account: true })
    } else {
      setProfile(() => ({ ...profile, account: false }))
    }
  }, [form, profile.account])

  const handleAppStepsCompleted = useCallback(() => {
    if (!profile.personal) {
      completedPersonalData()
    } else {
      completedAccountData()
    }
  }, [category, form])

  const handleBusinessCategory = useCallback((): IOptions[] => {
    if (category === 'facility') {
      return FacilityCategories
    } else if (category === 'individual') {
      return IndividualCategories
    }
    return []
  }, [category])

  const updateBusinessName = useCallback(() => {
    if (
      category === 'individual' &&
      form.first_name !== '' &&
      form.phone !== '' &&
      form.last_name !== ''
    ) {
      form.business_name = `${form.first_name} ${form.last_name} (${form.phone})`
      form.category = ''
      profile.account = false
    } else {
      form.business_name = ''
      form.category = ''
      profile.account = false
    }
  }, [category, form.first_name, form.last_name, form.phone])

  const updateForm = useCallback(
    (field: IField, e: ChangeEvent) => {
      setForm((prev) => ({
        ...prev,
        [field]: (e.target as any).value,
      }))
    },
    [profile, form]
  )

  const handleCategory = useCallback(
    (e: ChangeEvent) => {
      form.business_name = ''
      form.category = ''
      setProfile(() => ({ ...profile, account: false }))
      setCategory(() => (e.target as any).value)
    },
    [category]
  )

  useEffect(() => {
    updateBusinessName()
    handleAppStepsCompleted()
  }, [category, form, profile.personal, profile.account])

  return useMemo(
    () => ({
      profile,
      updateForm,
      form,
      category,
      handleCategory,
      handleBusinessCategory,
    }),
    [profile.account, profile.personal, form, updateForm, category]
  )
}
