import { AxiosResponse } from 'axios'
import AxiosConfig from '../config/axiosConfig'

const accountVerifyUrl = '/drugstocpay/transaction/utils/account/verify'
const withDrawUrl = '/drugstocpay/transaction/fund/withdrawal'

export const userWallet = (url: string): any =>
  AxiosConfig.get(url).then((resp: AxiosResponse) => resp.data)

export const verifyAccount = async (
  bankCode: string,
  accountNumber: string
): Promise<AxiosResponse> =>
  await AxiosConfig.post(accountVerifyUrl, { bank_code: bankCode, account_number: accountNumber })


export const withdrawFunds = async (
  payload: any
): Promise<AxiosResponse> =>
  await AxiosConfig.post(withDrawUrl, payload)
