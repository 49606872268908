/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import { IBalanceView } from './interface'
import { WalletIcon } from '@heroicons/react/20/solid'
import useWalletHandler, { WalletSection } from '../../hooks/handleWallet'
import { FormatterAccount } from '../../utilities/utils'
import { userWallet } from '../../Api/drugstocPay.api'
import useSWR from 'swr';

const BalanceView: IBalanceView = ({ handleSection }) => {
  const { walletBalance, loading } = useWalletHandler()
  const { data: payData } = useSWR(
    '/drugstocpay/wallet/account',
    userWallet
  )
  return (
    <div className="flex justify-between rounded-lg bg-blue-100 p-4 flex-wrap gap-4">
      <div>
        <p className="font-bold">
          {walletBalance !== 0 && !loading && typeof walletBalance === 'number'
            ? `₦${FormatterAccount(walletBalance)}`
            : '₦0.00'}
        </p>
        <p className="text-xs text-gray-500">Your available wallet balance</p>
      </div>
      <div>
      <p className="font-bold">
      ₦{FormatterAccount(payData?.credit_balance)}
        </p>
        <p className="text-xs text-gray-500">Your available credit balance</p>
      </div>
      <div className="inline-flex items-center">
        <button
          type="button"
          onClick={() => handleSection(WalletSection.WITHDRAW_SECTION)}
          className="base-button">
          <WalletIcon className="-ml-2 mr-1 h-5 w-7" />
          Withdraw Fund
        </button>
      </div>
    </div>
  )
}

export default BalanceView
