import React from 'react'
// import { AppContext, IContext } from '../context/appContext'
import Footer from './Footer/Footer'
import { ILayout } from './interface'
import Navbar from './Navbar/Navbar'
import Banner from '../component/Banner/Banner'

const Layout: React.FC<ILayout> = ({
  children,
  data,
  totalCost,
  deleteCartItem,
  updateCartItem,
  loading,
  cartItems,
}) => {

  return (
    <div className='flex flex-col justify-between h-[100vh]'>
      <div>
      <Navbar
        data={data}
        loading={loading}
        totalCost={totalCost}
        updateCartItem={updateCartItem}
        deleteCartItem={deleteCartItem}
        cartItems={cartItems}
      />
      <Banner />
      <div className="min-h-[25rem]">{children}</div>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
