import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { compositionList } from './../Api/composition.api'
import useSWR from 'swr'
import { IDropDownLinkData } from '../component/Dropdowns/interface'
import { IFilterOptions } from '../layout/Filters/interface'
import { useLocation } from 'react-router'

interface IComposition {
  data: IDropDownLinkData[]
  filter: IFilterOptions[]
  loading: boolean
  filterComposition: (query: ChangeEvent<HTMLInputElement>) => void
  error: any
}

interface IResult {
  id: number
  name: string
  desc: string
  slug: string
  created_at: Date
  updated_at: Date
}

export const useComposition = (): IComposition => {
  const { pathname } = useLocation()
  const path = pathname.split('/')
  const params = path[2]
  const { data, isLoading, error } = useSWR(
    params !== undefined && path.includes('manufacturers') ? `/inventory/compositions?manufacturer=${params}` : '/inventory/compositions',
    compositionList
  )

  const [dropDown, setData] = useState<IDropDownLinkData[]>([])
  const [filter, setFilter] = useState<IFilterOptions[]>([])

  const dropdownList = useCallback(() => {
    if (!isLoading) {
      const result: IDropDownLinkData[] = data?.results.map((e: IResult) => ({
        name: e.name,
        link: `/composition/${e.slug}`,
      }))
      setData(result)
      const filterResult: IFilterOptions[] = data?.results.map((e: IResult) => ({
        value: e.id,
        label: e.name,
        checked: false,
        params: 'composition',
      }))
      setFilter(filterResult)
    }
    return []
  }, [isLoading])

  const handleFilterQuery = async (query: string): Promise<void> => {
    const res = await compositionList(
      `/inventory/compositions?name__icontains=${query}`
    )
    const filterResult: IFilterOptions[] = res.results.map((e: IResult) => ({
      value: e.id,
      label: e.name,
      checked: false,
      params: 'composition',
    }))
    setFilter(filterResult)
  }

  const filterComposition = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      void handleFilterQuery(event.target.value)
    },
    [filter]
  )

  useEffect(() => {
    dropdownList()
  }, [isLoading])

  return useMemo(
    () => ({
      data: dropDown,
      filter,
      loading: isLoading,
      filterComposition,
      error,
    }),
    [isLoading, dropDown, filter]
  )
}
