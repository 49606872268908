import React from 'react'
import { IProgressCard } from './interface'

const ProgressCard: React.FC<IProgressCard> = ({
  loading,
  isCompleted,
  urgent = false,
  title,
  actionUrl,
  actionName,
  subtitle,
}) => {
  return (
    <div>
      <div
        className={`h-0.5 ${
          isCompleted ? 'bg-green-600' : 'bg-gray-300'
        }`}></div>
      <div className="lg:px-7.5 lg:py-8.7 px-5 py-5 sm:border-b-0 sm:border-r sm:py-7 md:px-6">
        {!loading ? (
          <h4 className="text-base font-medium leading-snug text-gray-600">
            {title}
          </h4>
        ) : (
          <div role="status" className="max-w-sm animate-pulse">
            <div className="mb-4 h-2 w-48 rounded-full bg-gray-200 dark:bg-gray-700"></div>
          </div>
        )}
        {!loading ? (
          <p className=" mt-2.5 h-[2.25rem] text-xs text-gray-400">
            {subtitle}
          </p>
        ) : (
          <div role="status" className="mt-2.5 max-w-sm animate-pulse">
            <div className="mb-1.5 h-1.5 max-w-[330px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
            <div className="mb-1.5 h-1.5 max-w-[300px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
            <div className="h-1.5 max-w-[160px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
          </div>
        )}
        <div className="mt-6">
          {!loading ? (
            <div>
              {isCompleted ? (
                <span
                  id="badge-dismiss-green"
                  className="mr-2 inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-sm font-medium text-green-800 dark:bg-green-900 dark:text-green-300">
                  Completed
                  <button
                    type="button"
                    className="ml-2 inline-flex items-center rounded-sm bg-transparent p-0.5 text-sm text-green-400 hover:bg-green-200 hover:text-green-900 dark:hover:bg-green-800 dark:hover:text-green-300"
                    data-dismiss-target="#badge-dismiss-green"
                    aria-label="Remove">
                    <svg
                      aria-hidden="true"
                      className="h-3 w-3"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                    <span className="sr-only">Icon description</span>
                  </button>
                </span>
              ) : (
                <a
                  href={actionUrl ?? 'javascript:void(0)'}
                  className={`rounded ${
                    urgent
                      ? 'bg-red-400 hover:bg-red-600'
                      : 'bg-[#3b82f7] hover:bg-[#2375f9]'
                  } px-3 py-2 text-center text-xs font-medium text-white`}>
                  {actionName}
                </a>
              )}
            </div>
          ) : (
            <div role="status" className="mt-2.5 max-w-sm animate-pulse">
              <div className="mb-1.5 h-6 max-w-[100px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProgressCard
