import React from 'react'
import { IProducts } from '../../Api/interface'
import { IProductList } from './Interface'
import Products from './Product'
import ProductsLoader from './ProductsLoader'
import { useLocation } from 'react-router-dom'

const loadingData = (): any[] => {
  const arr: any[] = []
  arr.length = 50
  return [
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1,
  ]
}

const ProductList: React.FC<IProductList> = ({
  data,
  loading,
  grid,
  addToCart,
  deleteCartItem,
  updateCartItem,
  cartLoading,
  filteredData,
  products,
  isGridNum,
  filterData,
  textSearchedComposition,
  textSearchedCategory,
  textSearchedManufacturer,
}) => {
  const { pathname } = useLocation()
  const gridLayout = grid === undefined || pathname === '/' ? (grid = 3) : grid
  const path = pathname.split('/')

  let result: any
  if (filterData?.length > 0) {
    filteredData = filterData
      ?.map((item: any) => {
        return products?.results.filter((newItem: any) => {
          return (
            item.label === newItem.category ||
            Boolean(newItem.composition.includes(item.label)) ||
            item.label === newItem.manufacturer
          )
        })
      })
      .map((item: any) => {
        return item
      })
      .flat()
  }

  let newResult
  if (result !== undefined) {
    newResult = result
  } else {
    newResult = filteredData
  }

  if (textSearchedComposition?.length > 0) {
    filteredData = filteredData.filter((item: any) => {
      return item.composition
        .join(', ')
        ?.toLowerCase()
        .includes(textSearchedComposition?.toLowerCase())
    })
  }

  if (textSearchedCategory?.length > 0) {
    filteredData = filteredData.filter((item: any) => {
      return item.category
        ?.toLowerCase()
        .includes(textSearchedCategory?.toLowerCase())
    })
  }

  if (textSearchedManufacturer?.length > 0) {
    filteredData = filteredData.filter((item: any) => {
      return item.manufacturer
        ?.toLowerCase()
        .includes(textSearchedManufacturer?.toLowerCase())
    })
  }

  if (filteredData?.length < 20) {
    newResult = filteredData
  }

  if (loading) {
    return (
      <div
        className={`grid grid-cols-${
          gridLayout - 1
        } gap-x-6 gap-y-10 sm:grid-cols-${gridLayout} lg:grid-cols-${
          path[1] !== 'category' && path[1] !== 'manufacturers'
            ? gridLayout + 1
            : isGridNum ?? false
            ? gridLayout + 1
            : gridLayout + 2
        } xl:gap-x-8`}>
        {loadingData().map((_element: any, index: number) => (
          <ProductsLoader key={index} />
        ))}
      </div>
    )
  }

  return (
    <>
      <div
        className={`grid grid-cols-${
          gridLayout - 1
        } gap-x-6 gap-y-10 sm:grid-cols-${gridLayout} lg:grid-cols-${
          path[1] !== 'category' &&
          path[1] !== 'manufacturers' &&
          !path.includes('product')
            ? gridLayout + 1
            : isGridNum ?? false
            ? gridLayout + 1
            : gridLayout + 2
        } xl:gap-x-8`}>
        {path[1] !== 'manufacturers' && path[1] !== 'category'
          ? data?.map((value: IProducts, index: any) => {
            if (value.id !== undefined) {
              return (
                  <Products
                    key={`${value?.id}-${index as string}`}
                    image={value?.image}
                    name={value?.name}
                    price={value?.price}
                    manufacturer={value?.manufacturer}
                    id={value?.id}
                    in_cart={value?.in_cart}
                    category={value?.category}
                    composition={value?.composition}
                    desc={value?.desc}
                    SKU={value?.SKU}
                    slug={value?.slug}
                    quantity={value?.quantity}
                    quantity_in_cart={value?.quantity_in_cart}
                    addToCart={addToCart}
                    deleteCartItem={deleteCartItem}
                    updateCartItem={updateCartItem}
                    loading={cartLoading}
                    therapeutic={value?.therapeutic}
                    side_effects={value?.side_effects}
                    contra_indications={value?.contra_indications}
                    drug_interactions={value?.drug_interactions}
                  />
              )
            } else {
              return null
            }
          })
          : newResult?.map((value: IProducts, index: any) => {
            if (value.id !== undefined) {
              return (
                  <Products
                    key={`${value?.id}-${index as string}`}
                    image={value?.image}
                    name={value?.name}
                    price={value?.price}
                    manufacturer={value?.manufacturer}
                    id={value?.id}
                    in_cart={value?.in_cart}
                    category={value?.category}
                    composition={value?.composition}
                    desc={value?.desc}
                    SKU={value?.SKU}
                    slug={value?.slug}
                    quantity={value?.quantity}
                    quantity_in_cart={value?.quantity_in_cart}
                    addToCart={addToCart}
                    deleteCartItem={deleteCartItem}
                    updateCartItem={updateCartItem}
                    loading={cartLoading}
                    therapeutic={value?.therapeutic}
                    side_effects={value?.side_effects}
                    contra_indications={value?.contra_indications}
                    drug_interactions={value?.drug_interactions}
                  />
              )
            } else {
              return null
            }
          })}
      </div>
    </>
  )
}

export default ProductList
