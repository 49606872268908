import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { IWalletContext } from './interface'

export const WalletContext = createContext<Partial<IWalletContext>>({})

export const WalletProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [hasWallet, handleHasWallet] = useState<boolean>(false)

  const setHasWallet = useCallback(
    (value: boolean) => {
      handleHasWallet(value)
      localStorage.setItem('USER_WALLET_DATA', JSON.stringify(value))
    },
    [hasWallet]
  )

  useEffect(() => {
    const activatedWallet = localStorage.getItem('USER_WALLET_DATA')
    const token = localStorage.getItem('token')
    if(token !== null) {
      if (activatedWallet !== null) {
        handleHasWallet(JSON.parse(activatedWallet))
      } else {
        window.location.replace('/dashboard')
      }
    }
  }, [hasWallet])

  return useMemo(
    () => (
      <WalletContext.Provider value={{ hasWallet, setHasWallet }}>
        {children}
      </WalletContext.Provider>
    ),
    [hasWallet]
  )
}
