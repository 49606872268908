import { Dialog, Transition } from '@headlessui/react'
import { TrashIcon } from '@heroicons/react/20/solid'
import { PhotoIcon } from '@heroicons/react/24/outline'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useHandleBusiness } from '../../hooks/handleBusiness'
import { ILicenseUpload } from './interface'

interface IFileUpload {
  payload: any
  imageUrl: string
  imageName: string
}

const LicenseUploadModal: React.FC<ILicenseUpload> = ({
  title,
  field,
  open,
  setOpen,
}) => {
  const cancelButtonRef = useRef(null)
  const { loading, uploadLicense } = useHandleBusiness()

  const [file, setFile] = useState<IFileUpload>({
    payload: null,
    imageUrl: '',
    imageName: '',
  })

  const handleDeleteUpload = useCallback(() => {
    setFile({
      payload: null,
      imageUrl: '',
      imageName: '',
    })
  }, [file])

  const handleFileUpload = useCallback(
    (e: React.FormEvent) => {
      const formData = new FormData()
      formData.append(field, (e.target as any).files[0])

      setFile({
        payload: formData,
        imageUrl: URL.createObjectURL((e.target as any).files[0]),
        imageName: (e.target as any).files[0].name,
      })
    },
    [file]
  )

  const handleUpload = useCallback(() => {
    uploadLicense(file.payload, setOpen)
  }, [file])

  useEffect(() => {
    handleDeleteUpload()
  }, [field])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="w-full">
                          <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                            <div className="text-center">
                              <PhotoIcon
                                className="mx-auto h-12 w-12 text-gray-300"
                                aria-hidden="true"
                              />
                              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                <label
                                  htmlFor="file-upload"
                                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600">
                                  <span>Upload a file</span>
                                  <input
                                    id="file-upload"
                                    onChange={handleFileUpload}
                                    disabled={loading}
                                    name="file-upload"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    className="sr-only"
                                  />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                              </div>
                              <p className="text-xs leading-5 text-gray-600">
                                PNG, JPG, GIF up to 10MB
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {file.imageUrl !== '' ? (
                        <div className="mt-4 flex justify-between rounded-lg bg-gray-100 p-4">
                          <div className="flex items-center space-x-3">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={file.imageUrl}
                              alt="Image Upload"
                            />
                            <p className="text-sm text-gray-700">
                              {file.imageName}
                            </p>
                          </div>
                          <button
                            type="button"
                            onClick={handleDeleteUpload}
                            disabled={loading}
                            className="inline-flex items-center rounded-full p-2 text-center text-sm font-medium text-[#3b82f7] hover:bg-[#70a4f837]">
                            <TrashIcon className="h-6 w-6" />
                            <span className="sr-only">Icon description</span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    disabled={loading || file.payload === null}
                    className="inline-flex w-full justify-center rounded-md bg-[#3b82f7] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#99bffb] disabled:bg-[#99bffb] sm:ml-3 sm:w-auto"
                    onClick={handleUpload}>
                    {loading ? 'Please wait...' : 'Upload license'}
                  </button>
                  <button
                    type="button"
                    disabled={loading}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}>
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default LicenseUploadModal
