/* eslint-disable @typescript-eslint/naming-convention */
// import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { IProductHandler } from './Interface'
import { ArrowLongRightIcon } from '@heroicons/react/20/solid'

const Products: React.FC<IProductHandler> = ({
  id,
  image,
  name,
  price,
  category,
  manufacturer,
  in_cart,
  addToCart,
  updateCartItem,
  deleteCartItem,
  quantity_in_cart,
  quantity,
  slug,
}) => {
  const [cartQt, setCartQt] = useState<string>(
    quantity_in_cart !== 0 && quantity_in_cart !== undefined
      ? quantity_in_cart?.toString()
      : '1'
  )

  const Formatter = useCallback(
    (amount: number): string => {
      return amount?.toFixed(0).replace(/./g, function (c, i, a) {
        return i > 0 && c !== '.' && (a?.length - i) % 3 === 0 ? ',' + c : c
      })
    },
    [price]
  )

  let token: string | null = null

  if (token !== null || token !== undefined) {
    token = localStorage.getItem('token')
  }

  return (
    <div
      key={name}
      className="group relative rounded-xl border px-4 py-4 transition-shadow ease-linear hover:shadow-xl">
      <div className="aspect-w-1 aspect-h-1 lg:aspect-none h-32 w-full overflow-hidden truncate rounded-md group-hover:opacity-75 md:h-48 lg:h-48">
        <a href={`/product/${slug}`} className="truncate">
          <img
            src={
              image ??
              `https://placehold.co/192/f0f0f0/png?text=${name?.replaceAll(
                ' ',
                '+'
              )}`
            }
            alt={name}
            className="h-full min-h-[101px] w-full object-contain object-center"
          />
        </a>
      </div>
      <div className="min-h-[56px] md:min-h-[4.5rem]">
        <p className="truncate text-xs font-normal text-gray-500">{category}</p>
        <div className="max-h-10 overflow-ellipsis">
          <h3
            className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm font-semibold capitalize leading-5 text-gray-700 md:leading-5"
            title={name}>
            <a href={`/product/${slug}`}>{name}</a>
          </h3>
        </div>

        {manufacturer === null ? (
          <span className="invisible mb-3 mt-1">null</span>
        ) : (
          <p className="mb-3 mt-1 truncate text-xs font-normal text-gray-500">
            by <span className="font- text-[#3b82f7]">{manufacturer}</span>
          </p>
        )}

        {quantity > 0 || token === null ? (
          <p className="absolute left-0 top-1 ml-3 mt-2 flex w-[80%] justify-center rounded bg-blue-100 p-1 text-[12px] font-medium text-blue-800 opacity-90 dark:bg-blue-300">
            {/* .product-tooltip.tooltip:hover .tooltiptext */}
            <span className="express-shipping-txt">Express Shipping</span>
            <img
              className="express-shipping-img hidden"
              width="20"
              height="20"
              src="https://img.icons8.com/ios-filled/50/free-shipping.png"
              alt="free-shipping"
            />
          </p>
        ) : (
          <p className="absolute left-0 top-1 ml-3 mt-2 flex w-[80%] justify-center whitespace-nowrap rounded bg-amber-600 p-1 px-3.5 text-[12px] text-sm font-medium text-amber-100 opacity-90 hover:text-red-100 dark:bg-amber-600">
            <span className="express-shipping-txt">Available on request</span>
            <img
              className="express-shipping-img hidden"
              width="20"
              height="20"
              src="https://img.icons8.com/color/48/man-on-phone-skin-type-5.png"
              alt="free-shipping"
            />
          </p>
        )}
      </div>
      <div
        className={`${
          price === null || price === undefined
            ? 'lg:justify-even flex-col items-center gap-2.5 sm:flex-row sm:justify-center xl:justify-between'
            : 'flex-row items-center justify-between gap-0.5'
        } card-xs mx-auto mt-3 flex flex-wrap`}>
        {token !== null && price != null ? (
          <p className="my-2 text-xl font-semibold text-gray-500 md:my-0 md:text-base">
            &#8358; {Formatter(price)}
          </p>
        ) : (
          <span className="tooltip product-tooltip mr-2 block rounded bg-red-100 px-2 py-1 text-center text-xs font-medium text-red-800 sm:flex">
            <span className="contact-us">&#8358; Contact Us</span>
            <img
              className="phone hidden"
              width="20"
              height="20"
              src="https://img.icons8.com/ios-glyphs/30/phone--v1.png"
              alt="phone--v1"
            />
            <span className="tooltiptext">
              Contact us for price information
            </span>
          </span>
        )}
        {!(in_cart ?? false) ? (
          <div>
            {quantity > 0 || token === null ? (
              <>
                <button
                  type="button"
                  // disabled={loading}
                  onClick={() => {
                    addToCart({ product_id: id, quantity: 1 })
                    in_cart = true
                  }}
                  className="inline-flex items-center rounded-md bg-[#3b82f7] px-2 py-2.5 text-center text-sm font-medium text-white hover:bg-[#3b82f7]">
                  <svg
                    aria-hidden="true"
                    className="cart-box hidden h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
                  </svg>
                  <span className="add-to-cart-txt">Add to cart</span>
                </button>
              </>
            ) : (
              <div className="product-tooltip tooltip flex flex-col items-center justify-center md:items-end">
                {/* <p className="absolute top-3 inline-flex flex-row truncate rounded-xl text-left text-sm text-red-600 hover:text-red-800">
                  Available on request
                </p> */}
                <a
                  href={`/product/${slug}`}
                  className="-pt-5 inline-flex flex-row items-center truncate rounded-xl pb-2.5 text-center text-base text-[#3b82f7] hover:text-[#1a6df3]">
                  <span>
                    <span>
                      View&nbsp;
                      <span className="hidden sm:inline-block view-alt">
                        alternative
                      </span>
                    </span>
                    <span className="tooltiptext right-0">
                      View alternatives
                    </span>
                  </span>
                  <ArrowLongRightIcon className="ml-1 h-5 w-5" />
                </a>
              </div>
            )}
          </div>
        ) : (
          <div className="flex space-x-2">
            <div className="flex flex-col items-center">
              <input
                type="text"
                value={cartQt}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCartQt(e.target.value)
                }
                className="sm:text-md block w-16 rounded-lg border border-gray-300 bg-gray-50 p-1 text-center text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              />
              {cartQt === quantity_in_cart?.toString() ? (
                <p className="text-[10px] text-gray-500">
                  Already in your cart
                </p>
              ) : null}
            </div>
            {parseInt(cartQt) > quantity ? (
              <p className="mt-1 bg-amber-600 text-sm text-amber-100 hover:text-red-100 dark:bg-amber-600 ">
                Available on request
              </p>
            ) : cartQt !== quantity_in_cart?.toString() ? (
              <button
                type="button"
                disabled={cartQt === ''}
                onClick={() =>
                  parseInt(cartQt) > 0 || cartQt === ''
                    ? updateCartItem(
                      {
                        product_id: id,
                        quantity: parseInt(cartQt),
                      },
                      id
                    )
                    : deleteCartItem(id)
                }
                className="font-medium text-[#3b82f7] hover:text-[#3b82f7]">
                Update
              </button>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}

export default Products
