import useSWR from 'swr'
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { userWallet, verifyAccount, withdrawFunds } from '../Api/drugstocPay.api'
import { HandleWalletContext } from '../context/Wallet/handleWallet.context'
import { toast } from 'react-hot-toast'
import { AxiosError, AxiosResponse } from 'axios'

export enum WalletSection {
  ACCOUNT_SECTION = 'ACCOUNT SECTION',
  WITHDRAW_SECTION = 'WITHDRAW SECTION',
}

export interface ITransactions {
  id: number
  transaction_id: string
  amount: number
  transaction_ref: number
  currency: string
  transaction_type: string
  remark: string
  status: 'pending' | 'success' | 'failed'
  payment_type: string
  created_at: string
  updated_at: string
  user: number
  business: number
  payment_id: number
  sender: number
  receiver: number
}

export interface IBanks {
  bank_code: string
  bank_name: string
}

interface ITransferFund {
  bank_code: string
  bank_name: string
  account_no: string
  account_name: string
  transaction_pin?: string | null
  amount: number
  remark: string
}

type IField =
  | 'bank_code'
  | 'bank_name'
  | 'account_no'
  | 'account_name'
  | 'transaction_pin'
  | 'amount'
  | 'remark'

interface IHandleWallet {
  isAuthenticated: boolean
  section: WalletSection
  walletBalance: number
  loading: boolean
  transactions: ITransactions[]
  banks: IBanks[]
  handleWalletSection: (value: WalletSection) => void
  transferFund: () => void
  handleAuthenticated: () => void
  updateForm: (fieldName: IField, event: ChangeEvent<Element>) => void
  updateFormData: (fieldName: IField, value: string) => void
  form: ITransferFund
}

const useWalletHandler = (): IHandleWallet | any => {
  const { pin } = useContext(HandleWalletContext)
  const { data: payData, isLoading: payLoading } = useSWR(
    '/drugstocpay/wallet/account',
    userWallet
  )
  console.log(payData)
  const { data: transactionData, isLoading: transactionLoading } = useSWR(
    '/drugstocpay/transaction/',
    userWallet
  )

  const { data: bankData, isLoading: bankLoading } = useSWR(
    '/drugstocpay/transaction/utils/banks',
    userWallet
  )
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false)
  const [transactions, setTransaction] = useState<ITransactions[]>([])
  const [banks, setBanks] = useState<IBanks[]>([])
  const [form, setForm] = useState<ITransferFund>({
    account_no: '',
    account_name: '',
    amount: 0,
    bank_code: '',
    bank_name: '',
    remark: '',
    transaction_pin: pin,
  })
  const [walletSection, setWalletSection] = useState<WalletSection>(
    WalletSection.ACCOUNT_SECTION
  )

  const handleAuthenticated = useCallback(() => {
    setAuthenticated(true)
  }, [])

  const handleWalletSection = useCallback(
    (value: WalletSection) => {
      setWalletSection(value)
    },
    [walletSection]
  )

  const transferFund = useCallback(() => {
    const resp = withdrawFunds(form)
    void toast.promise(resp, {
      loading: 'Please wait',
      success:(props: AxiosResponse) => {
        setWalletSection(WalletSection.ACCOUNT_SECTION)
        return "Transaction Successful"
      },
      error: 'Transaction failed'
    })
  }, [form])

  const updateFormData = useCallback((field: IField, value: string) => {
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }))
  },[form])

  const verifyAccountNumber = useCallback((bankCode: string, accountNumber: string) => {
    const resp = verifyAccount(bankCode, accountNumber)
    void toast.promise(resp, {
      loading: 'Verifying...',
      success:(props: AxiosResponse) => {
        updateFormData('account_name', props.data.account_name)
        return "Verification Confirmed"
      },
      error: (err: AxiosError) => {
        console.log(err.response)
        return 'Verification failed'
      },
    })
  },[form])

  const updateForm = useCallback(
    (field: IField, e: ChangeEvent<Element>) => {
      if(field === 'account_no' && (e.target as any).value.length === 10) {
        verifyAccountNumber(form.bank_code, ((e.target as any).value))
      }
      setForm((prev) => ({
        ...prev,
        [field]: (e.target as any).value,
      }))
    },
    [form]
  )

  useEffect(() => {
    if (!transactionLoading && transactionData?.results !== undefined) {
      setTransaction(transactionData?.results)
    }
    if (!bankLoading && bankData?.results !== undefined) {
      setBanks(bankData?.results)
    }
  }, [transactions, transactionLoading])

  return useMemo(
    () => ({
      isAuthenticated,
      walletBalance: payData?.balance,
      loading: payLoading || transactionLoading,
      section: walletSection,
      updateForm,
      updateFormData,
      transferFund,
      form,
      transactions,
      banks,
      handleWalletSection,
      handleAuthenticated,
    }),
    [
      isAuthenticated,
      walletSection,
      payData,
      transactions,
      transactionLoading,
      form,
    ]
  )
}

export default useWalletHandler
