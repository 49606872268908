import React, { useState } from 'react'
import OrderList from '../../component/Orders/OrderList.component'
import { useOrder } from '../../hooks/handeCheckout'
import { useCart } from '../../hooks/handleCart'
import Layout from '../../layout'
import Sidebar from '../../layout/Sidebar/Sidebar'
import Modal from '../Home/Modal/Modal'

const OrderHistoryPage: React.FC<{}> = () => {
  const { loading, data, orders, goToNextPage, goToPrevPage }: any = useOrder()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean | null>(null);

  const closeModal = (e: any): any => {
    if (e.target === e.currentTarget) {
      setShowDeleteModal(false);
    } 
  };

  // const handlClick = (): void => {
  //   setShowDeleteModal(false)
  // }

  const {
    cartItems,
    loading: cartLoading,
    deleteCartItem,
    totalCost,
    updateCartItem,
  } = useCart()
  return (
    <Layout
      cartItems={cartItems}
      totalCost={totalCost}
      data={cartItems}
      loading={cartLoading}
      updateCartItem={updateCartItem as any}
      deleteCartItem={deleteCartItem}>
      <Sidebar title="Order History" pathname="Order History">
        <Modal closeModal={closeModal} showDeleteModal={showDeleteModal} data={data} /* handlClick={handlClick} *//>
        <div className="bg-gray-50 p-6">
          <h2 className="sm:text-base">Order History</h2>
        </div>
        <div className="p-6">
          <OrderList loading={loading} data={data} orders={orders} goToNextPage={goToNextPage} goToPrevPage={goToPrevPage} />
        </div>
      </Sidebar>
    </Layout>
  )
}

export default OrderHistoryPage
