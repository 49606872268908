import { AxiosResponse } from 'axios'
import AxiosConfig from '../config/axiosConfig'
import { IPasswordChange } from './interface'

interface IRequest {
  payload: IPasswordChange
}

export const userProfile = (url: string): any =>
  AxiosConfig.get(url).then((resp: AxiosResponse) => resp.data)

export const updateUserPassword = async ({
  payload,
}: IRequest): Promise<AxiosResponse> => {
  return await AxiosConfig.post('/auth/change_password', payload)
}

export const forgetPassword = async (email: string): Promise<AxiosResponse> =>
  await AxiosConfig.post('/auth/password_reset/', { email })

export const validateForgotPasswordToken = async (
  token: string
): Promise<AxiosResponse> =>
  await AxiosConfig.post('/auth/password_reset/validate_token/', { token })

export const resetPassword = async (
  password: string,
  token: string
): Promise<AxiosResponse> =>
  await AxiosConfig.post('/auth/password_reset/confirm/', {
    password,
    token,
  })
