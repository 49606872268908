import { IManufacturers } from './interface'
import { AxiosResponse } from 'axios'
import AxiosConfig from '../config/axiosConfig'

export const manufacturerUrl = '/inventory/manufacturer/all'

export const manufacturerList = (url: string): any =>
  AxiosConfig.get(url).then((resp: AxiosResponse) => resp.data)

export const manufacturerDetail = async (
  slug: string
): Promise<IManufacturers> =>
  await AxiosConfig.get(`${manufacturerUrl}/${slug}`).then(
    (resp: AxiosResponse) => resp.data
  )
