import { AxiosResponse } from 'axios'
import { SearchProducts } from './../Api/search.api'
import React, { useCallback, useMemo, useState } from 'react'
import { APIResponse, IProducts } from '../Api/interface'

interface ISearch {
  query: string
  loading: boolean
  search: (query: React.ChangeEvent<HTMLInputElement>) => void
  result: IProducts[]
}

export const useSearch = (): ISearch => {
  const [query, setQuery] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [result, setResult] = useState<IProducts[]>([])

  const search = useCallback(
    async (q: React.ChangeEvent<HTMLInputElement> | undefined) => {
      setQuery(q?.target.value as string)
      if ((q?.target.value?.length as number) > 0) {
        setLoading(true)
        const response: AxiosResponse = await SearchProducts(
          q?.target.value as string
        )
        const { data } = response
        const searchResult: IProducts[] = (data as APIResponse).results
        setResult(searchResult)
        setLoading(false)
      }
    },
    [query]
  )

  return useMemo(
    () => ({
      query,
      loading,
      search,
      result,
    }),
    [query, loading]
  )
}