import React from 'react'
import { IPaymentOptions } from './interface'

const PaymentMethod: React.FC<IPaymentOptions> = ({ onChange }) => {
  return (
    <div className="flex space-x-4">
      <div className="flex">
        <div className="flex h-5 items-center">
          <input
            id="drugstocPay"
            name="payment_method"
            aria-describedby="drugstocPay-text"
            type="radio"
            // disabled
            defaultValue="drugstoc_pay"
            onChange={onChange}
            className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
          />
        </div>
        <div className="ml-2 text-sm">
          <label
            htmlFor="drugstocPay"
            className="font-medium text-gray-900 dark:text-gray-300">
            DrugStoc Pay
          </label>
          <p
            id="drugstocPay-text"
            className="text-xs font-normal text-gray-500 dark:text-gray-300">
            Make Payment from your wallet
          </p>
        </div>
      </div>
      <div className="flex">
        <div className="flex h-5 items-center">
          <input
            id="card-payment"
            name="payment_method"
            onChange={onChange}
            aria-describedby="card-payment-text"
            type="radio"
            defaultValue="card_payment"
            className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
          />
        </div>
        <div className="ml-2 text-sm">
          <label
            htmlFor="card-payment"
            className="font-medium text-gray-900 dark:text-gray-300">
            Debit Card
          </label>
          <p
            id="card-payment-text"
            className="text-xs font-normal text-gray-500 dark:text-gray-300">
            Use your card to make payment
          </p>
        </div>
      </div>
      <div className="flex">
      <div className="flex h-5 items-center">
          <input
            id="drugstoc-credit"
            name="payment_method"
            onChange={onChange}
            aria-describedby="drugstoc_credit-text"
            type="radio"
            defaultValue="drugstoc_credit"
            className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
          />
        </div>
        <div className="ml-2 text-sm">
          <label
            htmlFor="drugstoc-credit"
            className="font-medium text-gray-900 dark:text-gray-300">
            Pay Later
          </label>
          <p
            id="drugstoc-credit-text"
            className="text-xs font-normal text-gray-500 dark:text-gray-300">
            Installmental Payment
          </p>
        </div>
      </div>
    </div>
  )
}

export default PaymentMethod
