import { AxiosResponse } from 'axios'
import AxiosConfig from '../config/axiosConfig'
import { IBusiness } from './interface'

interface IRequest {
  payload: IBusiness | FormData
}

export const userBusiness = async (url: string): Promise<any> => {
  try {
    const resp: AxiosResponse = await AxiosConfig.get(url);
    return resp.data;
  } catch (error: any) {
    console.error(error?.response?.data?.detail);
    throw error;
  }
};

export const updatedBusinessProfile = async ({
  payload,
}: IRequest): Promise<AxiosResponse> => {
  try {
    const response: AxiosResponse = await AxiosConfig.patch('/business/profile', payload);
    return response;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};
