import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import React, { useEffect, useState } from 'react'
import { useCategory } from '../../hooks/handleCategoeries'
import FilterOptions from './FilterOptions'
import { IQuery } from './interface'

const CategoriesFilter: React.FC<IQuery> = ({
  params,
  type,
  handleFilter,
  isChecked,
  filteredData,
  uniqueFilteredCategoryData,
  handleSubmit,
  handleTextChange,
  uniqueSearchedCategoryData,
  text,
}) => {
  const { filter, loading } = useCategory()
  const [manufacturerList, setManufacturerList] = useState<any>()

  const token = localStorage.getItem('token')

  useEffect(() => {
    const getManufacturerList = async (): Promise<void> => {
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL as string
        }/inventory/products?manufacturer=${params}`,
        {
          headers: {
            Authorization: `token ${token ?? ''}`,
          },
        }
      )
      const json = await response.json()
      setManufacturerList(json.results)
    }
    void getManufacturerList()
  }, [params])

  const filteredFilter = filter?.filter((item: any) =>
    manufacturerList?.some(
      (manufacturerItem: any) => manufacturerItem.category === item.label
    )
  )
  
  return (
    <div>
      <h3 className="flow-root font-medium">Categories</h3>
      <form className="my-5" onSubmit={handleSubmit}>
        <div className="relative w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
          </div>
            <input
              type="search"
              id="voice-search"
              value={text ?? ''}
              onChange={handleTextChange}
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              placeholder="Search Categories"
              required
            />
        </div>
      </form>
      <FilterOptions
        data={token === null ? filter : filteredFilter}
        loading={loading}
        params={params}
        type={type}
        isChecked={isChecked}
        handleFilter={handleFilter}
        filteredData={filteredData}
        uniqueFilteredCategoryData={uniqueFilteredCategoryData}
        uniqueSearchedCategoryData={uniqueSearchedCategoryData}
      />
    </div>
  )
}

export default CategoriesFilter
