import { useCallback, useContext, useMemo, useState } from 'react'
import { confirmTransactionPin, createWallet } from '../Api/transactionpin.api'
import { toast } from 'react-hot-toast'
import { AxiosError } from 'axios'
import { HandleWalletContext } from '../context/Wallet/handleWallet.context'
import { WalletContext } from '../context/Wallet/wallet.context'

export enum PinStage {
  CREATE_PIN = 'CREATE PIN',
  CONFIRM_PIN = 'CONFIRM PIN',
}

interface IPinInput {
  pin: string
  c_pin: string
}

type IField = 'pin' | 'c_pin'

export interface IPinHandler {
  updateForm: (fieldName: IField, value: string) => void
  form: IPinInput
  stage: PinStage
  verifyPin: (authenticate: () => void) => void,
  createPin: (authenticate: () => void) => void,
  setPinStage: React.Dispatch<React.SetStateAction<PinStage>>
}

export const usePinHandler = (): IPinHandler => {
  const { setPin } = useContext(HandleWalletContext)
  const { setHasWallet } = useContext(WalletContext)
  const [pinStage, setPinStage] = useState<PinStage>(PinStage.CREATE_PIN)
  const [form, setForm] = useState<IPinInput>({
    pin: '',
    c_pin: '',
  })

  const verifyPin = useCallback(
    (authenticate: () => void) => {
      const resp = confirmTransactionPin(form.pin)
      void toast.promise(resp, {
        loading: 'Verifying Please wait...',
        success: () => {
          setPin?.(form.pin)
          authenticate()
          return 'Verification Successful'
        },
        error: (err: AxiosError) => {
          console.log(err.response)
          return 'Invalid Transaction Pin'
        },
      })
    },
    [form]
  )

  const createPin = useCallback(
    (authenticate: () => void) => {
      const resp = createWallet(form.pin)
      void toast.promise(resp, {
        loading: 'Verifying Please wait...',
        success: () => {
          setPin?.(form.pin)
          setHasWallet?.(true)
          authenticate()
          return 'Verification Successful'
        },
        error: (err: AxiosError) => {
          console.log(err.response)
          return 'Invalid Transaction Pin'
        },
      })
    },
    [form]
  )

  const updateForm = useCallback(
    (field: IField, value: string) => {
      setForm((prev) => ({
        ...prev,
        [field]: value,
      }))
    },
    [form]
  )

  return useMemo(
    () => ({
      form,
      updateForm,
      createPin,
      setPinStage,
      verifyPin,
      stage: pinStage,
    }),
    [form, pinStage]
  )
}
