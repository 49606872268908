import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import PinInputView from '../../pages/wallet/PinInputView'
import { IHandleWalletContext } from './interface'

export const HandleWalletContext = createContext<Partial<IHandleWalletContext>>({})

export const HandleWalletProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  let timer: NodeJS.Timeout
  const [isActive, setActive] = useState<boolean>(true)
  const [pin, setPin] = useState<string | null>(null)

  const updatedTimer = useCallback(() => {
    timer = setTimeout(() => {
      setActive(true)
    }, 300000)
  }, [isActive])

  const resetTimer = useCallback(() => {
    clearTimeout(timer)
    updatedTimer()
  }, [isActive])

  const handlePinValidation = useCallback(() => {
    setActive(false)
  }, [isActive])

  useEffect(() => {
    window.addEventListener('mousemove', resetTimer)
    window.addEventListener('click', resetTimer)
    window.addEventListener('keypress', () => resetTimer)
    window.addEventListener('scroll', () => resetTimer)
    updatedTimer()
    return () => {
      clearTimeout(timer)
      window.removeEventListener('mousemove', resetTimer)
      window.removeEventListener('click', resetTimer)
      window.removeEventListener('keypress', () => resetTimer)
      window.removeEventListener('scroll', () => resetTimer)
    }
  }, [isActive])

  return useMemo(
    () => (
      <HandleWalletContext.Provider
        value={{ isActive, handlePinValidation, pin, setPin }}>
        {isActive ? (
          <PinInputView
            handleAuthenticated={handlePinValidation}
          />
        ) : (
          children
        )}
      </HandleWalletContext.Provider>
    ),
    [isActive]
  )
}
