import React from 'react'
import { IAccountView } from './interface'
import BalanceView from './BalanceView'
import TransactionView from './TransactionView'
import useWalletHandler, { WalletSection } from '../../hooks/handleWallet'
import TransferView from './TransferView'

const AccountView: IAccountView = () => {
  const { section, handleWalletSection } = useWalletHandler()

  if (section === WalletSection.WITHDRAW_SECTION) {
    return <TransferView handleSection={handleWalletSection} />
  }

  return (
    <div>
      <BalanceView handleSection={handleWalletSection} />
      <TransactionView />
    </div>
  )
}

export default AccountView
