import React from 'react'
import { useParams } from 'react-router-dom'
import { useCart } from '../../hooks/handleCart'
import Layout from '../../layout'
import Sidebar from '../../layout/Sidebar/Sidebar'
import ShippingForm from './ShippingForm'

interface IParams {
  id?: number
}

const ShippingFormPage: React.FC<{}> = () => {
  const params: IParams = useParams()

  const {
    cartItems,
    loading: cartLoading,
    deleteCartItem,
    updateCartItem,
    totalCost,
  } = useCart()

  return (
    <Layout
      cartItems={cartItems}
      data={cartItems}
      totalCost={totalCost}
      loading={cartLoading}
      updateCartItem={updateCartItem as any}
      deleteCartItem={deleteCartItem}>
      <Sidebar
        title={
          params.id === undefined
            ? 'Add Shipping Address'
            : 'Edit Shipping Address'
        }
        pathname={
          params.id === undefined
            ? 'Add Shipping Address'
            : 'Edit Shipping Address'
        }>
        <div className="bg-gray-50 p-6">
          <h2 className="sm:text-base">
            {params.id === undefined
              ? 'Add Shipping Address'
              : 'Edit Shipping Address'}
          </h2>
        </div>
        <ShippingForm />
      </Sidebar>
    </Layout>
  )
}

export default ShippingFormPage
