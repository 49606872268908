import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import './assets/main.css'
import { Routes } from './routing/route'
import { WalletProvider } from './context/Wallet/wallet.context'

const router = createBrowserRouter(Routes)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <WalletProvider>
      <RouterProvider router={router} />
      <Toaster position="top-right" />
    </WalletProvider>
  </React.StrictMode>
)
