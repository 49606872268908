import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ProductList from '../../component/Products/ProductList'
import { useProductsResult } from '../../hooks/handleProductsCategory'
import Layout from '../../layout'
import Filter from '../../layout/Filters/Filter'
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/solid'
import Pagination from '../../layout/Pagination'

const sortOptions = [
  { name: 'Relevant', href: '#', current: true },
  { name: 'Composition (A - Z)', href: '#', current: false },
  { name: 'Composition (Z - A)', href: '#', current: false },
  { name: 'Newest Products', href: '#', current: false },
  { name: 'Quantity: Low to High', href: '#', current: false },
  { name: 'Quantity: High to Low', href: '#', current: false },
  { name: 'Name (A - Z)', href: '#', current: false },
  { name: 'Name (Z - A)', href: '#', current: false },
  { name: 'Price: Low to High', href: '#', current: false },
  { name: 'Price: High to Low', href: '#', current: false },
]

const BASE_URL = process.env.REACT_APP_BASE_URL

const CategoryPage: React.FC<{}> = () => {
  const params = useParams()
  const {
    loading,
    data,
    filter,
    pageInfo,
    handleFilter,
    addToCart,
    deleteCartItem,
    updateCartItem,
    checked,
    totalCost,
    cartLoading,
    cartItems,
    goToNextPage,
    goToPreviousPage,
    totalPages,
    brand,
  } = useProductsResult({
    params: params.slug as string,
    type: 'category',
  })

  const initialSortOption =
    sortOptions.find((option) => option.current) != null || sortOptions[0]
  const [selectedSortOption, setSelectedSortOption] =
    useState<any>(initialSortOption)
  const [filteredData, setFilteredData] = useState([])
  const [isGridNum, setIsGridNum] = useState(false)
  const [categoryList, setCategoryList] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<any>()
  const [fullManufacturerList, setFullManufacturerList] = useState<any>({})

  const [textSearchedManufacturer, setTextSearchedManufacturer] =
    useState<string>('')
  const [textSearchedComposition, setTextSearchedComposition] =
    useState<string>('')
  const handleTextManufacturerChange = (e: any): any => {
    setTextSearchedManufacturer(e.target.value)
  }

  const handleTextCompositionChange = (e: any): any => {
    setTextSearchedComposition(e.target.value)
  }

  const handleManufacturerSubmit = (e: any): any => {
    e.preventDefault()
    setTextSearchedManufacturer('')
  }
  const handleCompositionSubmit = (e: any): any => {
    e.preventDefault()
    setTextSearchedComposition('')
  }

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const currentPage = queryParams.get('page') ?? 1

  useEffect(() => {
    if (categoryList?.results?.length > 0) {
      const sortedData: any = [...categoryList?.results]

      switch (selectedSortOption.name) {
        case 'Relevant':
          sortedData.map((item: any) => item.name)
          break
        case 'Composition (A - Z)':
          sortedData.sort((a: any, b: any) => {
            const compositionA = a.composition.join(', ');
            const compositionB = b.composition.join(', ');
          
            return compositionA.localeCompare(compositionB);
          });
          break;
          
        case 'Composition (Z - A)':
          sortedData.sort((a: any, b: any) => {
            const compositionA = a.composition.join(', ');
            const compositionB = b.composition.join(', ');
          
            return compositionB.localeCompare(compositionA);
          });
          break;
        case 'Name (Z - A)':
          sortedData.sort((a: any, b: any) => b.name.localeCompare(a.name))
          break
        case 'Newest Products':
          sortedData.sort(
            (a: any, b: any) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          break
        case 'Quantity: Low to High':
          sortedData.sort((a: any, b: any) => a.quantity - b.quantity)
          break
        case 'Quantity: High to Low':
          sortedData.sort((a: any, b: any) => b.quantity - a.quantity)
          break
        case 'Price: Low to High':
          sortedData.sort((a: any, b: any) => a.price - b.price)
          break
        case 'Price: High to Low':
          sortedData.sort((a: any, b: any) => b.price - a.price)
          break
        default:
          break
      }

      setFilteredData(sortedData)
    }
  }, [selectedSortOption, categoryList?.results])

  const handleGridSystem = (): any => {
    setIsGridNum((prev) => {
      return !prev
    })
  }

  const getAllCategories = async (): Promise<void> => {
    try {
      const isAuthenticated = !(localStorage.getItem('token') == null)

      const headers: HeadersInit = isAuthenticated
        ? {
            'Content-Type': 'application/json',
            Authorization: `token ${localStorage.getItem('token') ?? ''}`,
          }
        : {
            'Content-Type': 'application/json',
          }

      const response = await fetch(
        `${BASE_URL as string}/inventory/products/category/${
          params.slug as string
        }?page=${currentPage}`,
        {
          headers,
        }
      )

      const json = await response.json()
      setCategoryList(json)
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void getAllCategories()
  }, [currentPage])

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handlePageClick = (page: number): any => {
    navigate(`${pathname}?page=${page}`)
    window.scrollTo({ top: 274, left: 0, behavior: "smooth" });
  }

  // FIltered Manufacturers
  const transformedData = filteredData?.map((item: any) => ({
    // value: decodeURIComponent(item.name.toLowerCase().replaceAll(' ', '-').replaceAll("''", '')),
    value: item.id,
    label: item.manufacturer,
    checked: false,
    params: 'manufacturer',
  }))

  const filteredTransformedData = transformedData?.filter(
    (item: any) => item.label !== null
  )
  const uniqueLabels = new Set()
  const uniqueFilteredManufacturerData = filteredTransformedData?.filter(
    (item: any) => {
      if (!uniqueLabels.has(item.label)) {
        uniqueLabels.add(item.label)
        return true
      }
      return false
    }
  )

  // Filtered Composition
  const transformedCompositions = filteredData?.map((item: any) => ({
    value: decodeURIComponent(
      item.name.toLowerCase().replaceAll(' ', '-').replaceAll("''", '')
    ),
    label: item.composition,
    checked: false,
    params: 'composition',
  }))
  const filteredTransformedCompositions = transformedCompositions?.filter(
    (item: any) => item.label !== null
  )
  const uniqueLabels2 = new Set()
  const uniqueFilteredData = filteredTransformedCompositions?.filter(
    (item: any) => {
      if (!uniqueLabels2.has(item.label)) {
        uniqueLabels2.add(item.label)
        return true
      }
      return false
    }
  )

  let uniqueFilteredCompositionsData = uniqueFilteredData.flatMap((obj) => {
    return obj.label.map((composition: any) => {
      return {
        value: obj.value,
        label: composition,
        checked: obj.checked,
        params: obj.params,
      };
    });
  });  

  uniqueFilteredData.map((obj) => {
    return {
      ...obj,
      label: obj.label,
    }
  })

  uniqueFilteredCompositionsData = uniqueFilteredCompositionsData.filter(
    (item: any) => item.label !== undefined
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fullCompositionList, setFullCompositionList] = useState<any>([])
  const [isLoading, setisLoading] = useState<any>(true)

  const getAllPages = async (apiEndpoint: any): Promise<any> => {
    let page = 1
    const resultList = []

    while (true) {
      const url = `${apiEndpoint as string}?page=${page}`

      try {
        const response = await fetch(url)
        const newData = await response.json()

        if (!response.ok) {
          throw new Error(`Error fetching paginated data at page ${page}`)
        }

        if (Boolean(newData?.results) && newData.results.length > 0  && newData?.next !== null) {
          resultList.push(...newData.results)
          page++
        } else {
          break
        }
      } catch (e: any) {
        setError(e.message)
        throw e
      }
    }

    return resultList
  }

  useEffect(() => {
    const apiEndpoint = `${BASE_URL as string}/inventory/compositions`

    getAllPages(apiEndpoint)
      .then((result) => {
        setFullCompositionList(result)
        setisLoading(false)
      })
      .catch(() => {
        setError('Error fetching paginated data')
        setisLoading(false)
      })
  }, [])

  const getAllManufacturerPages = async (apiEndpoint: string): Promise<any> => {
    let page = 1
    const resultList = []

    while (true) {
      const url = `${apiEndpoint}?page=${page}`

      try {
        const response = await fetch(url)
        const newData = await response.json()
        if (!response.ok) {
          throw new Error(`Error fetching paginated data at page ${page}`)
        }

        if (Boolean(newData?.results) && newData.results.length > 0 && newData?.next !== null) {
          resultList.push(...newData.results)
          page++
        } else {
          break
        }
      } catch (e: any) {
        setError(e.message)
        // throw e
      }
    }

    return resultList
  }

  useEffect(() => {
    const apiEndpoint = `${BASE_URL as string}/inventory/manufacturer/all`

    getAllManufacturerPages(apiEndpoint)
      .then((result) => {
        setFullManufacturerList(result)
        setisLoading(false)
      })
      .catch((e) => {
        setError(e)
        setisLoading(false)
      })
  }, [])

  // update category data
  const updatedManufacturerData = uniqueFilteredManufacturerData.map((item) => {
    const matchingManufacturer = fullManufacturerList.results?.find(
      (manufacturer: any) => manufacturer.name === item.label
    )
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (matchingManufacturer) {
      return {
        ...item,
        value: matchingManufacturer.id,
      }
    }
    return item
  })

  const updatedCompositionData = uniqueFilteredCompositionsData.map((item) => {
    const matchingComposition = fullCompositionList?.find(
      (composition: any) => composition.name === item.label
    )
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (matchingComposition) {
      return {
        ...item,
        value: matchingComposition.id,
      }
    }
    return item
  })

  return (
    <Layout
      cartItems={cartItems}
      data={cartItems}
      totalCost={totalCost}
      loading={cartLoading}
      updateCartItem={updateCartItem as any}
      deleteCartItem={deleteCartItem}>
      <Filter
        title={pageInfo.title}
        subtitle={pageInfo.count.toString()}
        params={params.slug as string}
        filterData={filter}
        data={data}
        uniqueFilteredManufacturerData={updatedManufacturerData}
        uniqueFilteredCompositionsData={updatedCompositionData}
        currentPage={currentPage}
        products={categoryList}
        brand={brand}
        handleTextManufacturerChange={handleTextManufacturerChange}
        isChecked={checked}
        handleTextCompositionChange={handleTextCompositionChange}
        handleManufacturerSubmit={handleManufacturerSubmit}
        handleCompositionSubmit={handleCompositionSubmit}
        setSelectedSortOption={setSelectedSortOption}
        filteredData={filteredData}
        textSearchedComposition={textSearchedComposition}
        textSearchedManufacturer={textSearchedManufacturer}
        type="category"
        handleGridSystem={handleGridSystem}
        handleFilter={handleFilter}>
        <div className="rounded-md bg-white">
          <div className="lg:no-scrollbar mx-auto max-w-2xl p-4 sm:px-6 lg:max-h-[796px] lg:max-w-7xl lg:overflow-y-auto lg:px-8">
            <ProductList
              data={filteredData}
              loading={loading ?? isLoading}
              isGridNum={isGridNum}
              grid={!isGridNum ? 3 : 1}
              addToCart={addToCart}
              goToNextPage={goToNextPage}
              goToPreviousPage={goToPreviousPage}
              currentPage={currentPage}
              textSearchedManufacturer={textSearchedManufacturer}
              textSearchedComposition={textSearchedComposition}
              totalPages={totalPages}
              products={categoryList}
              ITEMS_PER_PAGE={20}
              handlePageClick={handlePageClick}
              previousArrow={ArrowLeftCircleIcon}
              nextArrow={ArrowRightCircleIcon}
              TOTAL_ITEMS={categoryList?.count}
              deleteCartItem={deleteCartItem}
              updateCartItem={updateCartItem}
              cartLoading={cartLoading}
              setSelectedSortOption={setSelectedSortOption}
              filteredData={filteredData}
              filterData={filter}></ProductList>
          </div>
        </div>
      </Filter>
      <div className="relative bottom-20 hidden lg:block">
        {categoryList?.count < 21 ? null : (
          <Pagination
            ITEMS_PER_PAGE={20}
            handlePageClick={handlePageClick}
            previousArrow={ArrowLeftCircleIcon}
            nextArrow={ArrowRightCircleIcon}
            TOTAL_ITEMS={categoryList?.count}
          />
        )}
      </div>
    </Layout>
  )
}

export default CategoryPage
