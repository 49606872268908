/* eslint-disable linebreak-style */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { userWallet } from '../../../Api/drugstocPay.api'
import useSWR from 'swr'

const ToastNoWallet = ({ handleAuthenticated }: any): JSX.Element => {
  const [popupModal, setPopupModal] = useState<any>(true)
  const { data: payData } = useSWR(
    '/drugstocpay/wallet/account',
    userWallet
  )

  const closeModal = (e: any): any => {
    if (e.target === e.currentTarget) {
      setPopupModal(false)
    }
  }
  
  if (payData?.transaction_pin === '' && payData?.currency === null) {
    return (
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      popupModal && (
        <div className="fixed top-96 left-1/2 -translate-x-1/2 transform z-10 rounded-t-lg bg-white p-4 shadow-lg">
          <div className="mb-2 text-xl font-semibold">
            Create a Wallet Account
          </div>
          <div className="text-sm">
            Create your{' '}
            <Link to="/wallet" className="text-blue-500">
              Wallet account
            </Link>
          </div>
          <button
            onClick={closeModal}
            className="mt-2 text-sm text-blue-500 hover:underline focus:outline-none">
            Dismiss
          </button>
        </div>
      )
    )
  }
  return <div>{null}</div>
}

export default ToastNoWallet
