/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useProducts } from '../../hooks/handleProducts'
import Layout from '../../layout'
import Products from '../../component/Products/Product'
import ProductsLoader from '../../component/Products/ProductsLoader'
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/solid'
import Pagination from '../../layout/Pagination'

const BASE_URL = process.env.REACT_APP_BASE_URL
const MAX_ITEMS_PER_PAGE = 20

const ProductsDetailPage: React.FC<{}> = () => {
  const param: any = useParams()
  const {
    data,
    loading,
    cartItems,
    cartLoading,
    deleteCartItem,
    addToCart,
    totalCost,
    updateCartItem,
  } = useProducts(param)
  const Formatter = useCallback((amount: number): string => {
    return amount?.toFixed().replace(/./g, function (c, i, a) {
      return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
    })
  }, [])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cart, setCart] = useState(false)
  const token = localStorage.getItem('token')
  const [showTooltip, setShowTooltip] = useState(false)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const currentPage = queryParams.get('page') ?? 1

  const handleAddToCart = (): any => {
    const productId = data?.id ?? 0
    if (data?.quantity === 0) {
      setShowTooltip(true)
      setTimeout(() => {
        setShowTooltip(false)
      }, 5000)
      return
    }
    addToCart({ product_id: productId, quantity: 1 })
    if (token === null) {
      setCart(false)
    } else {
      setCart(true)
    }
  }

  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isZoomed, setIsZoomed] = useState(false)

  const handleMouseMove = (e: any): any => {
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = (e.clientX - left) / width
    const y = (e.clientY - top) / height

    setPosition({ x, y })
  }

  const handleMouseEnter = (): void => {
    setIsZoomed(true)
  }

  const handleMouseLeave = (): void => {
    setIsZoomed(false)
  }

  const zoomImage = {
    transition: 'transform 0.3s ease-in-out',
    display: 'block',
    width: '100%',
  }

  const zoomStyle = {
    transform: isZoomed ? `scale(${2.0})` : 'scale(1)',
    transformOrigin: `${position.x * 100}% ${position.y * 100}%`,
    ...zoomImage,
  }

  const [alternativeProducts, setAlternativeProducts] = useState<any>(null)
  const [error, setError] = useState<null | string>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    void getAllAlternativeProducts()
  }, [currentPage, data])

  const getAllAlternativeProducts = async (): Promise<void> => {
    setIsLoading(false)
    try {
      const headers: HeadersInit =
        localStorage.getItem('token') === null
          ? {
              'Content-Type': 'application/json',
            }
          : {
              'Content-Type': 'application/json',
              Authorization: `token ${localStorage.getItem('token') ?? ''}`,
            }
      let response;
      if (data !== undefined) {
        response = await fetch(
          `${BASE_URL as string}/inventory/products/${
            data?.slug
          }/alternative?page=${currentPage}`,
          {
            headers,
          }
        )
      }
      
      if (!((response?.ok) ?? false)) {
        throw new Error('Refresh Page')
      } else {
        const json = await response?.json()
        setAlternativeProducts(json)
      }
    } catch (e: any) {
      setError(e.message)
    } finally {
      setIsLoading(true)
    }
  }

  const alternatives: any =
    alternativeProducts !== null ? alternativeProducts?.results : null

  const loadingData = (): any[] => {
    const arr: any[] = []
    arr.length = 50
    return [
      1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
      1, 1, 1,
    ]
  }

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [height, setHeight] = useState(0)

  const detailDivRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (detailDivRef.current != null) {
      const divHeight: any = detailDivRef.current.clientHeight
      setHeight(divHeight)
    }
  }, [data])

  const newHeight = height + 154

  const handlePageClick = (page: number): any => {
    navigate(`${pathname}?page=${page}`)
    window.scrollTo({ top: newHeight, left: 0, behavior: 'smooth' });
  }

  return (
    <Layout
      cartItems={cartItems}
      data={cartItems}
      totalCost={totalCost}
      loading={cartLoading}
      updateCartItem={updateCartItem as any}
      deleteCartItem={deleteCartItem}>
      <div className="bg-gray-100 pb-10">
        <main className="mx-auto max-w-7xl rounded-b-xl bg-white px-4 sm:px-6 lg:px-8">
          <nav className="my-6 flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
              <li className="inline-flex items-center">
                <a
                  href="/"
                  className="inline-flex items-center text-sm font-normal text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                  Home
                </a>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    aria-hidden="true"
                    className="h-6 w-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"></path>
                  </svg>
                  <a
                    href={`/product/${data?.slug}`}
                    className="ml-1 text-sm font-normal text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white md:ml-2">
                    {data?.category}
                  </a>
                </div>
              </li>
              <li aria-current="page">
                <div className="flex items-center">
                  <svg
                    aria-hidden="true"
                    className="h-6 w-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"></path>
                  </svg>
                  <span className="ml-1 text-sm font-normal text-gray-500 dark:text-gray-400 md:ml-2">
                    {data?.name}
                  </span>
                </div>
              </li>
            </ol>
          </nav>
          {loading ? (
            <div
              style={{
                height: '70vh',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div className="flex items-center justify-center">
                <div className="h-12 w-12 animate-spin rounded-full border-t-4 border-blue-500 border-t-blue-500"></div>
              </div>
            </div>
          ) : (
            <div className="grid gap-x-8 gap-y-10 md:grid-cols-8">
              <div className="col-span-4 sm:col-span-6 lg:col-span-4">
                <div
                  className="zoom-container mb-10 mt-4 h-[25rem] rounded-2xl border p-2"
                  onMouseMove={handleMouseMove}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}>
                  <img
                    src={data?.image}
                    className="h-full w-full items-center object-contain"
                    alt={data?.name}
                    style={{
                      ...zoomStyle,
                      transition: 'transform 0.3s ease-in-out',
                    }}
                  />
                </div>
              </div>
              <div ref={detailDivRef} className="col-span-4 sm:col-span-6 lg:col-span-4">
                <div className="mb-10 mt-4 p-2">
                  {(data?.quantity as number) > 0 ? (
                    <span className="mr-2 rounded bg-blue-100 px-3.5 py-1.5 text-base font-medium text-blue-800 dark:bg-blue-300 dark:text-blue-900">
                      Express Shipping
                    </span>
                  ) : (
                    <span className="mr-2 rounded bg-amber-100 px-3.5 py-1.5 text-base font-medium text-amber-600">
                      Available on request
                    </span>
                  )}
                  <p className="mt-4 text-sm text-gray-700">{data?.SKU}</p>
                  <h2 className="mb-4 mt-2 text-3xl font-medium text-gray-700">
                    {data?.name}
                  </h2>

                  {/* Therapeutic Class */}
                  {data?.therapeutic.length === 0 ? null : (
                    <div style={{ marginBottom: 12 }}>
                      <h3 style={{ color: '#939094', fontWeight: 600 }}>
                        Therapeutic class:
                      </h3>
                      {data?.therapeutic.map((item: any) => {
                        return (
                          <div key={item}>
                            <p style={{ color: '#939094' }}>- {item}</p>
                          </div>
                        )
                      })}
                    </div>
                  )}

                  {/* Side Effect */}
                  {data?.side_effects === null ||
                  data?.side_effects.trim() === '' ? null : (
                    <div style={{ marginBottom: 12 }}>
                      <h3 style={{ color: '#939094', fontWeight: 600 }}>
                        Side Effect:
                      </h3>
                      <p style={{ color: '#939094' }}>{data?.side_effects}</p>
                    </div>
                    )}

                  {/* Contra-Indication */}
                  {data?.contra_indications === null ||
                  data?.contra_indications.trim() === '' ? null : (
                    <div style={{ marginBottom: 12 }}>
                      <h3 style={{ color: '#939094', fontWeight: 600 }}>
                        Contra-Indication:
                      </h3>
                      <p style={{ color: '#939094' }}>
                        {data?.contra_indications}
                      </p>
                    </div>
                    )}

                  {/* Drug Interaction: */}
                  {data?.drug_interactions === null ||
                  data?.drug_interactions.trim() === '' ? null : (
                    <div>
                      <h3 style={{ color: '#939094', fontWeight: 600 }}>
                        Drug interaction:
                      </h3>
                      <p style={{ color: '#939094' }}>
                        {data?.drug_interactions}
                      </p>
                    </div>
                    )}

                  <p className="text-sm text-gray-700">{data?.category}</p>
                  {data?.price !== undefined ? (
                    <p className="mb-4 mt-6 text-4xl font-semibold text-gray-700">
                      &#8358;{Formatter(data?.price)}
                    </p>
                  ) : (
                    <div className="my-5">
                      <span className="mr-2 flex w-fit items-center rounded bg-amber-100 px-3.5 py-1.5 text-base font-medium text-amber-600">
                        <ExclamationTriangleIcon className="mr-2 h-5 w-5" />
                        Price not available
                      </span>
                    </div>
                  )}
                  <div className="flex space-x-4 md:w-2/3">
                    <input
                      type="number"
                      defaultValue={1}
                      disabled={data?.quantity === 0}
                      className="block w-36 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-center text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="1"
                    />
                    <button
                      type="button"
                      // disabled={data?.quantity === 0}
                      onClick={handleAddToCart}
                      className="inline-flex items-center rounded-md bg-[#3b82f7] px-5 py-2.5 text-center text-lg font-medium text-white hover:bg-[#3b82f7] disabled:bg-[#6da2f8]">
                      <svg
                        aria-hidden="true"
                        className="-ml-1 mr-2 h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
                      </svg>
                      Add to Cart
                    </button>
                    {showTooltip && (
                      <div className="absolute left-1/2 top-[90px] -translate-x-1/2 transform rounded-md bg-red-500 p-2 text-sm text-white">
                        Call customer care or sales rep to assist with the order
                        of this product of this product
                      </div>
                    )}
                  </div>
                  <p className="mt-6 text-sm text-gray-700">
                    {typeof data?.manufacturer !== 'string' ? null : (
                      <span className="font-semibold text-[#3b82f7]">
                        by {data?.manufacturer}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}

          {error !== null && !isLoading ? (
            <>
              <p className="my-8 text-center text-2xl font-medium text-gray-600">
                Alternative Products
              </p>

              <a
                href={`${data?.slug}`}
                className="relative top-2 mx-auto flex w-[200px] justify-center rounded bg-blue-600 py-2 text-center text-white">
                {error}
              </a>

              <div
                className={`mt-7 grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-8`}>
                {loadingData().map((_element: any, index: number) => (
                  <ProductsLoader key={index} />
                ))}
              </div>
            </>
          ) : alternatives?.length === 0 ? null : (
            <div className="rounded-md pb-10">
              <p className="my-8 text-center text-2xl font-medium text-gray-600">
                Alternative Products
              </p>

              <div
                className={`grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-${3} lg:grid-cols-${4} xl:gap-x-8`}>
                {alternatives?.length > 0 &&
                  isLoading &&
                  alternatives?.map((value: any, index: number) => {
                    return (
                      <Products
                        key={`${value.id}-${index}`}
                        image={value.image}
                        name={value.name}
                        price={value.price}
                        manufacturer={value.manufacturer}
                        id={value.id}
                        in_cart={value.in_cart}
                        category={value.category}
                        composition={value.composition}
                        desc={value.desc}
                        SKU={value.SKU}
                        slug={value.slug}
                        quantity={value.quantity}
                        quantity_in_cart={value.quantity_in_cart}
                        addToCart={addToCart}
                        deleteCartItem={deleteCartItem}
                        updateCartItem={updateCartItem}
                        loading={cartLoading}
                        side_effects={value.side_effects}
                        contra_indications={value.contra_indications}
                        drug_interactions={value.drug_interactions}
                        therapeutic={value.therapeutics}
                      />
                    )
                  })}
              </div>
              {alternativeProducts?.count < MAX_ITEMS_PER_PAGE + 1 ? null : (
                <Pagination
                  ITEMS_PER_PAGE={MAX_ITEMS_PER_PAGE}
                  handlePageClick={handlePageClick}
                  previousArrow={ArrowLeftCircleIcon}
                  nextArrow={ArrowRightCircleIcon}
                  TOTAL_ITEMS={alternativeProducts?.count}
                />
              )}
            </div>
          )}
        </main>
      </div>
    </Layout>
  )
}

export default ProductsDetailPage
