/* eslint-disable react/no-unescaped-entities */
import { MagnifyingGlassIcon, MicrophoneIcon } from '@heroicons/react/20/solid'
import React, { ChangeEvent, useState } from 'react'
import CartList from '../../component/Cart/CartList'
import PaymentMethod from '../../component/PaymentMethod/PaymentMethod'
import ShippingSelect from '../../component/Shipping/ShippingSelect'
import { useOrder } from '../../hooks/handeCheckout'
import Layout from '../../layout'
import { Formatter } from '../../utilities/utils'
// import ModalPaySmallSmall from './ModalPaySmallSmall'
import { useNavigate } from 'react-router-dom'
import ToastNoWallet from '../Home/Modal/ModalNonWallet'
import { userBusiness } from '../../Api/business.api'
import useSWR from 'swr'
import { useShipping } from '../../hooks/handleShipping'
import ShippingForm from '../Shipping/ShippingForm'

const CheckoutPage: React.FC<{}> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isWallet, setIsWallet] = useState(false)

  const { data: businessDetail } = useSWR('/business/profile', userBusiness)
  const [shouldModal, setShouldModal] = useState(false)

  const navigate = useNavigate()
  const isVerified = businessDetail?.is_verified
  const { data: shipping } = useShipping(undefined)

  const navigateLicence = (): any => {
    navigate('/document')
  }

  const handleFormModal = (): any => {
    setShouldModal(true)
  }

  const checkoutBlur = isVerified === false && (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-lg">
      <div className="rounded-lg bg-white p-8 shadow-lg">
        <p>Upload your license to easily checkout</p>
        <button
          onClick={navigateLicence}
          className="mt-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600">
          Upload Licence
        </button>
      </div>
    </div>
  )

  const {
    canProceed,
    form,
    updateForm,
    createOrder,
    // isModalOpen,
    // setIsModalOpen,
    setDefaultAddress,
    cartItems,
    loading,
    updateCartItem,
    deleteCartItem,
    searchCart,
    totalCost,
  } = useOrder()

  const handleDataChange = (e: any): any => {
    createOrder(form, totalCost)
  }

  return (
    <Layout
      cartItems={cartItems}
      data={cartItems}
      totalCost={totalCost}
      loading={loading}
      updateCartItem={updateCartItem as any}
      deleteCartItem={deleteCartItem}>
      <div className="bg-gray-100 pb-5">
        {/* {isModalOpen && <ModalPaySmallSmall totalCost={totalCost} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />} */}
        {!isWallet && <ToastNoWallet />}
        {shouldModal ? <ShippingForm shouldModal={shouldModal} /> : null}
        {/* <OrderForm /> */}
        {checkoutBlur}
        {shouldModal ? null : (
          <div className="mx-auto max-w-7xl pb-24 pt-6">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-9">
              <div className="rounded-md bg-white p-6 md:col-span-6">
                <div className="border-gray-200">
                  <dl>
                    <div className="border-b bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-base font-semibold text-gray-700">
                        <span className="mr-4">1</span> Delivery Address
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <ShippingSelect
                          setDefaultAddress={setDefaultAddress}
                          value={form.shipping}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            updateForm('shipping', e)
                          }
                        />
                      </dd>
                    </div>
                    <div className="border-b bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-base font-semibold text-gray-700">
                        <span className="mr-4">2</span> Payment Method
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <PaymentMethod
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            updateForm('payment_method', e)
                          }}
                        />
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-base font-semibold text-gray-700">
                        <span className="mr-4">3</span> Review items
                      </dt>
                      <dd className="col-span-3 mt-1 rounded-lg border p-4 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                        <form className="mb-4 flex items-center">
                          <div className="relative w-full">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <MagnifyingGlassIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                            </div>
                            <input
                              type="text"
                              id="voice-search"
                              onChange={searchCart}
                              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                              placeholder="Search Your Cart"
                              required
                            />
                            <button
                              type="button"
                              className="absolute inset-y-0 right-0 flex items-center pr-3">
                              <MicrophoneIcon className="h-4 w-4 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" />
                            </button>
                          </div>
                        </form>
                        <CartList
                          cartItems={cartItems}
                          loading={loading}
                          data={cartItems}
                          updateCartItem={updateCartItem}
                          deleteCartItem={deleteCartItem}
                        />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              {isVerified === true ? (
                <div className="h-fit rounded-md bg-white p-6 md:col-span-3">
                  <button
                    type="button"
                    title={shipping?.length === 0 ? 'You are not able to create Order, please add your shipping address to continue.' : 'Create your Order'}
                    disabled={shipping?.length === 0 ? false : !canProceed}
                    onClick={
                      shipping?.length === 0
                        ? handleFormModal
                        : (e) => handleDataChange(e)
                    }
                    className="w-full items-center  rounded-md bg-[#3b82f7] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#3b82f7] disabled:bg-[#6da2f8] disabled:opacity-60">
                    {shipping?.length === 0
                      ? 'Add Shipping Address'
                      : 'Submit your Order'}
                  </button>
                  <p className="mt-3 text-center text-xs text-gray-500">
                    By placing your order you agree to DrugStoc's Conditions of
                    Use & Sale. Please see our{' '}
                    <a href="https://drugstoc.com/privacy" target='_blank' rel="noreferrer" className="text-[#3b82f7]">
                      Privacy Notice
                    </a>
                    .
                  </p>
                  <div className="my-2 border-b border-t">
                    <h2 className="my-2 text-lg font-semibold">
                      Order Summary
                    </h2>
                    <div className="flex justify-between">
                      <p className="text-center text-sm text-gray-700">Items</p>
                      <p className="text-center text-sm text-gray-700">
                        &#8358; {Formatter(totalCost)}
                      </p>
                    </div>
                    <div className="mb-2 mt-1 flex justify-between">
                      <p className="text-center text-sm text-gray-700">
                        Postage & Packing:
                      </p>
                      <p className="text-center text-sm text-gray-700">
                        &#8358; {Formatter(0)}
                      </p>
                    </div>
                  </div>
                  <div className="my-2 border-b">
                    <div className="flex justify-between">
                      <h2 className="my-2 text-lg font-semibold">
                        Order Total:
                      </h2>
                      <p className="my-2 text-lg font-semibold">
                        &#8358; {Formatter(totalCost)}
                      </p>
                    </div>
                    <div className="mb-2 flex justify-between">
                      <p className="text-center text-sm text-gray-700">
                        Order Totals include VAT.
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default CheckoutPage
