import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef } from 'react'
import { IModal } from './interface'
import Logo from '../../assets/img/logo.png'
import {
  ArrowSmallRightIcon,
  MagnifyingGlassIcon,
  MicrophoneIcon,
} from '@heroicons/react/20/solid'
import { useSearch } from '../../hooks/handleSearch'
import imgState from '../../assets/img/medicine.png'

const SearchModal: React.FC<IModal> = ({ open, setOpen }) => {
  const cancelButtonRef = useRef(null)
  const { search, result, query } = useSearch()

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="relative flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative -top-[0rem] transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl md:-top-[10%] md:bottom-10">
                <div className="overflow-hidden bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 h-[70vh] no-scrollbar w-full overflow-y-auto text-center sm:mt-0 sm:text-left md:h-[25rem]">
                      <Dialog.Title as="form" className="flex items-center">
                        <div className="relative w-full">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                          </div>
                          <input
                            type="text"
                            autoComplete="Off"
                            id="voice-search"
                            value={query}
                            onChange={search}
                            className="block w-full rounded-lg border-2 bg-white p-2.5  pl-10 text-sm text-gray-900 focus:border-blue-500 focus:outline-none focus:ring-blue-500 focus-visible:border-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            placeholder="Search anything on DrugStoc..."
                            required
                          />
                          <button
                            type="button"
                            className="absolute inset-y-0 right-0 flex items-center pr-3">
                            <MicrophoneIcon className="h-4 w-4 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" />
                          </button>
                        </div>
                      </Dialog.Title>
                      {result?.length === 0 || query?.length === 0 ? (
                        <div className="mt-2 flex h-[150] items-center">
                          <div className="flex flex-col items-center justify-center w-full mt-5">
                            <img src={imgState} className="w-12 h-12" alt="Search Icon" />
                            <p className="text-sm text-gray-500 mt-2">
                              No search result yet. Type in the product name to see related results
                            </p>
                          </div>

                        </div>
                      ) : query?.length > 0 ? (
                        <div className="no-scrollbar mt-2 h-[90%] overflow-scroll">
                          {result.map((e, i) => (
                            <a
                              key={`${e.id}-${e.slug}-${i}`}
                              href={`/product/${e.slug}`}
                              className="my-4 flex w-full items-center justify-between rounded-3xl bg-slate-50 p-2 hover:bg-slate-100">
                              <div className="flex  cursor-pointer items-center space-x-5">
                                <img
                                  src={e.image}
                                  className="h-20 w-20 rounded-full object-contain"
                                  alt={e.name}
                                />
                                <div>
                                  <a href={`/product/${e.slug}`} className="text-base font-semibold text-gray-500">
                                    {e.name}
                                  </a>
                                  {
                                    typeof e.manufacturer !== "string" ? <p className="my-1 text-xs text-blue-500">
                                  </p> : <p className="my-1 text-xs text-blue-500">
                                    by {e.manufacturer}
                                  </p>
                                  }
                                
                                </div>
                              </div>
                              <ArrowSmallRightIcon className="h-6 text-gray-500" />
                            </a>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row-reverse items-center bg-gray-50 px-4 py-3 sm:px-6">
                  <img className="h-6 w-auto" src={Logo} alt="" />
                  <p className="mr-2 text-xs text-gray-500">Search By</p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SearchModal
