/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react'
import { ITransferView } from './interface'
import useWalletHandler, {
  IBanks,
  WalletSection,
} from '../../hooks/handleWallet'
import { ArrowLeftIcon } from '@heroicons/react/20/solid'

const TransferView: ITransferView = ({ handleSection }) => {
  const { banks, form, updateForm, transferFund, updateFormData } = useWalletHandler()
  return (
    <div>
      <div>
        <button
          type="button"
          className="inline-flex items-center font-medium text-[#3b82f7] hover:text-[#3b82f7]"
          onClick={() => handleSection(WalletSection.ACCOUNT_SECTION)}>
          <ArrowLeftIcon className="-ml-2 mr-0 h-5 w-7" />
          Back
        </button>
      </div>
      <div className="mt-8 space-y-5">
        <div className="pb-4">
          <p className="text-center text-2xl font-bold">New Fund Withdrawal</p>
          <p className="text-center text-xs text-gray-400">
            Instant Fund Withdrawal: Access your money quickly and conveniently!
          </p>
        </div>
        <div className="sm:col-span-3">
          <label className="form-label">Beneficiary Bank</label>
          <select
            className="form-select"
            defaultValue={form.bank_code}
            onChange={(e) => {
              updateForm('bank_code', e)
              updateFormData('bank_name', banks[e.target.selectedIndex - 1].bank_name)
            }}>
            <option selected disabled value="">
              Choose Receipent Bank
            </option>
            {banks.map((data: IBanks, index: number) => (
              <option key={index} value={data.bank_code}>
                {data.bank_name}
              </option>
            ))}
          </select>
        </div>
        <div className="sm:col-span-3">
          <label className="form-label">Account Number</label>
          <input
            type="text"
            value={form.account_no}
            onChange={(e) => updateForm('account_no', e)}
            className="form-select"
            placeholder="Account Number"
          />
          {form.account_name !== '' ? <p className='text-gray-500 text-sm px-3 mt-1'>{form.account_name}</p> : null}
        </div>
        <div className="sm:col-span-3">
          <label className="form-label">Amount</label>
          <input
            type="text"
            value={form.amount}
            onChange={(e) => updateForm('amount', e)}
            className="form-select"
            placeholder="NGN1,000.00"
          />
        </div>
        <div className="sm:col-span-3">
          <label className="form-label">Purpose</label>
          <textarea
            rows={6}
            className="form-select"
            value={form.remark}
            onChange={(e) => updateForm('remark', e)}
            placeholder="Add reason for transfer"></textarea>
        </div>
        <button type="button" onClick={transferFund} className="base-button">
          Withdraw
        </button>
      </div>
    </div>
  )
}

export default TransferView
