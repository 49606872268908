import React from 'react'
import { useParams } from 'react-router-dom'
import { useShipping } from '../../hooks/handleShipping'
import Layout from '../../layout'
import Logo from '../../assets/img/logo.png'
import Sidebar from '../../layout/Sidebar/Sidebar'
import { useCart } from '../../hooks/handleCart'

const ShippingPage: React.FC<{}> = () => {
  const params: any = useParams()
  const { data, loading, removeShippingAddress, makeDefaultShippingAddress } =
    useShipping(params.id)

  const {
    cartItems,
    loading: cartLoading,
    deleteCartItem,
    updateCartItem,
    totalCost,
  } = useCart()

  return (
    <Layout
      cartItems={cartItems}
      data={cartItems}
      totalCost={totalCost}
      loading={cartLoading}
      updateCartItem={updateCartItem as any}
      deleteCartItem={deleteCartItem}>
      <Sidebar title="Shipping Address" pathname="Shipping Address">
        <div className="bg-gray-50 p-6">
          <h2 className="sm:text-base">Shipping Address</h2>
        </div>
        <div className="p-4">
          <div className="mb-4 grid grid-cols-2 gap-4 md:grid-cols-3">
            <a
              href="/shipping-address/add-shipping-address"
              className="flex h-60 cursor-pointer flex-col items-center justify-center rounded-xl border-4 border-dashed bg-gray-50 dark:bg-gray-800">
              <p className="text-7xl text-gray-400 dark:text-gray-500">+</p>
              <p className="text-xl text-gray-400 dark:text-gray-500">
                Add Address
              </p>
            </a>
            {data.map((el, index) => (
              <div
                key={index}
                className="flex h-60 flex-col  justify-between  space-y-2 rounded-xl border bg-gray-50 p-4">
                <div className="space-y-2">
                  {el.is_default ?? false ? (
                    <div className="flex items-center space-x-3 border-b pb-2">
                      <p className="text-sm font-semibold text-gray-700">
                        Default :{' '}
                      </p>
                      {''}
                      <img className="h-4 w-auto" src={Logo} alt="" />
                    </div>
                  ) : null}
                  <p className="text-sm text-gray-700">
                    {el.first_name} {el.last_name}
                  </p>
                  <p className="text-sm text-gray-700">{el.address_line1}</p>
                  <p className="text-sm text-gray-700">{el.address_line2}</p>
                  <p className="text-sm text-gray-700">{el.phone_number1}</p>
                  <p className="text-sm text-gray-700">{el.phone_number2}</p>
                </div>
                <div className="ml-auto flex items-center">
                  <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                    {!(el.is_default ?? false) ? (
                      <button
                        onClick={() => makeDefaultShippingAddress(el)}
                        disabled={loading}
                        className="text-xs font-medium text-gray-700 hover:text-gray-800">
                        Make Default
                      </button>
                    ) : null}
                    {!(el.is_default ?? false) ? (
                      <span
                        className="h-6 w-px bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <a
                      href={`/shipping-address/edit-shipping-address/${
                        el.id as number
                      }`}
                      className="text-xs font-medium text-blue-700 hover:text-blue-800">
                      Edit
                    </a>
                    <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                    <button
                      onClick={() => removeShippingAddress(el.id as number)}
                      disabled={loading}
                      className="text-xs font-medium text-red-700 hover:text-red-800">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Sidebar>
    </Layout>
  )
}

export default ShippingPage
