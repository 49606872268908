import { AxiosResponse } from 'axios'
import AxiosConfig from '../config/axiosConfig'

export interface IAuthenticate {
  first_name?: string
  last_name?: string
  email?: string
  password?: string
  business_name?: string
  phone?: string
  category?: string
  location?: string
  discovery?: string
  ref_code?: string
}

interface IRequest {
  payload: IAuthenticate
}

export const LoginUser = async ({
  payload,
}: IRequest): Promise<AxiosResponse> => {
  return await AxiosConfig.post('/auth/login', payload)
}

export const RegisterUser = async ({
  payload,
}: IRequest): Promise<AxiosResponse> => {
  return await AxiosConfig.post('/auth/register', payload)
}
