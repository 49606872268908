interface IProgressContent {
  title: string
  subtitle: string
  action: string
  importance: boolean
}

export const verificationContent: IProgressContent = {
  title: 'Verify Your Account',
  subtitle:
    'Upload your licence and get verified for the best experience. Start shopping and enjoy quality service from DrugStoc.',
  action: 'Upload Licence',
  importance: true,
}

export const firstOrderContent: IProgressContent = {
  title: 'Place Your First Order',
  subtitle:
    'It\'s easy. Explore the products page or search items by brand name, composition or manufacturers.',
  action: 'Start Shopping',
  importance: false,
}

export const walletContent: IProgressContent = {
  title: 'Activate Your Wallet',
  subtitle: 'Get started with DrugStoc Pay, experience seamless transactions and instant inventory financing when you need it.',
  action: 'Create Wallet',
  importance: false,
}
