import { Popover, Transition } from '@headlessui/react'
import { ArrowLongRightIcon } from '@heroicons/react/20/solid'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { IDropdown } from './interface'
import ManufacturerDropdownLink from './ManufacturerDropdownLink'
import { ArrowLongLeftIcon } from '@heroicons/react/24/solid'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const BASE_URL = process.env.REACT_APP_BASE_URL

const ManufacturerDropDown: React.FC<IDropdown> = ({ data, loading }) => {
  const [productsManufacturer, setProductsManufacturer] = useState<any>(null)
  const [error, setError] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    void getAllManufacturer()
  }, [currentPage])

  const getAllManufacturer = async (): Promise<void> => {
    const url = `${BASE_URL as string}/inventory/manufacturer/all`
    try {
      const response = await fetch(`${url}?page=${+currentPage}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        throw new Error('Error in loading new page')
      } else {
        const json = await response.json()
        setProductsManufacturer(json)
      }
    } catch (e: any) {
      setTimeout(() => {
        setError(e.messsage)
      }, 3000)
      setError('')
    }
  }

  const goToNextPage = (): void => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const goToPreviousPage = (): void => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1))
  }

  const totalPages = Math.ceil(productsManufacturer?.count / 20)
  const buttonStyles =
    'flex h-[35px] w-[35px] items-center justify-center bg-[#00000060] hover:bg-[#00000090] rounded-full'

  const manufacturerDivRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (manufacturerDivRef.current != null) {
      const divHeight = manufacturerDivRef.current.clientHeight
      setHeight(divHeight)
    }
  }, [productsManufacturer])

  return (
    <Popover className="flex">
      {({ open }) => (
        <>
          <div className="relative flex">
            <Popover.Button
              className={classNames(
                open
                  ? 'border-[#3b82f7] text-[#3b82f7]'
                  : 'border-transparent text-gray-700 hover:text-gray-800',
                'relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out focus:outline-none focus:ring-0'
              )}>
              Manufacturers
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enterFrom="opacity-50"
            enterTo="opacity-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-50">
            <Popover.Panel className="absolute inset-x-0 top-full text-sm text-gray-500">
              {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
              <div
                className="absolute inset-0 top-1/2 bg-white shadow"
                aria-hidden="true"
              />

              <div
                ref={manufacturerDivRef}
                className={`relative z-50 bg-white`}>
                {error !== null ? (
                  <a
                    href={`${window.location.href}`}
                    className={`relative top-2 w-[200px] rounded bg-red-600 py-2 ${
                      error !== null ? 'none' : 'flex'
                    } mx-auto justify-center text-white`}>
                    {error}
                  </a>
                ) : null}
                <div className={`absolute bottom-2 right-0 flex`}>
                  <div className="flex items-center justify-between">
                    <div className="ml-5 flex flex-none flex-col items-center justify-center">
                      <button
                        className={`${
                          currentPage === 1 ? 'invisible' : buttonStyles
                        } mr-2`}
                        onClick={goToPreviousPage}>
                        <ArrowLongLeftIcon className="h-5 w-5 cursor-pointer text-white" />
                      </button>
                    </div>
                    <div className="mr-5 flex flex-none items-center justify-center">
                      <button
                        className={`${
                          currentPage === totalPages
                            ? 'invisible'
                            : buttonStyles
                        } ml-2`}
                        onClick={goToNextPage}>
                        <ArrowLongRightIcon className="h-5 w-5 cursor-pointer text-white" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="container mx-auto max-w-7xl px-3">
                  <div
                    className={`grid grid-cols-1 gap-x-8 gap-y-5 py-4 h-[${height}px] items-end`}>
                    <ManufacturerDropdownLink
                      data={productsManufacturer?.results}
                      loading={loading}
                    />
                    <div className="flex justify-center">
                      <a
                        href="/manufacturers"
                        className="text-md inline-flex flex-row items-center truncate rounded-xl px-4 py-2.5 text-center font-semibold text-[#3b82f7] hover:text-[#1a6df3]">
                        See More Manufacturers
                        <ArrowLongRightIcon className="ml-2 h-5 w-5" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default ManufacturerDropDown
