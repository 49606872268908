import { categoryList } from './../Api/category.api'
import { IFilterOptions } from './../layout/Filters/interface'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { IDropDownLinkData } from './../component/Dropdowns/interface'
import useSWR from 'swr'
// import { useLocation } from 'react-router'

interface ICategory {
  data: IDropDownLinkData[]
  filter: IFilterOptions[] | any
  loading: boolean
  filterCategory: (query: ChangeEvent<HTMLInputElement>) => void
  error: any
}

interface IResult {
  id: number
  name: string
  desc: string
  slug: string
  created_at: Date
  updated_at: Date
}

export const useCategory = (): ICategory => {
  // const { pathname } = useLocation()
  // const path = pathname.split('/')?.splice(1)
  // const params = path[2]
  /* console.log(path.includes) */
  // const { data, error, isLoading } = useSWR(
  //   params !== undefined && path[1] !== 'product' && path[1] !== 'category' ? /* !path.includes('') && */ `/inventory/category/list?manufacturer=${params}` : '/inventory/category/list',
  //   categoryList
  // )

  const { data, error, isLoading } = useSWR(
    `/inventory/category/list`,
    categoryList
  )

  const [dropDown, setData] = useState<IDropDownLinkData[]>([])
  const [filter, setFilter] = useState<IFilterOptions[]>([])

  const dropdownList = useCallback(() => {
    if (!isLoading) {
      const result: IDropDownLinkData[] = data?.results.map((e: IResult) => ({
        name: e.name,
        link: `/category/${e.slug}`,
      }))
      setData(result)
      const filterResult: IFilterOptions[] = data?.results.map(
        (e: IResult) => ({
          value: e.id,
          label: e.name,
          checked: false,
          params: 'category',
        })
      )
      setFilter(filterResult)
      return result
    }
    return []
  }, [isLoading])

  const handleFilterQuery = async (query: string): Promise<void> => {
    const res = await categoryList(
      `/inventory/category/list?name__icontains=${query}`
    )
    const filterResult: IFilterOptions[] = res.results.map((e: IResult) => ({
      value: e.id,
      label: e.name,
      checked: false,
      params: 'category',
    }))
    setFilter(filterResult)
  }

  const filterCategory = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      void handleFilterQuery(event.target.value)
    },
    [filter]
  )

  useEffect(() => {
    dropdownList()
  }, [isLoading])

  return useMemo(
    () => ({
      data: dropDown,
      loading: isLoading,
      filter,
      filterCategory,
      error,
    }),
    [isLoading, dropDown, filter]
  )
}
